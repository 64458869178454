import styled from 'styled-components';
import {
  OrderListUpdateRequestDto,
  TraderType,
} from '../../../../api/apiConfig';
import ModalBase from '../ModalBase';
import { BiWindowClose } from 'react-icons/bi';
import { BsFiletypeXls } from 'react-icons/bs';
import { BsFiletypeXlsx } from 'react-icons/bs';
import { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { OrderListType } from '../../../../api/apiConfig';
import {
  removeSlashFromDateString,
  replaceOrderUploadserno,
} from '../../../../function/replaceString';
import toast from 'react-hot-toast';
import {
  AttendanceType,
  AttendanceWrapper,
  headerKeywords,
} from '../../../../router/user/pages/Attendance';

type AttendanceUploadModalType = {
  onClose: () => void;
  onSelect: (items: AttendanceWrapper[]) => void;
  year: string;
  month: string;
};
export const AttendanceUploadModal = ({
  onClose,
  onSelect,
  year,
  month,
}: AttendanceUploadModalType) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);

  const [originFileNames, setOriginFileNames] = useState<string[]>([]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [excelData, setExcelData] = useState<AttendanceWrapper[]>([]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);

    const files: FileList = event.dataTransfer.files;
    if (files.length > 0) {
      // 여러 파일을 처리할 경우
      Array.from(files).forEach((file) => handleFileChange(file));
    }
  };

  const handleFileChange = (file: File) => {
    // if (!e.target.files || e.target.files.length === 0) {
    //   return;
    // }

    // const file = e.target.files[0];
    setOriginFileNames((prev) =>
      prev.length > 0 ? [...prev, file.name] : [file.name]
    );

    const reader = new FileReader();
    const sheetDatas: AttendanceWrapper[] = [];
    reader.onload = (e) => {
      if (!e.target) return;

      //시트 순환하여 담아둘 임시배열

      // 헤더 인덱스 선언
      let headerIndex = -1;

      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      workbook.SheetNames.forEach((sheetName) => {
        // 첫 번째 시트
        const worksheet = workbook.Sheets[sheetName];

        // 엑셀 데이터를 JSON으로 변환. header:1을 사용하여 헤더지정 없이 초기에는 모든 데이터를 배열로 가져옴
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        //헤더 시작점 탐색
        for (let i = 0; i < jsonData.length; i++) {
          const row = jsonData[i];
          if (
            headerKeywords.some(
              (keyword) => Array.isArray(row) && row.includes(keyword)
            )
          ) {
            headerIndex = i;
            break;
          }
        }

        if (headerIndex === -1) {
          console.log('header error');
          return;
        }

        // 헤더와 데이터를 분리
        const [headers, ...originRows] = jsonData.slice(headerIndex) as [
          string[],
          any[]
        ];

        //빈 행 감지하여 잘라냄.
        const rows = originRows.slice(
          0,
          originRows.findIndex((row) => !row || row.length === 0) ||
            originRows.length
        );

        //근무일자 범위가 유효한지 검증
        const dateColumnIndex = headers.findIndex(
          (header) => header === '근무일자'
        );

        if (dateColumnIndex === -1) {
          console.log('근무일자 열을 찾을 수 없습니다.');
          return;
        }

        rows.forEach((row) => {
          if (!row || row.length === 0) {
            console.log('빈 행 발견. 데이터 순회 종료.');
            return; // 전체 순회 종료
          }
          const workDate = row[dateColumnIndex];
          if (workDate) {
            const [rowYear, rowMonth] = workDate.split('-');
            if (rowYear !== year || rowMonth !== month) {
              console.log(`근무일자 불일치: ${workDate}`);
            }
          }
        });

        //이름이 유효한지 검증
        const nameColumnIndex = headers.findIndex(
          (header) => header === '이름'
        );

        if (nameColumnIndex === -1) {
          console.log('이름 열을 찾을 수 없습니다.');
          return;
        }

        let nameCheck = '';
        rows.forEach((row) => {
          if (!row || row.length === 0) {
            console.log('빈 행 발견. 데이터 순회 종료.');
            return; // 전체 순회 종료
          }
          const name = row[nameColumnIndex];
          if ((!name || name !== nameCheck) && nameCheck !== '') {
            console.log(`이름 불일치 또는 빈 값: ${name}`);
          }
          nameCheck = name; // 첫 번째 이름을 기준으로 나머지 이름들을 비교
        });

        if (Array.isArray(headers)) {
          const mappedHeaders = headers.map((header) => {
            switch (header) {
              case '근무일자':
                return 'date';
              case '이름':
                return 'name';
              case '근무스케줄':
                return 'schedule';
              case '출근시간':
                return 'clockInTime';
              case '퇴근시간':
                return 'clockOutTime';
              case '출근판정':
                return 'clockInStatus';
              case '퇴근판정':
                return 'clockOutStatus';
              case '휴일근무시간':
                return 'holidayWorkTime';
              case '정상근무시간':
                return 'workdayWorkTime';
              case '수정 사유':
                return 'changeReason';
              case '연장근무시간':
                return 'overWorkTime';
              default:
                return null;
            }
          });

          const formattedData: AttendanceType[] = rows
            .filter((row) => {
              if (Array.isArray(row)) {
                const date = row[mappedHeaders.indexOf('date')];
                const name = row[mappedHeaders.indexOf('name')];
                return date && name; // 둘 다 값이 존재할 경우에만. 추가검증
              }
              return false; // 배열이 아닌 경우 false
            })
            .map((row) => {
              if (Array.isArray(row)) {
                // 각 행을 객체로 변환
                return row.reduce(
                  (acc, value, index) => {
                    const key = mappedHeaders[index];
                    if (key) {
                      if (value) {
                        acc[key] = value;
                      } else {
                        acc[key] = null;
                      }
                      delete acc[index]; //기존 숫자인덱스는 삭제
                    }
                    return acc;
                  },
                  { ...row }
                );
              }
              return;
            });

          const attendanceWrapper: AttendanceWrapper = {
            name: nameCheck,
            year: year,
            month: month,
            data: formattedData,
          };
          sheetDatas.push(attendanceWrapper);
        }
      });
      setExcelData((prev) =>
        prev.length > 0 ? [...prev, ...sheetDatas] : [...sheetDatas]
      );
    };

    reader.readAsArrayBuffer(file);
  };

  console.log('exceldata', excelData);

  return (
    <ModalBase>
      <Container>
        <HeaderSection>
          근태 원본 엑셀파일 등록
          <HeaderButtonSection>
            <div
              className='button'
              onClick={() => {
                if (excelData.length > 0) {
                  onSelect(excelData);
                  onClose();
                } else {
                  console.log('엑셀정보 없음');
                }
              }}
            >
              업로드
            </div>
            <BiWindowClose
              size={20}
              className='button'
              onClick={() => {
                onClose();
              }}
            />
          </HeaderButtonSection>
        </HeaderSection>
        <UploadSection
          isDragOver={isDragOver}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <IconSection>
            <BsFiletypeXls size={42} color='var(--green)' />
            <BsFiletypeXlsx size={42} color='var(--green)' />
          </IconSection>
          <div style={{ height: '30px' }} />
          <Title>파일을 드래그하여 업로드합니다.</Title>
          <div style={{ height: '30px' }} />
          <SubTitle>
            혹은, 탐색기에서{' '}
            <strong
              style={{
                textDecoration: 'underline',
                color: 'blue',
                cursor: 'pointer',
              }}
              onClick={() =>
                fileInputRef.current && fileInputRef.current.click()
              }
            >
              직접 선택
            </strong>
            합니다.
          </SubTitle>
          {originFileNames.length > 0 && (
            <SubTitle style={{ marginTop: '8px', padding: '15px' }}>
              선택된 파일:
              <br />
              {originFileNames.map(
                (fileName, index) =>
                  `${fileName}${index + 1 < originFileNames.length ? ', ' : ''}`
              )}
            </SubTitle>
          )}
          <input
            type='file'
            multiple
            ref={fileInputRef}
            onChange={(e) => {
              if (!e.target.files || e.target.files.length === 0) {
                return;
              }
              const files = Array.from(e.target.files); // FileList를 Array로 변환
              files.forEach((file) => handleFileChange(file)); // 각 파일 처리
            }}
            style={{ display: 'none' }}
            accept='.xls, .xlsx'
          />
        </UploadSection>
      </Container>
    </ModalBase>
  );
};

const Container = styled.div`
  width: 600px;
  /* height: 700px; */
  border-radius: 8px;
  z-index: 101;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const HeaderSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 32px;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.fontsize.table_header};
  background-color: ${({ theme }) => theme.colors.brightNavy};
  color: ${({ theme }) => theme.colors.white};
`;

const HeaderButtonSection = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  .button {
    cursor: pointer;
  }
`;

const UploadSection = styled.div<{ isDragOver: boolean }>`
  /* width: 370px; */
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--green);
  border-radius: 10px;
  background-color: ${(props) =>
    props.isDragOver ? '#ada8a8' : 'transparent'};
`;

const IconSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.div`
  font-size: 22px;
  color: black;
  font-weight: bold;
`;

const SubTitle = styled.div`
  font-size: 18px;
  color: gray;
  font-weight: 700;
`;
