import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store/modules';

import AnonymousRouter from './router/anonymouse/AnonymousRouter';
import { AdminRouter } from './router/admin/AdminRouter';
import { ManagerRouter } from './router/user/ManagerRouter';
import { WorkerRouter } from './router/worker/WorkerRouter';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const ScreenProvider = () => {
  const location = useLocation();
  const loginUserData = useSelector((state: RootState) => state.loginUserData);
  const role = loginUserData?.role ? loginUserData?.role : 'ANONYMOUS';

  useEffect(() => {
    console.log('LOCATION:::', location);
  }, [location]);

  return role === 'ADMIN' ? (
    <AdminRouter />
  ) : role === 'MANAGER' ? (
    <ManagerRouter />
  ) : role === 'WORKER' ? (
    <WorkerRouter />
  ) : (
    <AnonymousRouter />
  );
};

export default ScreenProvider;
