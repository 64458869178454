import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../store/modules';
import theme from '../../../styles/theme';
import OutPlaceModal from '../model/OutPlaceModal';
import { setIsRefresh } from '../../../store/modules/delivery';
import { PreparationPutApi } from '../../../api/apiConfig';
import useFetch from '../../../api/useFetch';

const DeliverySchedule = () => {
  const dispatch = useDispatch();
  const { deliveryType, order, deliveryData, isLoading } = useSelector(
    (state: RootState) => state.delivery
  );

  // 출고 일정 조회 API
  const { fetch: PreparationPut } = useFetch({
    fetchFunction: PreparationPutApi,
    onSuccess: (data: any) => {
      console.log('PreparationPutApi :  성공');
      dispatch(setIsRefresh(true));
    },
  });

  const [selectedId, setSelectedId] = useState(0);
  // 모달 열림 상태 관리
  const [showOutPlaceModal, setShowOutPlaceModal] = useState(false);
  // 드롭다운 열림 상태 관리 (어떤 행의 드롭다운이 열려 있는지 추적)
  const [openDropdownId, setOpenDropdownId] = useState<number | null>(null);

  //  deliveryData를 deliveryType 또는 order 기준으로 필터링
  const filteredData = useMemo(() => {
    let tempData = [...deliveryData];

    // 배송방법(deliveryType)으로 필터링
    if (deliveryType !== '전체') {
      tempData = tempData.filter((item) => item.DELIVERY_TYPE === deliveryType);
    }

    // 정렬(order) 예시:
    // "가까운 날짜순" -> TIME_DATE 오름차순
    // "먼 날짜순" -> TIME_DATE 내림차순
    if (order === '가까운 날짜순') {
      // YYYYMMDD 형태라면 문자열 비교로도 정렬 가능
      tempData.sort((a, b) => a.TIME_DATE.localeCompare(b.TIME_DATE));
    } else if (order === '먼 날짜순') {
      tempData.sort((a, b) => b.TIME_DATE.localeCompare(a.TIME_DATE));
    }

    // 각 item의 subRows를 QTY 기준으로 정렬 (오름차순)
    tempData = tempData.map((item) => ({
      ...item,
      subRows: item.subRows
        ? [...item.subRows].sort((a, b) => b.QTY - a.QTY)
        : [],
    }));

    return tempData;
  }, [deliveryData, deliveryType, order]);
  // 셀 클릭 시 모달 열기
  const handleOpenOutPlaceModal = (id: number) => {
    setSelectedId(id);
    setShowOutPlaceModal(true);
  };

  // 모달 닫기
  const handleCloseOutPlaceModal = () => {
    console.log('close');
    setShowOutPlaceModal(false);
  };

  // 저장 후 모달 닫는 로직
  const handleClose = () => {
    setShowOutPlaceModal(false);
    dispatch(setIsRefresh(true));
  };

  // 드롭다운 토글 핸들러
  const handleToggleDropdown = (id: number) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };
  // 드롭다운 옵션 선택 핸들러
  const handleSelectStatus = (id: number, status: string) => {
    console.log('선택한 상태:', status);
    setOpenDropdownId(null);
    PreparationPut({ id: id, preparationState: status });
    // 필요시 여기에서 Redux 액션을 디스패치하여 상태를 업데이트할 수 있습니다.
  };

  // 드롭다운 외부 클릭 시 드롭다운 닫기
  const handleClickOutside = () => {
    setOpenDropdownId(null);
  };

  useEffect(() => {
    if (openDropdownId !== null) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    // 클린업
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [openDropdownId]);

  //  로딩 상태 & 데이터 유무 처리
  if (isLoading) {
    return (
      <EmptyContainer>
        <Title>로딩 중...</Title>
      </EmptyContainer>
    );
  }

  if (filteredData.length === 0) {
    return (
      <EmptyContainer>
        <Title>데이터가 없습니다.</Title>
      </EmptyContainer>
    );
  }

  //  필터링된 데이터 렌더링
  return (
    <Container>
      {filteredData.map((item, index) => (
        <Section key={index}>
          <Header>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* 예: 주문 담당자 EMP_NAME */}
              <Title>{item.CUST_DES}</Title>
              {/* 예: 배송 방식 DELIVERY_TYPE */}
              <Tag>{item.DELIVERY_TYPE}</Tag>
            </div>
            {/* 납기일 TIME_DATE */}
            <Date>납기일 {item.TIME_DATE}</Date>
          </Header>

          <Content>
            {/* subRows 배열을 순회하여 상품 정보 렌더링 */}
            {item.subRows?.map((row, rowIndex) => (
              <Row key={rowIndex}>
                <Cell>{row.PROD_DES}</Cell>
                <Cell>{row.SIZE_DES}</Cell>
                <Cell>수량 : {row.QTY}</Cell>
                {/* 실제 데이터에 location 필드가 없으면, 가상의 예시로 작성 */}
                <Cell onClick={() => handleOpenOutPlaceModal(row.id)}>
                  {row.loadingLocationName
                    ? row.loadingLocationName
                    : '상차장소지정'}
                </Cell>
                {/* 준비 상태(preparationState) with dropdown */}
                <Cell>
                  <StatusContainer>
                    <Status
                      $isReady={row.preparationState === '준비중'}
                      onClick={(e) => {
                        e.stopPropagation(); // 다른 클릭 이벤트 방지
                        handleToggleDropdown(row.id);
                      }}
                    >
                      {row.preparationState}
                    </Status>
                    {openDropdownId === row.id && (
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => handleSelectStatus(row.id, '준비중')}
                        >
                          준비중
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => handleSelectStatus(row.id, '준비완료')}
                        >
                          준비완료
                        </DropdownItem>
                      </DropdownMenu>
                    )}
                  </StatusContainer>
                </Cell>
              </Row>
            ))}
          </Content>
        </Section>
      ))}

      {/* 모달 열림 상태일 때만 표시 */}
      {showOutPlaceModal && (
        <ModalOverlay onClick={handleCloseOutPlaceModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={handleCloseOutPlaceModal}>×</CloseButton>
            <OutPlaceModal id={selectedId} handleClose={handleClose} />
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default DeliverySchedule;

const Container = styled.div`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: calc(100vh - 54px);
`;

const Section = styled.div`
  padding: 15px;
  margin: 0 12px;
  border-bottom: 1px solid ${({ theme }) => `${theme.colors.charcoal}`};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  color: #717171;
  font-size: calc(0.4rem + 1vw);
  font-weight: 700;
`;

const Tag = styled.div`
  display: inline-flex;
  width: fit-content;
  color: #fff;
  font-size: calc(0.2rem + 1vw);
  font-weight: 700;
  border-radius: 10px;
  border: #557fae 1px solid;
  padding: 5px 7px;
  background-color: #557fae;
  margin-left: 20px;
`;

const Date = styled.div`
  display: flex;
  color: #717171;
  font-size: calc(0.4rem + 1vw);
  font-weight: 700;
  align-items: center;
`;

const Content = styled.div`
  margin-top: 10px;
`;

const Row = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 5px;
`;

const Cell = styled.div`
  flex: 1;
  display: flex;
  color: #717171;
  font-size: calc(0.2rem + 1vw);
  font-weight: 600;
  align-items: center;
`;

const Status = styled.div<{ $isReady: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  color: ${({ $isReady }) =>
    $isReady ? theme.colors.red : theme.colors.green};
`;
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  min-width: 300px;
  min-height: 200px;
  width: 50%;
  height: 60%;
  border-radius: 8px;
  position: relative;
  padding: 20px;
`;
const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  font-size: 2rem;
  line-height: 1;
  z-index: 2;
`;

const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
// 드롭다운을 감싸는 컨테이너
const StatusContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

// 드롭다운 메뉴
const DropdownMenu = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.charcoal};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  width: 100%;
  border-radius: 10px;
`;

// 드롭다운 아이템
const DropdownItem = styled.div`
  padding: 8px 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #717171;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }
`;
