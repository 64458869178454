const SET_TABLET_NAV_HEADER_WORKBAY_NAME =
  'tabletNavHeader/SET_TABLET_NAV_HEADER_WORKBAY_NAME' as const;
const SET_TABLET_NAV_HEADER_TIMEDATE =
  'tabletNavHeader/SET_TABLET_NAV_HEADER_TIMEDATE' as const;

export interface TabletNavHeaderStatusType {
  workbayName: string | null;
  timeDate: string | null;
}

export type TabletNavHeaderAction =
  | {
      type: typeof SET_TABLET_NAV_HEADER_WORKBAY_NAME;
      payload: string;
    }
  | {
      type: typeof SET_TABLET_NAV_HEADER_TIMEDATE;
      payload: string;
    };

export const setTabletNavHeaderWorkbayName = (payload: string) => ({
  type: SET_TABLET_NAV_HEADER_WORKBAY_NAME,
  payload: payload,
});

export const setTabletNavHeaderTimeDate = (payload: string) => ({
  type: SET_TABLET_NAV_HEADER_TIMEDATE,
  payload: payload,
});

const initialState: TabletNavHeaderStatusType = {
  workbayName: null,
  timeDate: null,
};

const tabletNavHeaderReducer = (
  state: TabletNavHeaderStatusType = initialState,
  action: TabletNavHeaderAction
) => {
  switch (action.type) {
    case SET_TABLET_NAV_HEADER_WORKBAY_NAME:
      return {
        ...state,
        workbayName: action.payload,
      };

    case SET_TABLET_NAV_HEADER_TIMEDATE:
      return {
        ...state,
        timeDate: action.payload,
      };

    default:
      return state;
  }
};

export default tabletNavHeaderReducer;
