import { useEffect, useState } from 'react';
import { useHeaderButtons } from '../../../component/header/HeaderButtonProvider';
import SquareButton from '../../../component/button/SquareButton';
import { AttendanceUploadModal } from '../../../component/common/modal/ModalComponents/AttendanceUploadModal';
import styled, { css } from 'styled-components';

const sample = [
  {
    name: '이의열',
    year: '2024',
    month: '11',
    data: [
      {
        date: '2024-11-01',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-01 08:32:00',
        clockOutTime: '2024-11-01 18:01:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-02',
        name: '이의열',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-03',
        name: '이의열',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-04',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-04 08:10:00',
        clockOutTime: '2024-11-04 18:04:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-05',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-05 08:20:00',
        clockOutTime: '2024-11-05 18:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-06',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-06 08:22:00',
        clockOutTime: '2024-11-06 18:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-07',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-07 08:54:00',
        clockOutTime: '2024-11-07 18:04:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-08',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-08 08:08:00',
        clockOutTime: '2024-11-08 18:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-09',
        name: '이의열',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-10',
        name: '이의열',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-11',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-11 08:21:00',
        clockOutTime: '2024-11-11 18:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-12',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-12 08:35:00',
        clockOutTime: '2024-11-12 18:04:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-13',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-13 08:07:00',
        clockOutTime: '2024-11-13 18:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-14',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-14 08:09:00',
        clockOutTime: '2024-11-14 18:04:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-15',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-15 08:33:00',
        clockOutTime: '2024-11-15 14:06:00',
        clockInStatus: '정상출근',
        clockOutStatus: '조퇴',
        holidayWorkTime: '00:00',
        workdayWorkTime: '04:06',
        changeReason: '단축(4시간)',
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-16',
        name: '이의열',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-17',
        name: '이의열',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-18',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-18 08:19:00',
        clockOutTime: '2024-11-18 18:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-19',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-19 08:08:00',
        clockOutTime: '2024-11-19 18:14:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-20',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-20 08:10:00',
        clockOutTime: '2024-11-20 15:01:00',
        clockInStatus: '정상출근',
        clockOutStatus: '조퇴',
        holidayWorkTime: '00:00',
        workdayWorkTime: '05:01',
        changeReason: '문화생활',
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-21',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-21 08:31:00',
        clockOutTime: '2024-11-21 18:07:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-22',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-22 08:35:00',
        clockOutTime: '2024-11-22 18:08:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-23',
        name: '이의열',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-24',
        name: '이의열',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-25',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-25 08:47:00',
        clockOutTime: '2024-11-25 15:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '조퇴',
        holidayWorkTime: '00:00',
        workdayWorkTime: '05:00',
        changeReason: '단축(3시간)',
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-26',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-26 08:38:00',
        clockOutTime: '2024-11-26 18:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-27',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-27 08:20:00',
        clockOutTime: '2024-11-27 17:01:00',
        clockInStatus: '정상출근',
        clockOutStatus: '조퇴',
        holidayWorkTime: '00:00',
        workdayWorkTime: '07:01',
        changeReason: '가정의날',
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-28',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-28 07:45:00',
        clockOutTime: '2024-11-28 18:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-29',
        name: '이의열',
        schedule: '평일(18시)',
        clockInTime: '2024-11-29 08:06:00',
        clockOutTime: '2024-11-29 15:05:00',
        clockInStatus: '정상출근',
        clockOutStatus: '조퇴',
        holidayWorkTime: '00:00',
        workdayWorkTime: '05:05',
        changeReason: '단축(3시간)',
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-30',
        name: '이의열',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
    ],
  },
  {
    name: '이종원',
    year: '2024',
    month: '11',
    data: [
      {
        date: '2024-11-01',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-01 08:23:00',
        clockOutTime: '2024-11-01 18:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-02',
        name: '이종원',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-03',
        name: '이종원',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-04',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-04 08:06:00',
        clockOutTime: '2024-11-04 18:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: '미인식(CCTV)',
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-05',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-05 07:59:00',
        clockOutTime: '2024-11-05 18:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-06',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-06 08:15:00',
        clockOutTime: '2024-11-06 18:03:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-07',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-07 08:25:00',
        clockOutTime: '2024-11-07 18:01:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-08',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-08 08:08:00',
        clockOutTime: '2024-11-08 18:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-09',
        name: '이종원',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-10',
        name: '이종원',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-11',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-11 08:26:00',
        clockOutTime: '2024-11-11 18:01:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-12',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-12 08:09:00',
        clockOutTime: '2024-11-12 18:01:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-13',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-13 08:08:00',
        clockOutTime: '2024-11-13 13:11:00',
        clockInStatus: '정상출근',
        clockOutStatus: '조퇴',
        holidayWorkTime: '00:00',
        workdayWorkTime: '03:11',
        changeReason: '맘껏외쳐봐 / 연가(3시간)',
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-14',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-14 08:19:00',
        clockOutTime: '2024-11-14 18:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-15',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-15 08:05:00',
        clockOutTime: '2024-11-15 14:06:00',
        clockInStatus: '정상출근',
        clockOutStatus: '조퇴',
        holidayWorkTime: '00:00',
        workdayWorkTime: '04:06',
        changeReason: '단축(4시간)',
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-16',
        name: '이종원',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-17',
        name: '이종원',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-18',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-18 08:24:00',
        clockOutTime: '2024-11-18 18:01:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-19',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-19 08:01:00',
        clockOutTime: '2024-11-19 18:01:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-20',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-20 08:10:00',
        clockOutTime: '2024-11-20 15:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '조퇴',
        holidayWorkTime: '00:00',
        workdayWorkTime: '05:00',
        changeReason: '문화생활',
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-21',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-21 08:06:00',
        clockOutTime: '2024-11-21 18:01:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-22',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-22 08:06:00',
        clockOutTime: '2024-11-22 18:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-23',
        name: '이종원',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-24',
        name: '이종원',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-25',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-25 08:08:00',
        clockOutTime: '2024-11-25 15:01:00',
        clockInStatus: '정상출근',
        clockOutStatus: '조퇴',
        holidayWorkTime: '00:00',
        workdayWorkTime: '05:01',
        changeReason: '단축(3시간)',
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-26',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-26 08:11:00',
        clockOutTime: '2024-11-26 18:01:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-27',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-27 08:12:00',
        clockOutTime: '2024-11-27 17:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '조퇴',
        holidayWorkTime: '00:00',
        workdayWorkTime: '07:00',
        changeReason: '가정의날',
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-28',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-28 08:09:00',
        clockOutTime: '2024-11-28 18:00:00',
        clockInStatus: '정상출근',
        clockOutStatus: '정상퇴근',
        holidayWorkTime: '00:00',
        workdayWorkTime: '08:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-29',
        name: '이종원',
        schedule: '평일(18시)',
        clockInTime: '2024-11-29 08:06:00',
        clockOutTime: '2024-11-29 17:06:00',
        clockInStatus: '정상출근',
        clockOutStatus: '조퇴',
        holidayWorkTime: '00:00',
        workdayWorkTime: '07:06',
        changeReason: '연가(1시간)',
        overWorkTime: '00:00',
      },
      {
        date: '2024-11-30',
        name: '이종원',
        schedule: '휴일',
        clockInTime: null,
        clockOutTime: null,
        clockInStatus: '휴일',
        clockOutStatus: '휴일',
        holidayWorkTime: '00:00',
        workdayWorkTime: '00:00',
        changeReason: null,
        overWorkTime: '00:00',
      },
    ],
  },
];

export const headerKeywords = [
  '근무일자',
  '이름',
  '근무스케줄',
  '출근시간',
  '퇴근시간',
  '출근판정',
  '퇴근판정',
  '휴일근무시간',
  '정상근무시간',
  '수정 사유',
  '연장근무시간',
];
export type AttendanceWrapper = {
  name: string;
  year: string;
  month: string;
  data: AttendanceType[];
};

export type AttendanceResultWrapper = {
  name: string;
  year: string;
  month: string;
  data: AttendanceResultType[];
};

export type AttendanceType = {
  date: string; //근무일자
  name: string; //이름
  schedule: string; //근무스케줄
  clockInTime: string; //출근시간
  clockOutTime: string; //퇴근시간
  clockInStatus: string; //출근판정
  clockOutStatus: string; //퇴근판정
  holidayWorkTime: string; //휴일근무시간
  workdayWorkTime: string; //정상근무시간
  overWorkTime: string; //연장근무시간
  changeReason: string; //수정사유
};

export type AttendanceResultType = {
  totalWorkDays: number; //근무일수(일)
  shortenedWorkHours: number; //근무단축(시간)
  annualLeaveHours: number; //연가(시간)
  overtimeHours: number; //연장근로(시간)
  paidToUnpaidHours: number; //유급휴일무급처리(시간)
  absentDays: number; //결근(일)
  sickLeaveDays: number; //병가(일)
};

export type LayoutDataType = {
  name: string;
  groupTopIndex: number[];
  groupMiddleIndex: number[];
  groupBottomIndex: number[];
};

export const Attendance = () => {
  const { setButtons } = useHeaderButtons();
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [layoutDatas, setLayoutDatas] = useState<LayoutDataType[]>([]);
  const [selectedLayoutData, setSelectedLayoutData] =
    useState<LayoutDataType | null>(null);
  const [selectedName, setSelectedName] = useState<string | null>(null);
  const [attendanceResults, setAttendanceResults] = useState<
    AttendanceResultWrapper[]
  >([]);
  const [selectedWrapper, setSelectedWrapper] =
    useState<AttendanceWrapper | null>(null);
  const [fixedNames, setFixedNames] = useState<string[]>([]);
  const [year, setYear] = useState<string | null>(null);
  const [month, setMonth] = useState<string | null>(null);
  const [attendanceWrappers, setAttendanceWrappers] = useState<
    AttendanceWrapper[]
  >([]);
  useEffect(() => {
    //헤더에 버튼 추가
    setButtons(
      <>
        <SquareButton
          text='파일 등록'
          colorType={year && month ? 'ACTIVE' : 'DISACTIVE'}
          onClick={() => (year && month ? setOpenUploadModal(true) : undefined)}
        />
      </>
    );
    // 페이지가 바뀌면 버튼 초기화
    return () => setButtons(null);
  }, [setButtons, year, month]);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value; // "YYYY-MM" 형식

    // 연도와 월 분리
    const [year, month] = value.split('-');
    setYear(year);
    setMonth(month);
  };

  function initData(datas: AttendanceWrapper[]) {
    if (datas.length < 1) return;
    setAttendanceWrappers(datas);
    setSelectedName(datas[0].name);
    const layoutDataInit: LayoutDataType[] = datas.map((data) => ({
      name: data.name,
      groupTopIndex: [],
      groupMiddleIndex: [],
      groupBottomIndex: [],
    }));
    setLayoutDatas(layoutDataInit);
    setSelectedLayoutData(
      layoutDataInit.find((data) => data.name === datas[0].name)!
    );
  }

  useEffect(() => {
    if (!selectedName) return;
    if (attendanceWrappers.length < 1) return;
    setSelectedWrapper(
      attendanceWrappers.find((wrapper) => wrapper.name === selectedName) ||
        null
    );
    setSelectedLayoutData(
      layoutDatas.find((layoutData) => layoutData.name === selectedName) || null
    );
  }, [selectedName]);

  //개발용 임시
  useEffect(() => {
    //@ts-ignore
    initData(sample);
  }, []);

  function totalDateFn(wrapper: AttendanceWrapper) {
    const datas: AttendanceType[] = wrapper.data;
    const workdayIndecies: number[] = datas.reduce<number[]>(
      (prev, data, index) => {
        if (data.schedule.startsWith('평일')) {
          prev.push(index);
        }
        return prev;
      },
      []
    );
    let totalCount = 0; // 총 카운트

    const groupTopIndex: number[] = [];
    const groupMiddleIndex: number[] = [];
    const groupBottomIndex: number[] = [];

    let consecutiveCount = 0; // 연속된 인덱스 카운터

    workdayIndecies.forEach((index, i) => {
      const currentStatus = datas[index]?.clockInStatus;
      if (currentStatus !== '결근') {
        totalCount++; // '결근'이 아닌 경우 카운팅

        // 연속 여부 확인
        if (
          i > 0 &&
          workdayIndecies[i] === workdayIndecies[i - 1] + 1 // 이전 인덱스와 연속
        ) {
          consecutiveCount++;
        } else {
          consecutiveCount = 1; // 연속 끊기면 다시 초기화
        }

        // 5개 연속된 경우 처리
        if (consecutiveCount === 5) {
          groupTopIndex.push(workdayIndecies[i - 4]); // 첫 번째 인덱스
          groupMiddleIndex.push(
            workdayIndecies[i - 3],
            workdayIndecies[i - 2],
            workdayIndecies[i - 1]
          ); // 중간 인덱스
          groupBottomIndex.push(workdayIndecies[i]); // 마지막 인덱스
          consecutiveCount = 0; // 카운터 초기화
          totalCount++;
        }
      } else {
        consecutiveCount = 0; // '결근'인 경우 연속 초기화
      }
    });

    console.log('indecies', workdayIndecies);
    console.log('totalCount', totalCount);
    console.log('groupTopIndex', groupTopIndex);
    console.log('groupMiddleIndex', groupMiddleIndex);
    console.log('groupBottomIndex', groupBottomIndex);
  }

  return (
    <Container>
      {!year && !month && <h3>연, 월을 선택해주세요</h3>}
      <StyledDateInput
        id='month-input'
        type='month'
        onChange={handleDateChange}
      />
      <ResultArea>
        <div onClick={() => selectedWrapper && totalDateFn(selectedWrapper)}>
          테스트 근무일수
        </div>
        <div>테스트 근무단축</div>
        <div>테스트 연가</div>
        <div>테스트 연장근로</div>
        <div>테스트 유급무급처리</div>
        <div>테스트 결근</div>
        <div>테스트 병가</div>
      </ResultArea>
      <ResultArea>
        <ResultItem>
          <ResultLabel>근무일수(일)</ResultLabel>
          <ResultInput />
        </ResultItem>
        <ResultItem>
          <ResultLabel>근무단축(시간)</ResultLabel>
          <ResultInput />
        </ResultItem>
        <ResultItem>
          <ResultLabel>연가(시간)</ResultLabel>
          <ResultInput />
        </ResultItem>
        <ResultItem>
          <ResultLabel>연장근로(시간)</ResultLabel>
          <ResultInput />
        </ResultItem>
        <ResultItem>
          <ResultLabel>유급휴일무급처리(시간)</ResultLabel>
          <ResultInput />
        </ResultItem>
        <ResultItem>
          <ResultLabel>결근(일)</ResultLabel>
          <ResultInput />
        </ResultItem>
        <ResultItem>
          <ResultLabel>병가(일)</ResultLabel>
          <ResultInput />
        </ResultItem>
      </ResultArea>

      {openUploadModal && year && month && (
        <AttendanceUploadModal
          onClose={() => setOpenUploadModal(false)}
          onSelect={(data: AttendanceWrapper[]) => initData(data)}
          year={year}
          month={month}
        />
      )}
      {attendanceWrappers.length > 0 && selectedName !== null && (
        <>
          <NameSection>
            {attendanceWrappers.map((wrapper, index) => (
              <NameSelect
                selected={selectedName === wrapper.name}
                isFirstItem={index === 0}
                isLastItem={index + 1 === attendanceWrappers.length}
                isFixed={fixedNames.includes(wrapper.name)}
                onClick={() => setSelectedName(wrapper.name)}
              >
                {wrapper.name}
              </NameSelect>
            ))}
          </NameSection>
          {selectedWrapper && selectedLayoutData && (
            <>
              <TableSection>
                <tr>
                  <th className='date'>근무일자</th>
                  <th className='name'>이름</th>
                  <th className='schedule'>근무스케줄</th>
                  <th className='clock-in-time'>출근시간</th>
                  <th className='clock-out-time'>퇴근시간</th>
                  <th className='clock-in-status'>출근판정</th>
                  <th className='clock-out-status'>퇴근판정</th>
                  <th className='holiday-work-time'>휴일근무시간</th>
                  <th className='workday-work-time'>정상근무시간</th>
                  <th className='change-reason'>수정 사유</th>
                  <th className='over-work-time'>연장근무시간</th>
                </tr>
                {selectedWrapper.data.map((data, index) => (
                  <tr>
                    <td className='date'>{data.date}</td>
                    <td className='name'>{data.name}</td>
                    <GroupLayoutTd className={`schedule schedule-${index}`}>
                      {data.schedule}
                    </GroupLayoutTd>
                    <td className='clock-in-time'>{data.clockInTime}</td>
                    <td className='clock-out-time'>{data.clockOutTime}</td>
                    <td className='clock-in-status'>{data.clockInStatus}</td>
                    <td className='clock-out-status'>{data.clockOutStatus}</td>
                    <td className='holiday-work-time'>
                      {data.holidayWorkTime}
                    </td>
                    <td className='workday-work-time'>
                      {data.workdayWorkTime}
                    </td>
                    <td className='change-reason'>{data.changeReason}</td>
                    <td className='over-work-time'>{data.overWorkTime}</td>
                  </tr>
                ))}
              </TableSection>
            </>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
`;

const StyledDateInput = styled.input`
  width: 150px;
  height: 25px;
  padding: 7px;
  font-size: 17px;
`;

const NameSection = styled.div`
  display: flex;
  margin-top: 15px;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const NameSelect = styled.div<{
  selected: boolean;
  isFirstItem: boolean;
  isLastItem: boolean;
  isFixed: boolean;
}>`
  cursor: pointer;

  padding: 8px 12px;
  font-weight: 600;
  border-bottom: ${({ isFixed, theme }) =>
    isFixed ? '1px solid transparent' : `1px solid ${theme.colors.red}`};
  align-content: center;
  text-align: center;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.white : theme.colors.white_odd};

  border-top-left-radius: ${({ isFirstItem }) => (isFirstItem ? '15px' : 0)};
  border-top-right-radius: ${({ isLastItem }) => (isLastItem ? '15px' : 0)};

  &:hover {
    background-color: rgb(255 255 255 / 4%);
  }
`;

const TableSection = styled.div`
  box-sizing: border-box;
  border-collapse: collapse;
  /* table-layout: fixed; */

  th,
  td {
    padding: 5px;
    align-content: center;
    text-align: center;
  }

  th {
    border: ${({ theme }) => `1px solid ${theme.colors.charcoal}`};
    background-color: white;
  }

  td {
    background-color: white;
  }

  .date {
    width: 150px;
  }
  .name {
    width: 110px;
  }
  .schedule {
    width: 180px;
  }
  .clock-in-time {
    width: 210px;
  }
  .clock-out-time {
    width: 210px;
  }
  .clock-in-status {
    width: 130px;
  }
  .clock-out-status {
    width: 130px;
  }
  .holiday-work-time {
    width: 85px;
  }
  .workday-work-time {
    width: 85px;
  }
  .over-work-time {
    width: 85px;
  }
  .change-reason {
    width: 160px;
  }
`;

const ResultArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 15px;
  gap: 15px;
`;

const ResultItem = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const ResultLabel = styled.h4`
  margin: 5px 0px 0px 0px;
`;

const ResultInput = styled.input`
  margin: 7px 0px;
  font-size: 18px;
  width: 80%;
  text-align: center;
  padding: 0px 5px;
  height: 22px;
`;

const GroupLayoutTd = styled.td<{ groupType?: string }>`
  border-color: ${({ theme }) => theme.colors.green};
  border-style: solid;
  border-width: 1px;
`;
