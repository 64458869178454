import styled from 'styled-components';
import { Factory_SVG } from '../../../@icons/svg/Factory_SVG';
import { Note_SVG } from '../../../@icons/svg/Note_SVG';
import { Truck_SVG } from '../../../@icons/svg/Truck_SVG';
import '../style/index.css';
import { useNavigate } from 'react-router-dom';
import { UserInfo } from '../../userHeader/UserInfo';

export const RouterPage = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <div
        style={{
          position: 'absolute',
          top: 8,
          right: 13,
          height: '30px',
        }}
      >
        <UserInfo />
      </div>
      <Item onClick={() => navigate('/production-progress-tablet')}>
        <ItemIcon className='even-shadow white-hover'>
          <Factory_SVG fill='#777777' />
        </ItemIcon>
        <text className='primary-title-text'>생산작업</text>
      </Item>
      <Item>
        <ItemIcon
          className='even-shadow white-hover'
          onClick={() => navigate('/production-bom')}
        >
          <Note_SVG fill='#777777' />
        </ItemIcon>
        <text className='primary-title-text'>소모등록</text>
      </Item>
      <Item>
        <ItemIcon
          className='even-shadow white-hover'
          onClick={() => navigate('/delivery-schedule')}
        >
          <Truck_SVG fill='#777777' />
        </ItemIcon>
        <text className='primary-title-text'>출고일정</text>
      </Item>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const Item = styled.div`
  flex: 0 0 15%;
  aspect-ratio: 1/1.1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const ItemIcon = styled.div`
  width: 70%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 90%;
    height: 90%;
  }
`;
