import styled from 'styled-components';
import { WebsocketManager } from '../../hook/WebsocketManager';
import { HeaderButtonProvider } from '../../component/header/HeaderButtonProvider';
import Header from '../../component/header/Header';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ProductionProgress } from '../user/pages/ProductionProgress';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ProductionProgressWorker } from '../user/pages/ProductionProgressWorker';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/modules';
import { RouterPage } from '../../component/tablet/pages/RouterPage';
import { ResponsiveContainer } from '../../component/tablet/components/ResponsiveContainer';
import { TabletWorkbaySidebar } from '../../component/tablet/sidebar/TabletWorkbaySidebar';
import { TabletKeypadSidebar } from '../../component/tablet/sidebar/TabletKeypadSidebar';
import { TabletPageLayout } from '../../component/tablet/pages/TabletPageLayout';
import { ProductionProgressTablet } from '../../component/tablet/pages/ProductionProgressTablet';
import { ProductionProgressTabletWorker } from '../../component/tablet/pages/ProductionProgressTabletWorker';
import { ProductionBom } from '../../component/tablet/pages/ProductionBom';
import { TabletBomSideBar } from '../../component/tablet/sidebar/TabletBomSideBar';
import DeliverySchedule from '../../component/tablet/pages/DeliverySchedule';
import DeliverySideBar from '../../component/tablet/sidebar/DeliverySideBar';
import { ProductionBomProgress } from '../../component/tablet/pages/ProductionBomProgress';
import { TabletBomKeypadSidebar } from '../../component/tablet/sidebar/TabletBomKeypadSidebar';

export const WorkerRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (location.pathname === '/' || location.pathname === '/login') {
      navigate('/home');
    }
  }, [location]);

  return (
    <>
      <WebsocketManager />
      <Routes>
        <Route path='home' element={<RouterPage />} />

        <Route
          path='production-progress-tablet'
          element={
            <TabletPageLayout
              bodyChildren={<ProductionProgressTablet />}
              sidebarChildren={<TabletWorkbaySidebar />}
            />
          }
        />
        <Route
          path='production-progress-tablet/:productionId/:itemId'
          element={
            <TabletPageLayout
              bodyChildren={<ProductionProgressTabletWorker />}
              sidebarChildren={<TabletKeypadSidebar />}
            />
          }
        />

        <Route
          path='production-bom'
          element={
            <TabletPageLayout
              bodyChildren={<ProductionBom />}
              sidebarChildren={<TabletBomSideBar />}
            />
          }
        />

        <Route
          path='production-bom/:workbayId/:itemId'
          element={
            <TabletPageLayout
              bodyChildren={<ProductionBomProgress />}
              sidebarChildren={<TabletBomKeypadSidebar />}
            />
          }
        />

        <Route
          path='delivery-schedule/'
          element={
            <TabletPageLayout
              bodyChildren={<DeliverySchedule />}
              sidebarChildren={<DeliverySideBar />}
            />
          }
        />
        {/* <Route
        path='production-progress'
        element={
          <WithDefaultLayout>
          <ProductionProgress />
          </WithDefaultLayout>
          }
          />
          <Route
          path='production-progress/:productionId/:itemId'
          element={
            // <WithDefaultLayout>
            <ProductionProgressWorker />
            // </WithDefaultLayout>
            }
            /> */}
      </Routes>
    </>
  );
};

const WholeContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const BodyContainer = styled.div`
  flex-basis: 100%;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.lightGray};
`;
