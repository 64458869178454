import styled, { useTheme } from 'styled-components';
import { ProductionResponseType } from '../../../api/apiConfig';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/modules';
import { ProductionCircularCard } from '../../common/ProductionCircularCard';
import { ProductionCircularCardFix } from '../../common/card/ProductionCircularCardFix';
import { useNavigate } from 'react-router-dom';

export const ProductionProgressTablet = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const [productionData, setProductionData] = useState<
    ProductionResponseType[]
  >([]);
  const [afterProductionData, setAfterProductionData] = useState<
    ProductionResponseType[]
  >([]);

  const productionStoreData = useSelector(
    (state: RootState) => state.socketData.production
  );

  const selectedWorkbayTablet = useSelector(
    (state: RootState) => state.tabletOption.selectedWorkbay
  );

  useEffect(() => {
    if (selectedWorkbayTablet === null) return;
    setProductionData(
      productionStoreData
        .map((storeData: ProductionResponseType) => {
          if (storeData === null || storeData === undefined) return null;
          if (
            (storeData.progress === 'W' || storeData.progress === 'P') &&
            Number(storeData.workbayId) === Number(selectedWorkbayTablet.id)
          ) {
            return storeData;
          } else {
            return null;
          }
        })
        .filter((data) => data !== null)
    );

    setAfterProductionData(
      productionStoreData
        .map((storeData: ProductionResponseType) => {
          if (storeData === null || storeData === undefined) return null;
          if (
            storeData.progress === 'E' ||
            (storeData.progress === 'C' &&
              Number(storeData.workbayId) === Number(selectedWorkbayTablet.id))
          ) {
            return storeData;
          } else {
            return null;
          }
        })
        .filter((data) => data !== null)
    );
  }, [productionStoreData, selectedWorkbayTablet]);

  return selectedWorkbayTablet === null ? (
    <h3 style={{ textAlign: 'center' }}>작업장을 선택해주세요</h3>
  ) : (
    <Container>
      <Half>
        <h3 style={{ color: theme.colors.charcoal }}>
          - 대기중 및 진행중인 생산항목
        </h3>
        <CardSection>
          {productionData.length > 0 ? (
            productionData
              .filter(
                (production) =>
                  Number(selectedWorkbayTablet.id) ===
                  Number(production.workbayId)
              )
              .map((production) => (
                <ProductionCircularCardFix
                  width={170}
                  height={270}
                  hover={false}
                  onClick={() =>
                    navigate(
                      `/production-progress-tablet/${production.id}/${production.item.id}`
                    )
                  }
                  itemName={production.item.PROD_DES}
                  itemSize={production.item.SIZE_DES}
                  workbayName={production.workbayName}
                  current={production.productionCnt}
                  goal={production.productionGoal}
                  progress={production.progress}
                />
              ))
          ) : (
            <h4>-생산항목이 없습니다</h4>
          )}
        </CardSection>
      </Half>
      <Half>
        <h3 style={{ color: theme.colors.charcoal }}>- 마감된 생산항목</h3>
        <CardSection>
          {afterProductionData.length > 0 ? (
            afterProductionData
              .filter(
                (production) =>
                  Number(selectedWorkbayTablet.id) ===
                  Number(production.workbayId)
              )
              .map((production) => (
                <ProductionCircularCardFix
                  width={170}
                  height={270}
                  hover={false}
                  onClick={() => undefined}
                  itemName={production.item.PROD_DES}
                  itemSize={production.item.SIZE_DES}
                  workbayName={production.workbayName}
                  current={production.productionCnt}
                  goal={production.productionGoal}
                  progress={production.progress}
                />
              ))
          ) : (
            <div>
              <h4>-생산항목이 없습니다</h4>
            </div>
          )}
        </CardSection>
      </Half>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px 20px;
`;

const Half = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 50%;
`;

const CardSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 28px;
  overflow-x: scroll;
  padding: 8px 8px 0px 8px;
`;
