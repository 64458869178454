import { Bell_SVG } from '../../@icons/svg/Bell_SVG';
import * as Styled from './HeaderStyled';
import { useHeaderButtons } from './HeaderButtonProvider';
import { useDispatch, useSelector } from 'react-redux';
import { clearLoginUserData } from '../../store/modules/loginUserData';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../store/modules';
import { useEffect, useState } from 'react';
import { UserInfo } from '../userHeader/UserInfo';

type HeaderDataType = {
  categoryFirst: string;
  categorySecond: string;
  description: string;
};

const categoryMap: Record<string, HeaderDataType> = {
  '/orders/update': {
    categoryFirst: '주문관리',
    categorySecond: '주문등록',
    description: '엑셀파일로 주문을 등록합니다',
  },
  '/production-add': {
    categoryFirst: '생산관리',
    categorySecond: '생산등록',
    description: '생산일정을 등록 및 수정합니다',
  },
  '/production-overview': {
    categoryFirst: '생산관리',
    categorySecond: '생산현황',
    description: '금일의 생산일정을 조회합니다',
  },
  '/production-progress': {
    categoryFirst: '생산관리',
    categorySecond: '생산진행',
    description: '생산작업 현황을 확인하고 진행합니다',
  },
  '/production-inbound': {
    categoryFirst: '생산관리',
    categorySecond: '생산입고',
    description: '금일의 생산량, 소모량을 확인하고 엑셀파일로 출력합니다',
  },
  '/trader/update': {
    categoryFirst: '기초등록',
    categorySecond: '거래처 정보등록',
    description: '엑셀파일로 거래처를 등록합니다',
  },
  '/delivery/set': {
    categoryFirst: '배송',
    categorySecond: '배송배정',
    description: '배송일자와 담당자, 주문목록을 배정합니다',
  },
  '/bom': {
    categoryFirst: '기초등록',
    categorySecond: '소모량등록',
    description: '생산품의 소모항목과 소모량을 등록합니다',
  },
  '/parcel': {
    categoryFirst: '배송',
    categorySecond: '택배송장변환',
    description: '주문을 택배송장 엑셀양식에 맞게 변환합니다',
  },
  '/parcel-item': {
    categoryFirst: '기초등록',
    categorySecond: '품목 택배정보 등록',
    description: '택배송장 변환 시 품목의 변환값을 설정합니다',
  },
  '/parcel-trader': {
    categoryFirst: '기초등록',
    categorySecond: '거래처 택배정보 등록',
    description: '택배송장 변환 시 거래처의 변환값을 설정합니다',
  },
  '/attendance': {
    categoryFirst: '데이터관리',
    categorySecond: '근태관리 엑셀변환',
    description: '세콤매니저 엑셀양식을 근태양식으로 변환합니다',
  },
  '/orders/address-verify': {
    categoryFirst: '주문관리',
    categorySecond: '주문 배송지 미등록 항목',
    description: '유효하지 않은 주소지를 직접 수정하여 입력합니다',
  },
  '/orders/address-modify': {
    categoryFirst: '주문관리',
    categorySecond: '주문 배송지 변경',
    description: '주문의 배송지를 수정합니다',
  },
  '/orders/emm-shop-changed': {
    categoryFirst: '주문관리',
    categorySecond: '자사쇼핑몰 주문 변경내역',
    description: '변경사항이 감지된 자사쇼핑몰의 주문내역을 확인합니다',
  },

  /** ADMIN */
  '/account-manage': {
    categoryFirst: '계정관리',
    categorySecond: '',
    description: '계정정보를 관리합니다',
  },
  '/data-manage': {
    categoryFirst: '데이터관리',
    categorySecond: '',
    description: '데이터를 관리합니다',
  },
};

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [headerData, setHeaderData] = useState<HeaderDataType>({
    categoryFirst: '',
    categorySecond: '',
    description: '',
  });

  // 현재 경로에서 root 제외하고, '/'를 붙여서 출력
  const currentPath = '/' + location.pathname.split('/').slice(1).join('/');
  const dispatch = useDispatch();
  const { buttons } = useHeaderButtons();
  const openHeader = useSelector((state: RootState) => state.header.open);

  useEffect(() => {
    if (!currentPath) return;
    const headerData = categoryMap[currentPath] as HeaderDataType;
    setHeaderData(headerData);
  }, [currentPath]);

  return (
    <Styled.Container open={openHeader}>
      <Styled.HeaderTop>
        <Styled.NavHistory>
          <div className='category-first'>
            {headerData && headerData.categoryFirst}
          </div>
          <div>&nbsp;/&nbsp;</div>
          {/* <div className='category-second'>Object Storage</div>
          <div>&nbsp;/&nbsp;</div> */}
          <div className='category-third'>
            {headerData && headerData.categorySecond}
          </div>
        </Styled.NavHistory>
        <Styled.InfoButtonSec>
          {/* <Bell_SVG /> */}
          {/* <Styled.UserInfoSec style={{ backgroundColor: 'black' }}>
            <div
              onClick={() => {
                dispatch(clearLoginUserData());
                navigate('/');
              }}
            >
              유저정보
            </div>
          </Styled.UserInfoSec> */}
          <UserInfo />
        </Styled.InfoButtonSec>
      </Styled.HeaderTop>
      <Styled.HeaderTitle>
        {headerData && headerData.description}
      </Styled.HeaderTitle>
      {buttons && <Styled.HeaderButtonSec>{buttons}</Styled.HeaderButtonSec>}
    </Styled.Container>
  );
};

export default Header;
