import React, { useState, useEffect } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import styled from 'styled-components';
import { useTheme } from 'styled-components';

interface ProgressBarProps {
  current: number; // 현재 값
  goal: number; // 목표 값
  style?: React.CSSProperties;
  dir?: 'ltr' | 'rtl' | 'auto';
  gageColor?: string;
}

export const CustomProgressBar: React.FC<ProgressBarProps> = ({
  current,
  goal,
  style,
  dir = 'auto',
  gageColor = '#8dc4e6',
}) => {
  const theme = useTheme();
  const [progress, setProgress] = useState(0);
  const [isOverGoal, setIsOverGoal] = useState(false);

  useEffect(() => {
    // 현재 값에 따른 비율 계산
    const percentage = (current / goal) * 100;
    setProgress(Math.min(percentage, 100)); // 100% 이상일 때도 width는 100%
    setIsOverGoal(current > goal); // 목표 초과 여부
  }, [current, goal]);

  return (
    <div style={{ position: 'relative', overflow: 'hidden', ...style }}>
      <ProgressBarPositionFixer>
        <ProgressBar
          completed={progress}
          customLabel={`${((current / goal) * 100).toFixed()}%`} // 실제 퍼센트 표시
          bgColor={isOverGoal ? '#72fd5f' : gageColor} // 목표 초과 시 색상 변경
          height='100%'
          width='100%'
          borderRadius='8px'
          transitionDuration='0.2s'
          dir={dir}
        />
      </ProgressBarPositionFixer>
    </div>
  );
};

const ProgressBarPositionFixer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
