import axios from 'axios'; //기본 Axios
import { apiClient } from './axiosConfig'; //토큰적용등 설정 반영된 커스텀axios
import { createApiFunction } from './createApiFunction';
import { API_BASE_URL } from './axiosConfig';
export type LoginRequestType = {
  loginId: string;
  password: string;
};
export type LoginResponseType = {
  accessToken: string;
  refreshToken: string;
  userInfo: {
    userName: string;
    role: string;
    loginId: string;
  };
};

/** 거래처타입 */
export type TraderType = {
  CUST_CODE: string; //거래처코드
  CUST_NAME: string; //거래처명
  BOSS_NAME: string; //대표자명
  TEL: string; //전화번호
  FAX: string; //팩스번호
  HP_NO: string; //모바일번호
  POST_NO: string; //우편번호
  ADDR: string; //주소
  updateFlag: boolean;
};

/** 주문목록 업데이트 post api dto*/
export type OrderListUpdateRequestDto = {
  startDt: string;
  endDt: string;
  orderList: OrderListType[];
};

//주문목록리스트
export type OrderListType = {
  id?: number;
  orderId?: number;
  IO_DATE?: string; //주문일자
  UPLOAD_SER_NO?: number; //주문시리얼
  ORDER_ID?: string; //20241104-1형식으로 부여하여서 주문 unique값으로 사용. 주문일자-시리얼 형태.
  CUST?: string; //거래처코드
  CUST_DES?: string; //거래처명
  EMP_CD?: string; //담당자코드
  EMP_NAME?: string; //담당자명. 저장은 안하고 보여주는용도
  PROD_DES?: string; //품목명
  SIZE_DES?: string; //품목규격
  PROD_CD?: number; //품목코드
  CLASS_CD?: string; //품목그룹1코드
  CLASS_CD_DES?: string; //품목그룹1명 //저장안함
  CLASS_CD2?: string; //품목그룹2코드
  CLASS_CD2_DES?: string; //품목그룹2명
  CLASS_CD3?: string; //품목그룹3코드
  CLASS_CD3_DES?: string; //품목그룹3명
  QTY?: number; //수량
  PRICE?: number; //단가
  SUPPLY_AMT?: number; //공급가액
  TIME_DATE?: string; //납기일자
  ORDER_TYPE?: string; //주문방법
  DELIVERY_TYPE?: string; //배송방법
  DESCRIPTION?: string; //주문특이사항
  REMARKS?: string; //적요
  receiverAddress?: string; //실배송주소
  receiverAddressDetail?: string; //상세주소
  deliveryRemarks?: string;
  subRows?: OrderListType[];
  items?: OrderListType[];
  duplicated?: boolean;
  updateFlag?: boolean; //업데이트여부
  changedItemFields?: string[];
  changedOrderFields?: string[];
};

/** 주문 주소검색 후 주소변경 요청타입 */
export type OrderAddressUpdateRequestType = {
  orderId: number;
  receiverAddress: string | null;
  receiverAddressDetail: string | null;
  latitude: number | null;
  longitude: number | null;
  zoneNo: string | null;
};

export const keyMapping: Record<string, keyof OrderListType> = {
  qty: 'QTY',
  price: 'PRICE',
  supplyAmt: 'SUPPLY_AMT',
  cust: 'CUST',
  custDes: 'CUST_DES',
  empCd: 'EMP_CD',
  empName: 'EMP_NAME',
  prodDes: 'PROD_DES',
  sizeDes: 'SIZE_DES',
  prodCd: 'PROD_CD',
  classCd: 'CLASS_CD',
  classCdDes: 'CLASS_CD_DES',
  classCd2: 'CLASS_CD2',
  classCd2Des: 'CLASS_CD2_DES',
  classCd3: 'CLASS_CD3',
  classCd3Des: 'CLASS_CD3_DES',
  timeDate: 'TIME_DATE',
  orderType: 'ORDER_TYPE',
  deliveryType: 'DELIVERY_TYPE',
  description: 'DESCRIPTION',
  remarks: 'REMARKS',
  receiverAddress: 'receiverAddress',
  deliveryRemarks: 'deliveryRemarks',
};

// 출고일정조회
// 하위 상품 정보를 위한 타입
export type SubRowType = {
  id: number;
  changedItemFields: any | null; // 변경된 필드 정보 (알 수 없어서 any로 지정)
  itemChangeFlag: any | null; // 변경 상태 플래그 (알 수 없어서 any로 지정)
  preparationState: string; // 준비 상태 (e.g., "준비중")
  loadingLocationId: number;
  loadingLocationName: string;
  PROD_ID: string; // 상품 ID
  QTY: number; // 수량
  PRICE: number; // 단가
  SUPPLY_AMT: number; // 공급 금액
  PROD_DES: string; // 상품 설명
  SIZE_DES: string | null; // 크기 설명 (없을 수도 있음)
};

// 메인 데이터 구조 타입
export type DeliveryScheduleResponseType = {
  changedOrderFields: any | null; // 변경된 필드 정보 (알 수 없어서 any로 지정)
  duplicated: boolean; // 중복 여부
  receiverAddressDetail: string | null; // 수령인 상세 주소
  receiverName: string | null; // 수령인 이름
  receiverTelNo: string | null; // 수령인 전화번호
  zoneNo: string | null; // 우편번호
  addressVerified: boolean; // 주소 검증 여부
  ORDER_ID: string; // 주문 ID
  receiverAddress: string | null; // 수령인 주소
  deliveryRemarks: string | null; // 배송 비고
  orderId: number; // 주문 ID
  IO_DATE: string; // 입출고 날짜
  UPLOAD_SER_NO: number; // 업로드 일련번호
  CUST: string; // 고객 번호
  CUST_DES: string; // 고객 설명
  EMP_CD: string; // 직원 코드
  EMP_NAME: string; // 직원 이름
  TIME_DATE: string; // 시간 날짜
  ORDER_TYPE: string; // 주문 유형
  DELIVERY_TYPE: string; // 배송 유형
  DESCRIPTION: string; // 설명
  REMARKS: string | null; // 비고
  subRows: SubRowType[]; // 하위 상품 정보 배열
  orderChageFlug: any | null; // 주문 변경 플래그 (알 수 없어서 any로 지정)
};

// 상차 장소 리스트트 조회 타입
export type LoadingLocationListResponseType = {
  id: number;
  name: string;
};

export type OrderUploadResponseType = {
  updatedOrInsertedOrders: OrderListType[];
  deletedItemsByOrderId: {
    [orderId in string]: string[];
  };
  deletedOrders: string[];
};
// 용법
//   const { fetch: apiGetTraderList } = useFetch({
//     fetchFunction: traderListGetApi,
//     onSuccess: (data: TraderType[]) => setData(data),
//   });
//   apiGetTraderList() --> 호출부

//   const { fetch: apiPostTraderList } = useFetch({
//     fetchFunction: traderListPostApi,
//     onSuccess: (data: TraderType[]) => {
//       setUpdatedData(data);
//       toast('업데이트에 성공하였습니다.');
//     },
//   });
//  apiPostTraderList(some_data) --> 호출부

/** 배송담당자 정보 타입 */
export type DeliveryManagerType = {
  id: number;
  name: string;
  position: string;
};

/** 작업장 정보 타입 */
export type WorkbayType = {
  id: number;
  workbayName: string;
  workbayCode: string;
};

/** 품목 반환타입 임시 */
export type ProductItem = {
  id: number;
  PROD_CD: string;
  PROD_DES: string;
  SIZE_DES: string;
  UNIT: string;
  PROD_TYPE: string;
  SET_FLAG: string;
  WH_CD: string;
  OUT_PRICE: string;
  REMARKS_WIN: string;
  REMARKS: string;
};

/** 생산 request dto */
export type ProductionType = {
  productionDt: string;
  workbayId: number;
  progress: string;
  phase: number;
  items: {
    itemId: number;
    goal: number;
  }[];
};

/** 생산일정 존재하는 날짜 조회 request dto */
export type ProductionExistDayReqType = {
  targetYear?: string;
  targetMonth?: string;
  targetDay?: string;
  targetWorkbayId?: number;
};

/** 생산일정 진행상태 업데이트 request dto */
export type ProductionProgressUpdateReqType = {
  progress: string;
  targetDate: string;
  workbayId: number;
};

/** 생산일정 응답타입 */
export type ProductionResponseType = {
  id: number;
  workbayId: string;
  workbayName: string;
  productionDt: string;
  productionCnt: number;
  productionGoal: number;
  productionPhase: number;
  progress: string;
  item: ProductItem;
};

/** 생산테이블 수량증가시의 response type */
export type ProductionCntIncreaseResponseType = {
  productionId: number;
  updatedCount: number;
};

/** 재고현황 응답 type */
export type StockResponseType = {
  itemId: number;
  prodCd: string;
  prodDes: string;
  qty: number;
  sizeDes: string;
  stockId: number;
  timeData: string;
};

/** 재고변동현황 응답 type */
export type StockHistoryResponseType = {
  id: number;
  productionId: number;
  stockId: number;
  itemId: number;
  timeDate: string;
  changedQuantity: number;
};

/** 생산품과 별도로 설정할 수 있는 재고변동현황 */
export type UnsyncedStockHistoryResponseType = {
  id: number;
  workbayId: number;
  stockId: number;
  itemId: number;
  timeDate: string;
  changedQuantity: number;
};

/** 배송배정 요청 타입 */
export type DeliveryAssignmentRequestType = {
  targetDt: string;
  data: DeliveryAssignmentRequestDataType[];
};

export type DeliveryAssignmentRequestDataType = {
  managerId: number;
  assignmentDate: string;
  orderIds: number[];
};

/** BOM 타입 */
export interface ItemBomType {
  itemDes: string;
  sizeDes: string;
  itemId: number;
  bomId: number;
  consumedQty: number;
}

/** BOM ui용 타입 */
export type ItemBomEssType = {
  itemDes: string;
  sizeDes: string;
  itemId: number;
  bomId?: number;
  consumedQty?: number; // 타입 변경
};

/** BOM 묶음 */
export type ItemBomResponseType = {
  producedItem: ItemBomType;
  consumedItems: ItemBomType[];
};

/** BOM 묶음 ui용 타입 */
export type ItemBomResponseEssType = {
  producedItem: ItemBomEssType;
  consumedItems: ItemBomEssType[];
};

/** Bom request type */
export type BomRequestType = {
  producedItemId: number;
  consumedItems: {
    consumedItemId: number;
    consumedQuantity: number;
  }[];
};

/** 생산테이블 수량증가 request type */
export type ProductionCntIncrease = {
  productionId: number;
  increaseCount: number;
};

/** 생산테이블에 포함된 소모품현황 리스트 조회 요청타입*/
export type ProductionStockHistoryRequestType = {
  timeDate: string;
  workbayId: number;
};

/** 택배송장 품목 옵션값 post request type */
export type ParcelItemRequestType = {
  id: number;
  label: string;
  sizeLabel: string;
  unitLabel: string;
  packageMaxQty: number;
  prodCd: string;
};

/** 택배송장 품목 옵션값 get response type */
export type ParcelItemResponseType = {
  id: number | null;
  label: string | null;
  sizeLabel: string | null;
  unitLabel: string | null;
  packageMaxQty: number | null;
  prodCd: string;
};

/** 택배송장 거래처 옵션값 post request type */
export type ParcelTraderRequestType = {
  id: number;
  label: string;
  custCd: string;
};

/** 택배송장 거래처 옵션값 get response type */
export type ParcelTraderResponseType = {
  id: number | null;
  label: string | null;
  custCd: string | null;
};

/** 유저 정보 type */
export type UserType = {
  id: number | null;
  userName: string;
  role: string;
  loginId: string;
  password: string | null;
};

/** 자사쇼핑몰 주문 */
export type EmmShopOrder = {
  id: number; // emm_shop 테이블 고유 아이디
  orderId: string; // 주문 ID
  orderPrice: string; // 주문 총 금액 (상품 가격 합계)
  receiptPrice: string; // 실제 결제된 금액
  status: string; // 주문 상태 (주문, 입금, 배송, 완료 등)
  orderTime: string; // 주문 생성 시간
  invoiceNumber: string; // 운송장번호
  deliveryCompany: string; // 배송 회사 이름
  deliveryTime: string; // 배송 날짜 및 시간
  cancelPrice: string; // 취소된 금액
  couponTotal: number; // 쿠폰으로 할인된 금액(합계)
  outstandingAmount: string; // 미수금
  paymentMethod: string; // 결제방식(신용카드, 무통장 등)
  memo: string; // 주문 시 작성된 메모
  escrow: boolean; // 에스크로 여부
  ordererName: string; // 주문자의 이름
  ordererPhone: string; // 주문자의 전화번호
  ordererMobile: string; // 주문자의 휴대폰번호
  ordererEmail: string; // 주문자의 이메일
  ordererAddressZipcode: string; // 주문자의 주소 우편번호
  ordererAddressBase: string; // 주문자의 주소-기본주소
  ordererAddressDetail: string; // 주문자의 주소-상세주소
  ordererAddressOption: string; // 주문자의 주소-참고항목
  recipientName: string; // 수령인의 이름
  recipientPhone: string; // 수령인의 전화번호
  recipientMobile: string; // 수령인의 휴대폰번호
  recipientAddressZipcode: string; // 수령인의 주소 우편번호
  recipientAddressBase: string; // 수령인의 주소-기본주소
  recipientAddressDetail: string; // 수령인의 주소-상세주소
  recipientAddressOption: string; // 수령인의 주소-참고항목
  changeFlag: string; // 변경감지 여부 Y/N
  products: EmmShopOrderProduct[];
};

/** 자사쇼핑몰 주문의 품목타입 */
export type EmmShopOrderProduct = {
  productId: string;
  productName: string;
  quantity: string;
  price: string;
};

/** 로그인 */
const loginApiConfig = async (data: LoginRequestType) => {
  const response = await axios.post(`${API_BASE_URL}/auth/login`, data);
  return response.data;
};
export const LoginApi = createApiFunction({
  apiCall: loginApiConfig,
});

/** 계정생성 */
const userCreateApiConfig = async (data: UserType) => {
  const response = await apiClient.post(`${API_BASE_URL}/api/signup`, data);
  return response.data;
};
export const userCreateApi = createApiFunction({
  apiCall: userCreateApiConfig,
  successMessage: (data: UserType) => `${data.loginId} 계정이 생성되었습니다`,
  errorMessage: (data: UserType) =>
    `${data.loginId} 계정을 생성하지 못했습니다`,
});

/** 유저리스트 조회 */
const userListGetApiConfig = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/api/user`);
  return response.data;
};
export const userListGetApi = createApiFunction({
  apiCall: userListGetApiConfig,
});

/** 유저정보 업데이트 */
const userUpdateApiConfig = async (data: UserType) => {
  const response = await apiClient.post(`${API_BASE_URL}/api/user`, data);
  return response.data;
};
export const userUpdateApi = createApiFunction({
  apiCall: userUpdateApiConfig,
  successMessage: (data: UserType) =>
    `${data.loginId} 계정의 정보가 수정되었습니다`,
});

/** 유저 삭제 */
const userDeleteApiConfig = async (loginId: string) => {
  const response = await apiClient.post(`${API_BASE_URL}/api/delete-user`, {
    loginId,
  });
  return response.data;
};
export const userDeleteApi = createApiFunction({
  apiCall: userDeleteApiConfig,
  successMessage: (data: string) => `${data} 계정이 삭제되었습니다`,
});

/** 코드기반 로그인 */
const loginByCodeApiConfig = async (code: string) => {
  const response = await axios.post(`${API_BASE_URL}/auth/login/code`, {
    code,
  });
  return response.data;
};
export const LoginByCodeApi = createApiFunction({
  apiCall: loginByCodeApiConfig,
});

/** 거래처목록 조회 */
const traderListGetApiConfig = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/api/trader`);
  return response.data;
};
export const traderListGetApi = createApiFunction({
  apiCall: traderListGetApiConfig,
});

/** 거래처목록 수정등록 */
const traderListPostApiConfig = async (data: LoginRequestType) => {
  const response = await apiClient.post(`${API_BASE_URL}/api/trader`, data);
  return response.data;
};
export const traderListPostApi = createApiFunction({
  apiCall: traderListPostApiConfig,
});

/** 이카운트 주문목록 엑셀파일 업로드 api */
const orderListPostApiConfig = async (data: OrderListUpdateRequestDto) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/api/order-excel`,
    data
  );
  return response.data;
};
export const orderListPostApi = createApiFunction({
  apiCall: orderListPostApiConfig,
});

/** 이카운트 주문목록 조회 api */
const orderListGetApiConfig = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/api/order-list`);
  return response.data;
};
export const orderListGetApi = createApiFunction({
  apiCall: orderListGetApiConfig,
});

/** 주소 수정해야할 주문목록 조회 api */
const orderToVerifyAddressListGetApiConfig = async () => {
  const response = await apiClient.get(
    `${API_BASE_URL}/api/orders/unverified-address`
  );
  return response.data;
};
export const orderToVerifyAddressListGetApi = createApiFunction({
  apiCall: orderToVerifyAddressListGetApiConfig,
});

/** 주소 수정 api */
const orderAddressDataPutApiConfig = async (
  datas: OrderAddressUpdateRequestType[]
) => {
  const response = await apiClient.put(
    `${API_BASE_URL}/api/orders/unverified-address`,
    datas
  );
  return response.data;
};
export const orderAddressDataPutApi = createApiFunction({
  apiCall: orderAddressDataPutApiConfig,
});

/** 배송담당자 전부 조회 api */
const deliveryManagerListGetApiConfig = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/api/delivery-persons`);
  return response.data;
};
export const deliveryManagerListGetApi = createApiFunction({
  apiCall: deliveryManagerListGetApiConfig,
});

/** 작업장정보 전부 조회 api */
const workbayListGetApiConfig = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/api/workbay`);
  return response.data;
};
export const workbayListGetApi = createApiFunction({
  apiCall: workbayListGetApiConfig,
});

/** 품목중 '제품'만 전부 조회 api */
const productItemListGetApiConfig = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/api/item-product`);
  return response.data;
};
export const productItemListGetApi = createApiFunction({
  apiCall: productItemListGetApiConfig,
});

/** 품목 전부 조회 api */
const itemListGetApiConfig = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/api/item`);
  return response.data;
};
export const itemListGetApi = createApiFunction({
  apiCall: itemListGetApiConfig,
});

/** 생산테이블저장 api */
const productionPostApiConfig = async (data: ProductionType) => {
  const response = await apiClient.post(`${API_BASE_URL}/api/production`, data);
  return response.data;
};
export const productionPostApi = createApiFunction({
  apiCall: productionPostApiConfig,
});

/** 생산일정 존재하는 날짜 리스트 조회 */
const productionDaylistFindApiConfig = async (
  data: ProductionExistDayReqType
) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/api/production/day-list`,
    data
  );
  return response.data;
};
export const productionDaylistFindApi = createApiFunction({
  apiCall: productionDaylistFindApiConfig,
});

/** 생산일정 조회 */
const productionListFindApiConfig = async (data: ProductionExistDayReqType) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/api/production/list`,
    data
  );
  return response.data;
};
export const productionListFindApi = createApiFunction({
  apiCall: productionListFindApiConfig,
});

/** 생산일정 진행상태 업데이트 */
const productionProgressUpdatePostApiConfig = async (
  data: ProductionProgressUpdateReqType
) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/api/production/progress`,
    data
  );
  return response.data;
};
export const productionProgressUpdatePostApi = createApiFunction({
  apiCall: productionProgressUpdatePostApiConfig,
});

/** 배송배정 업데이트 */
const deliveryAssignmentUpdateApiConfig = async (
  data: DeliveryAssignmentRequestType
) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/api/delivery-assignment`,
    data
  );
  return response.data;
};
export const deliveryAssignmentUpdateApi = createApiFunction({
  apiCall: deliveryAssignmentUpdateApiConfig,
});

/** 배송배정 조회 */
const deliveryAssignmentGetApiConfig = async (targetDate: string) => {
  const response = await apiClient.get(
    `${API_BASE_URL}/api/delivery-assignment?targetDt=${targetDate}`
  );
  return response.data;
};
export const deliveryAssignmentGetApi = createApiFunction({
  apiCall: deliveryAssignmentGetApiConfig,
});

/** 배송배정 되지 않은 && 배송방법 직배인 주문 조회 */
const deliveryTargetOrdersGetApiConfig = async () => {
  const response = await apiClient.get(
    `${API_BASE_URL}/api/orders/unassigned-delivery`
  );
  return response.data;
};
export const deliveryTargetOrdersGetApi = createApiFunction({
  apiCall: deliveryTargetOrdersGetApiConfig,
});

/** 출고일정  조회 */

const DeliveryScheduleGetApiConfig = async (data: {
  startDate: string;
  endDate: string;
}) => {
  const { startDate, endDate } = data; // 객체에서 추출
  const response = await apiClient.get(
    `${API_BASE_URL}/api/out-going?startDate=${startDate}&endDate=${endDate}`
  );
  return response.data;
};

export const DeliveryScheduleGetApi = createApiFunction({
  apiCall: DeliveryScheduleGetApiConfig,
});

/** 상차장소 리스트 조회 */
const LoadingLocationGetApiConfig = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/api/loading-location`);
  return response.data;
};

export const LoadingLocationGetApi = createApiFunction({
  apiCall: LoadingLocationGetApiConfig,
});

/** 상차장소 업데이트 */
const UpdateLoadingLocationPutApiConfig = async (data: {
  ordersItemId: number; // 품목 id
  loadingLocationId: number; // 상차 장소 id
}) => {
  const response = await apiClient.put(
    `${API_BASE_URL}/api/out-going/loading-location`,
    data
  );
  return response.data;
};

export const UpdateLoadingLocationPutApi = createApiFunction({
  apiCall: UpdateLoadingLocationPutApiConfig,
});

/** 상차장소 등록 */
const LoadingLocationPostApiConfig = async (data: {
  name: string; // 상차 장소 이름름
}) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/api/loading-location`,
    data
  );
  return response.data;
};

export const LoadingLocationPostApi = createApiFunction({
  apiCall: LoadingLocationPostApiConfig,
});

/** 출고 상태 변경 API */
const PreparationPutApiConfig = async (data: {
  id: number; // 품목 id
  preparationState: string; // 출고 상태 명명
}) => {
  const response = await apiClient.put(
    `${API_BASE_URL}/api/preparation-state`,
    data
  );
  return response.data;
};

export const PreparationPutApi = createApiFunction({
  apiCall: PreparationPutApiConfig,
});

/** 특정아이템 bom 조회 */
const itemBomListGetApiConfig = async (id: number) => {
  const response = await apiClient.get(`${API_BASE_URL}/api/item-bom/${id}`);
  return response.data;
};
export const itemBomListGetApi = createApiFunction({
  apiCall: itemBomListGetApiConfig,
});

/** 전체 bom 조회 */
const bomListGetApiConfig = async (id: number) => {
  const response = await apiClient.get(`${API_BASE_URL}/api/bom`);
  return response.data;
};
export const bomListGetApi = createApiFunction({
  apiCall: bomListGetApiConfig,
});

/** BOM 등록 */
const bomPostApiConfig = async (data: BomRequestType) => {
  const response = await apiClient.post(`${API_BASE_URL}/api/bom`, data);
  return response.data;
};
export const bomPostApi = createApiFunction({
  apiCall: bomPostApiConfig,
});

/** 생산테이블 수량증가 post */
const productionCntIncreaseApiConfig = async (data: ProductionCntIncrease) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/api/production/production-count`,
    data
  );
  return response.data;
};
export const productionCntIncreaseApi = createApiFunction({
  apiCall: productionCntIncreaseApiConfig,
});

/** 생산테이블 재고변동현황 조회 */
const productionStockHistoryFindApiConfig = async (
  data: ProductionStockHistoryRequestType
) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/api/production-stock-history`,
    data
  );
  return response.data;
};

export const productionStockHistoryFindApi = createApiFunction({
  apiCall: productionStockHistoryFindApiConfig,
});

/** 택배송장 품목 옵션값 전체조회 */
const parcelItemListAllGetApiConfig = async () => {
  const response = await apiClient.get(
    `${API_BASE_URL}/api/parcel-item-list-all`
  );
  return response.data;
};

export const parcelItemListAllGetApi = createApiFunction({
  apiCall: parcelItemListAllGetApiConfig,
});

/** 택배송장 거래처 옵션값 전체조회 */
const parcelTraderListAllGetApiConfig = async () => {
  const response = await apiClient.get(
    `${API_BASE_URL}/api/parcel-trader-list-all`
  );
  return response.data;
};

export const parcelTraderListAllGetApi = createApiFunction({
  apiCall: parcelTraderListAllGetApiConfig,
});

/** 택배송장 품목 옵션값 품목코드로 조회 */
const parcelItemListGetApiConfig = async (data: string[]) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/api/parcel-item-list`,
    data
  );
  return response.data;
};

export const parcelItemListGetApi = createApiFunction({
  apiCall: parcelItemListGetApiConfig,
});

/** 택배송장 품목 옵션값 수정/등록 */
const parcelItemPostApiConfig = async (data: ParcelItemResponseType) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/api/parcel-item`,
    data
  );
  return response.data;
};
export const parcelItemPostApi = createApiFunction({
  apiCall: parcelItemPostApiConfig,
});

/** 택배송장 거래처 옵션값 거래처코드로 조회 */
const parcelTraderListGetApiConfig = async (data: string[]) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/api/parcel-trader-list`,
    data
  );
  return response.data;
};

export const parcelTraderListGetApi = createApiFunction({
  apiCall: parcelTraderListGetApiConfig,
});

/** 택배송장 거래처 옵션값 수정/등록 */
const parcelTraderPostApiConfig = async (data: ParcelItemResponseType) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/api/parcel-trader`,
    data
  );
  return response.data;
};
export const parcelTraderPostApi = createApiFunction({
  apiCall: parcelTraderPostApiConfig,
});

/** 재고테이블 최종업데이트날짜 조회 */
const stockLatestUpdatedTimeGetApiConfig = async () => {
  const response = await apiClient.get(
    `${API_BASE_URL}/api/stock-updated-time`
  );
  return response.data;
};
export const stockLatestUpdatedTimeGetApi = createApiFunction({
  apiCall: stockLatestUpdatedTimeGetApiConfig,
});

/** 재고테이블 수동업데이트 */
const stockUpdateApiConfig = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/api/stock`);
  return response.data;
};
export const stockUpdateApi = createApiFunction({
  apiCall: stockUpdateApiConfig,
});

/** 품목테이블 수동업데이트 */
const itemUpdateApiConfig = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/api/item-update`);
  return response.data;
};
export const itemUpdateApi = createApiFunction({
  apiCall: itemUpdateApiConfig,
});

/** 나라장터 주문 수동업데이트 */
const govshopOrderPostToEcountApiConfig = async () => {
  const response = await apiClient.get(
    `${API_BASE_URL}/api/gov-shop-orders-dlvr`
  );
  return response.data;
};
export const govshopOrderPostToEcountApi = createApiFunction({
  apiCall: govshopOrderPostToEcountApiConfig,
});

/** 나라장터 주문 수동업데이트 */
const emmshopOrderPostToEcountApiConfig = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/api/emm-shop`);
  return response.data;
};
export const emmshopOrderPostToEcountApi = createApiFunction({
  apiCall: emmshopOrderPostToEcountApiConfig,
});

/** 자사쇼핑몰 changeFlag y 조회 */
const emmShopOrderListGetApiConfig = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/api/emm-shop-changed`);
  return response.data;
};
export const emmShopOrderListGetApi = createApiFunction({
  apiCall: emmShopOrderListGetApiConfig,
});

/** 자사쇼핑몰 changeFlag Y 품목을 N으로 변경  */
const emmShopChangedOrderConfirmApiConfig = async (id: number) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/api/emm-shop/change-flag/${id}`
  );
  return id;
};
export const emmShopChangedOrderConfirmApi = createApiFunction({
  apiCall: emmShopChangedOrderConfirmApiConfig,
});
/** 스케줄러 시작 */
const executeSchedulerPostApiConfig = async () => {
  const response = await apiClient.post(`${API_BASE_URL}/api/execute`);
  return response;
};
export const executeSchedulerPostApi = createApiFunction({
  apiCall: executeSchedulerPostApiConfig,
});

/** 독립 재고변동 생성 or 기존값 조회 */
const unsyncedStockHistoryPostApiConfig = async (data: {
  workbayId: number;
  itemId: number;
}) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/api/unsynced-stock-history`,
    data
  );
  return response.data;
};
export const unsyncedStockHistoryPostApi = createApiFunction({
  apiCall: unsyncedStockHistoryPostApiConfig,
});

/** 독립 재고변동 수량변경 */
const unsyncedStockHistoryQtyPostApiConfig = async (data: {
  stockHistoryId: number;
  changedQty: number;
}) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/api/unsynced-stock-history-qty`,
    data
  );
  return response.data;
};
export const unsyncedStockHistoryQtyPostApi = createApiFunction({
  apiCall: unsyncedStockHistoryQtyPostApiConfig,
});
