import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styled, { css, useTheme } from 'styled-components';
export const CustomCircularProgressbar = ({
  current,
  goal,
  style,
}: {
  current: number;
  goal: number;
  style?: React.CSSProperties;
}) => {
  const theme = useTheme();
  const percentage = (current / goal) * 100;
  const isExceeded = current > goal;

  return (
    <div style={{ position: 'relative', ...style }}>
      <CircularProgressbar
        value={percentage}
        strokeWidth={11}
        // text={`달성률\n${Math.min(percentage, 100).toFixed(0)}%`}
        styles={buildStyles({
          pathColor: isExceeded ? theme.colors.blue : theme.colors.lightBlue, // 목표 초과시 진한 색상
          trailColor: '#e0e0e0',
          textColor: '#333',
          pathTransitionDuration: 0.5,
          textSize: theme.fontsize[14],
        })}
      />
      <TextSection isExceeded={isExceeded}>
        <div className='title'>달성률</div>
        <div className='progress'>{percentage.toFixed(0)}%</div>
      </TextSection>
    </div>
  );
};

const TextSection = styled.div<{ isExceeded: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .title {
    font-size: 0.8em;
    font-weight: 700;
  }
  .progress {
    font-size: 0.8em;
    font-weight: 700;
  }

  ${({ isExceeded }) =>
    isExceeded &&
    css`
      .progress {
        color: ${({ theme }) => theme.colors.blue};
      }
    `}
`;
