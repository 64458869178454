import styled from 'styled-components';
import { RootState } from '../../../store/modules';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import { setTabletNavSidebarWidth } from '../../../store/modules/tabletNavSidebar';

export const TabletSidebarWrapper = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const location = useLocation();

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (location.pathname) {
      console.log(location.pathname);
      if (location.pathname.startsWith('/production-progress-tablet')) {
        if (location.pathname === '/production-progress-tablet') {
          dispatch(setTabletNavSidebarWidth(300));
        } else {
          dispatch(setTabletNavSidebarWidth(400));
        }
      } else if (location.pathname.startsWith('/production-bom')) {
        if (location.pathname === '/production-bom') {
          dispatch(setTabletNavSidebarWidth(300));
        } else {
          dispatch(setTabletNavSidebarWidth(400));
        }
      } else if (location.pathname.startsWith('/delivery-schedule')) {
        dispatch(setTabletNavSidebarWidth(310));
      } else {
        dispatch(setTabletNavSidebarWidth(0));
      }
    }
  }, [location]);

  const tabletSidebarOpenState = useSelector(
    (state: RootState) => state.tabletNavSidebar.openState
  );
  const sidebarWidth = useSelector(
    (state: RootState) => state.tabletNavSidebar.width
  );

  console.log(sidebarWidth);

  return (
    <Wrapper
      id='sidebar-wrapper'
      className={`sidebar-wrapper`}
      $open={tabletSidebarOpenState}
      $width={sidebarWidth ?? 0}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $open: boolean; $width: number }>`
  height: 100vh;

  width: ${({ $open, $width }) => ($open ? `${$width}px` : '0px')};

  background-color: ${({ theme }) => theme.colors.softBlack};
  /* background-color: #444955; */
  transition: width 0.3s ease; /* 부드러운 애니메이션 */
  overflow: hidden;

  position: relative;
`;
