import { combineReducers } from 'redux';
import sidebarReducer from './sidebar';
import loginUserDataReducer from './loginUserData';
import socketDataReducer from './socketData';
import headerReducer from './header';
import tabletNavHeaderReducer from './tabletNavHeader';
import tabletNavSidebarReducer from './tabletNavSidebar';
import tabletOptionReducer from './tabletOption';
import deliveryReducer from './delivery';

const rootReducer = combineReducers({
  sidebar: sidebarReducer,
  header: headerReducer,
  loginUserData: loginUserDataReducer,
  socketData: socketDataReducer,
  tabletNavHeader: tabletNavHeaderReducer,
  tabletNavSidebar: tabletNavSidebarReducer,
  tabletOption: tabletOptionReducer,
  delivery: deliveryReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
