import {
  HTMLProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { orderData, OrderHeaderType } from '../TableTestdata';
import {
  ColumnDef,
  Row,
  RowData,
  TableMeta,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import * as Styled from '../TableStyled';
import TableConfig from '../TableConfig';
import { useHeaderButtons } from '../../header/HeaderButtonProvider';
import SquareButton from '../../button/SquareButton';
import { AiFillCaretRight } from 'react-icons/ai';
import { AiFillCaretDown } from 'react-icons/ai';
import ListTooltip from '../../common/ListTooltip';
import { ValueWithKey } from '../TableTestdata';
import { traderData } from '../TableTestdata';
import TraderUploadModal from '../../common/modal/ModalComponents/TraderUploadModal';
import axios from 'axios';
import { useTheme } from 'styled-components';
import { toast } from 'react-hot-toast';
import {
  keyMapping,
  orderAddressDataPutApi,
  OrderAddressUpdateRequestType,
  orderListGetApi,
  orderListPostApi,
  orderToVerifyAddressListGetApi,
  OrderUploadResponseType,
  traderListGetApi,
  traderListPostApi,
  TraderType,
} from '../../../api/apiConfig';
import useFetch from '../../../api/useFetch';
import { OrderUploadModal } from '../../common/modal/ModalComponents/OrderUploadModal';
//주문번호 예시 20241104-1

import { BsChevronDoubleLeft } from 'react-icons/bs';
import { BsChevronDoubleRight } from 'react-icons/bs';
import { BsChevronLeft } from 'react-icons/bs';
import { BsChevronRight } from 'react-icons/bs';

import { IoMdSearch } from 'react-icons/io';

import {
  OrderListType,
  OrderListUpdateRequestDto,
} from '../../../api/apiConfig';
import { PaginationState } from '../Pagination';
import {
  formatCurrency,
  formatEightDigitStringToSlashForm,
  getYearMonthDateFromDate,
} from '../../../function/replaceString';

import AddressSearchModal from '../../common/modal/ModalComponents/AddressSearchModal';

function mapKey(inputKey: string): keyof OrderListType | undefined {
  return keyMapping[inputKey];
}

export type AddressSearchResultType = {
  receiverAddress: string;
  latitude: number;
  longitude: number;
  zoneNo: string;
};

export const OrderAddressModifyTable = () => {
  const theme = useTheme();
  const { setButtons } = useHeaderButtons();

  const { fetch: fetchOrderToVerifyAddressListGetApi } = useFetch({
    fetchFunction: orderToVerifyAddressListGetApi,
    onSuccess: (data: OrderListType[]) => {
      setData(data);
      initAddressDatas(data);
    },
  });

  const { fetch: fetchOrderAddressDataPutApi } = useFetch({
    fetchFunction: orderAddressDataPutApi,
    onSuccess: (data: OrderListType[]) =>
      filterAfterValidate(
        data.map((item) => item.orderId || null).filter((item) => item !== null)
      ),
  });

  //   const { fetch: apiPostOrderList } = useFetch({
  //     fetchFunction: orderListPostApi,
  //     onSuccess: (data: OrderUploadResponseType) => {
  //       setUpdatedData(data);
  //       // toast('업데이트에 성공하였습니다.');
  //       // setData(data);
  //     },
  //   });

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [showModal, setShowModal] = useState<boolean>(false);

  const [modalType, setModalType] = useState<'edit' | 'add' | null>(null);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const targetOrderId = useRef<number | null>(null);

  //모든 테이블값
  const [data, setData] = useState<OrderListType[]>([]);

  const addressDatas = useRef<OrderAddressUpdateRequestType[]>([]);

  const [targetAddressData, setTargetAddressData] =
    useState<OrderAddressUpdateRequestType | null>(null);

  //테이블 옵션값 ~~~
  const [headerOverflowType, setHeaderOverflowType] = useState<'wrap' | 'hide'>(
    'hide'
  );
  const [bodyOverflowType, setBodyOverflowType] = useState<'wrap' | 'hide'>(
    'hide'
  );
  const [bodyAlignHoriType, setBodyAlignHoriType] = useState<
    'left' | 'center' | 'right'
  >('left');

  const [cellHoriPaddValue, setCellHoriPaddValue] = useState<number>(5);
  const [bodyVertPaddValue, setBodyVertPaddValue] = useState<number>(5);

  const [showConfig, setShowConfig] = useState<boolean>(false);

  useEffect(() => {
    fetchOrderToVerifyAddressListGetApi();
  }, []);

  function initAddressDatas(datas: OrderListType[]) {
    const initDatas: OrderAddressUpdateRequestType[] = datas.map((data) => ({
      orderId: data.orderId!,
      receiverAddress: null,
      receiverAddressDetail: data.receiverAddressDetail || null,
      latitude: null,
      longitude: null,
      zoneNo: null,
    }));
    console.log('initDatas', initDatas);
    addressDatas.current = initDatas;
  }

  const columns = useMemo<ColumnDef<OrderListType>[]>(
    () => [
      {
        //TH Cell
        header: ({ table }) => <>No</>,
        cell: ({ row, getValue }) => (
          <Styled.NoBodyCell style={{ paddingLeft: `${row.depth * 2}rem` }}>
            {row.getCanExpand() && (
              <Styled.NoText>{row.index + 1}</Styled.NoText>
            )}
          </Styled.NoBodyCell>
        ),
        id: 'NO',
        size: 79,
      },
      //   {
      //     header: '주문번호',
      //     accessorKey: 'ORDER_ID',
      //     size: 84,
      //   },
      {
        header: '거래처명',
        accessorKey: 'CUST_DES',
        size: 180,
      },
      //   {
      //     header: '담당자명',
      //     accessorKey: 'EMP_NAME',
      //     size: 65,
      //   },
      {
        header: '품목명',
        accessorKey: 'PROD_DES',
        size: 140,
      },
      //   {
      //     header: '규격',
      //     accessorKey: 'SIZE_DES',
      //     size: 140,
      //   },
      //   {
      //     header: '수량',
      //     accessorKey: 'QTY',
      //     size: 48,
      //   },
      //   {
      //     header: '단가',
      //     accessorKey: 'PRICE',
      //     size: 53,
      //     cell: (price) => formatCurrency(price.getValue() as number),
      //   },
      //   {
      //     header: '공급가액',
      //     accessorKey: 'SUPPLY_AMT',
      //     size: 73,
      //     cell: (supplyAmt) => formatCurrency(supplyAmt.getValue() as number),
      //   },
      {
        header: '납기일자',
        accessorKey: 'TIME_DATE',
        size: 140,
        cell: (timeDate) =>
          formatEightDigitStringToSlashForm(timeDate.getValue() as string),
      },
      {
        header: '주문방법',
        accessorKey: 'ORDER_TYPE',
        size: 66,
      },
      {
        header: '배송방법',
        accessorKey: 'DELIVERY_TYPE',
        size: 66,
      },
      {
        header: '주문특이사항',
        accessorKey: 'DESCRIPTION',
        size: 420,
      },
      {
        header: '주소',
        accessorKey: 'receiverAddress',
        size: 420,
        cell: ({ cell, row }) => {
          // cell.getValue<string>()으로 반환 타입을 지정
          const matchingItem =
            addressDatas.current.find(
              (addressData) =>
                addressData.orderId === row.original.orderId &&
                isAllFilled(addressData)
            ) || null;
          const value = cell.getValue<string>();
          if (row.depth > 0) return;
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                // border: '1px solid red',

                flex: 1,
                justifyContent: 'space-between',
                // gap: '15px',
                alignItems: 'center',
              }}
            >
              {matchingItem ? (
                `${matchingItem.receiverAddress}`
              ) : (
                <>
                  <div
                    style={{
                      textDecoration: 'underline',
                      textDecorationColor: 'red',
                      textUnderlineOffset: '4px',
                      color: 'red',
                    }}
                  >
                    {value}
                  </div>
                  <IoMdSearch
                    size={23}
                    color='black'
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      //   if (targetAddressData === null && row.original.orderId) {
                      targetOrderId.current = row.original.orderId!;
                      setOpenModal(true);
                      //   }
                    }}
                  />
                </>
              )}
            </div>
          );
        },
      },
      {
        header: '주소(상세)',
        accessorKey: 'receiverAddressDetail',
        size: 420,
        cell: ({ cell, row }) => {
          // cell.getValue<string>()으로 반환 타입을 지정
          const matchingItem =
            addressDatas.current.find(
              (addressData) =>
                addressData.orderId === row.original.orderId &&
                isAllFilled(addressData)
            ) || null;
          let value = matchingItem?.receiverAddressDetail || '';

          function setValue() {
            const newData = addressDatas.current.map((prev) =>
              prev.orderId === row.original.orderId
                ? { ...prev, receiverAddressDetail: value }
                : prev
            );
            addressDatas.current = newData;
          }

          if (row.depth > 0) return;
          return (
            <input
              style={{
                display: 'flex',
                flexDirection: 'row',
                // border: '1px solid red',
                border: 'none',
                backgroundColor: 'transparent',
                flex: 1,
                lineHeight: '20px',
                justifyContent: 'space-between',
                // gap: '15px',
                alignItems: 'center',
              }}
              defaultValue={value}
              onChange={(e) => {
                value = e.target.value;
              }}
              onBlur={setValue} // 포커스 아웃 시 실행
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setValue(); // Enter 입력 시 실행
                }
              }}
            ></input>
          );
        },
      },

      //   {
      //     header: '적요',
      //     accessorKey: 'REMARKS',
      //     size: 420,
      //   },
    ],
    [addressDatas.current]
  );

  function updateOrders() {
    const validItems = addressDatas.current
      .map((addressData) => (isAllFilled(addressData) ? addressData : null))
      .filter((data) => data !== null);
    fetchOrderAddressDataPutApi(validItems);
  }

  function filterAfterValidate(ids: number[]) {
    const filteredOrders = data.filter(
      (orderData) =>
        orderData.orderId !== undefined && !ids.includes(orderData.orderId)
    );
    initAddressDatas(filteredOrders);
    setData(filteredOrders);
  }

  function isAllFilled(data: OrderAddressUpdateRequestType) {
    return (
      data.orderId !== null &&
      data.latitude !== null &&
      data.longitude !== null &&
      data.receiverAddress !== null &&
      data.zoneNo !== null
    );
  }

  //아마 페이지네이션
  function useSkipper() {
    const shouldSkipRef = useRef(true);
    const shouldSkip = shouldSkipRef.current;

    // Wrap a function with this to skip a pagination reset temporarily
    const skip = useCallback(() => {
      shouldSkipRef.current = false;
    }, []);

    useEffect(() => {
      shouldSkipRef.current = true;
    });

    return [shouldSkip, skip] as const;
  }

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  //~~페이지네이션

  useEffect(() => {
    //헤더에 버튼 추가
    setButtons(
      <>
        <SquareButton
          text='주소 검색'
          colorType='ACTIVE'
          onClick={() => {
            // setModalType('add');
            // setShowModal(true);
          }}
        />
        <SquareButton
          text='전체 저장'
          colorType={
            addressDatas.current.some((addressData) => isAllFilled(addressData))
              ? 'ACTIVE'
              : 'DISACTIVE'
          }
          onClick={() => {
            addressDatas.current.some((addressData) =>
              isAllFilled(addressData)
            ) && updateOrders();
          }}
        />
      </>
    );
    // 페이지가 바뀌면 버튼 초기화
    return () => setButtons(null);
  }, [setButtons, addressDatas.current]);

  const table = useReactTable({
    data,
    columns,
    columnResizeMode: 'onChange',
    state: {
      expanded: true,
      pagination,
    },
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    autoResetPageIndex,
    getPaginationRowModel: getPaginationRowModel(),
    paginateExpandedRows: false,
    onPaginationChange: setPagination,
  });

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]!;
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }
    return colSizes;
  }, [table.getState().columnSizingInfo, table.getState().columnSizing]);

  const updateAddressData = useCallback(
    (data: AddressSearchResultType) => {
      console.log('addressDatas', addressDatas.current);
      console.log('orderId', targetOrderId.current);
      // setAddressDatas((prev) =>
      //   prev.map((address) =>
      //     address.orderId === targetOrderId.current
      //       ? {
      //           ...address,
      //           receiverAddress: data.receiverAddress,
      //           latitude: data.latitude,
      //           longitude: data.longitude,
      //           zoneNo: data.zoneNo,
      //         }
      //       : address
      //   )
      // );
      const updatedDatas = addressDatas.current.map((address) =>
        address.orderId === targetOrderId.current
          ? {
              ...address,
              receiverAddress: data.receiverAddress,
              latitude: data.latitude,
              longitude: data.longitude,
              zoneNo: data.zoneNo,
            }
          : address
      );
      addressDatas.current = updatedDatas;
      targetOrderId.current = null;
      setOpenModal(false);
    },
    [addressDatas.current, targetOrderId.current]
  );

  return (
    <>
      <AddressSearchModal
        isOpen={openModal && targetOrderId.current !== null}
        onClose={() => setOpenModal(false)}
        onSelect={(data: AddressSearchResultType) => updateAddressData(data)}
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Styled.PaginationButtonContainer>
          <Styled.PaginationButton
            size={28}
            onClick={() => table.firstPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <BsChevronDoubleLeft />
          </Styled.PaginationButton>
          <Styled.PaginationButton
            size={28}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <BsChevronLeft />
          </Styled.PaginationButton>
          {/* 중간인풋 */}
          <Styled.PaginationButton
            size={28}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <BsChevronRight />
          </Styled.PaginationButton>
          <Styled.PaginationButton
            size={28}
            onClick={() => table.lastPage()}
            disabled={!table.getCanNextPage()}
          >
            <BsChevronDoubleRight />
          </Styled.PaginationButton>
        </Styled.PaginationButtonContainer>
        <div
          style={{
            alignContent: 'center',
            marginLeft: '20px',
            marginRight: '40px',
          }}
        >
          {table.getPageCount()}페이지 중{' '}
          {table.getState().pagination.pageIndex + 1}페이지
        </div>
        <div className='flex-center'>페이지당 표시 데이터 수</div>
        <select
          id={'row-count'}
          value={pagination.pageSize}
          onChange={(e) => {
            setPagination((prev) => ({
              pageIndex: prev.pageIndex,
              pageSize: Number(e.target.value),
            }));
            table.firstPage();
          }}
          style={{ marginLeft: '14px' }}
        >
          <option key={'10'} value={10}>
            10개
          </option>
          <option key={'20'} value={20}>
            20개
          </option>
          <option key={'30'} value={30}>
            30개
          </option>
          <option key={'40'} value={40}>
            40개
          </option>
          <option key={'50'} value={50}>
            50개
          </option>
        </select>
      </div>

      <Styled.TableContainer>
        <Styled.TableOptionButton onClick={() => setShowConfig(!showConfig)}>
          설정
        </Styled.TableOptionButton>
        <TableConfig
          show={showConfig}
          Header_Overflow_Type={headerOverflowType}
          setHeaderOverflowType={(value: 'hide' | 'wrap') =>
            setHeaderOverflowType(value)
          }
          Body_Overflow_Type={bodyOverflowType}
          setBodyOverflowType={(value: 'hide' | 'wrap') =>
            setBodyOverflowType(value)
          }
          Body_Align_Hori_Type={bodyAlignHoriType}
          setBodyAlignHoriType={(value: 'left' | 'center' | 'right') =>
            setBodyAlignHoriType(value)
          }
          Cell_Hori_Padd_Value={cellHoriPaddValue}
          setCellHoriPaddValue={(value: number) => setCellHoriPaddValue(value)}
          Body_Vert_Padd_Value={bodyVertPaddValue}
          setBodyVertPaddValue={(value: number) => setBodyVertPaddValue(value)}
        />
        <Styled.TableDiv
          {...{
            style: {
              ...columnSizeVars,
              width: table.getTotalSize(),
            },
          }}
          headOverflowType={headerOverflowType}
          bodyOverflowType={bodyOverflowType}
          bodyAlignHoriType={bodyAlignHoriType}
          cellHoriPaddValue={cellHoriPaddValue}
          bodyVertPaddValue={bodyVertPaddValue}
        >
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <Styled.ThDiv {...{ key: headerGroup.id }}>
                {headerGroup.headers.map((headerCell) =>
                  headerCell.isPlaceholder ? null : (
                    <Styled.ThCell
                      {...{
                        key: headerCell.id,
                        style: {
                          width: `calc(var(--header-${headerCell?.id}-size) * 1px)`,
                        },
                        className: 'th',
                      }}
                    >
                      {headerCell.isPlaceholder
                        ? null
                        : flexRender(
                            headerCell.column.columnDef.header,
                            headerCell.getContext()
                          )}
                      <Styled.ResizeHandler
                        {...{
                          onMouseDown: headerCell.getResizeHandler(),
                          className: `resizer ${
                            headerCell.column.getIsResizing()
                              ? 'isResizing'
                              : ''
                          }`,
                        }}
                      />
                    </Styled.ThCell>
                  )
                )}
              </Styled.ThDiv>
            );
          })}
          {table.getRowModel().rows.map((row) => (
            <>
              <Styled.TdDiv
                {...{
                  key: row.id,
                  style: {
                    // 전체 배경 색 변경 (변경된 필드가 있으면)
                    backgroundColor:
                      targetOrderId.current &&
                      targetOrderId.current === row.original.orderId
                        ? theme.colors.lime
                        : (row.original.changedOrderFields &&
                            row.original.changedOrderFields.length > 0) ||
                          row.original.subRows?.some(
                            (subRow) =>
                              subRow.changedItemFields &&
                              subRow.changedItemFields?.length > 0
                          )
                        ? theme.colors.lime
                        : 'transparent',
                  },
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  const isMainRow = row.depth === 0;

                  // 서브로우에서 각 셀의 변경된 필드 확인 (셀이 속한 서브로우마다)
                  let subRowChangedFields = null;
                  if (!isMainRow) {
                    // 메인로우가 아닐 경우, 자기 자신에서만 변경된 필드를 찾음
                    subRowChangedFields = row.original.changedItemFields
                      ? row.original.changedItemFields.find(
                          (field) => mapKey(field) === cell.column.id
                        )
                      : null;
                  } else {
                    // 서브로우에서 찾는 경우
                    subRowChangedFields = row.original.subRows
                      ? row.original.subRows.reduce(
                          (acc, subRow, subRowIndex) => {
                            if (subRow.changedItemFields) {
                              if (
                                subRow.changedItemFields.find(
                                  (field) => mapKey(field) === cell.column.id
                                )
                              ) {
                                acc[subRowIndex] = true; // 해당 서브로우의 셀이 변경되었음을 표시
                              }
                            }
                            return acc;
                          },
                          {} as Record<number, boolean>
                        )
                      : null;
                  }

                  // 메인로우에서 변경된 필드 확인 (주문 필드 변경)
                  const isHighlighted =
                    (isMainRow &&
                      row.original.changedOrderFields &&
                      row.original.changedOrderFields.find(
                        (field) => mapKey(field) === cell.column.id
                      )) || // 메인로우의 changedOrderFields
                    (subRowChangedFields && subRowChangedFields[row.index]); // 서브로우의 changedItemFields와 일치하는 셀

                  // 커스텀 스타일 (변경된 셀에 하이라이트 적용)
                  return (
                    <Styled.TdCell
                      {...{
                        key: cell.id,
                        className: 'td',
                        style: {
                          // 해당 셀이 변경되었으면 배경 색을 노란색으로 하이라이트
                          backgroundColor: isMainRow
                            ? isHighlighted
                              ? 'rgb(253 255 85 / 26%)'
                              : // : '#3535350a'
                                theme.colors.white_odd
                            : 'transparent',
                          width: `calc(var(--header-${cell.column.id}-size) * 1px)`, // 셀 크기
                        },
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Styled.TdCell>
                  );
                })}
              </Styled.TdDiv>
            </>
          ))}
        </Styled.TableDiv>
      </Styled.TableContainer>
    </>
  );
};
