import { DefaultTheme } from 'styled-components';

// vscode-color-picker 색 미리보기 vscode extension
const colors = {
  white: '#FFFFFF',
  white_odd: 'rgba(53, 53, 53, 0.04)',
  white_odd_hover: 'rgb(47 47 47 / 11%)',
  white_hover: '#e9e7e7',
  black: '#171717',
  darkBlue: '#edeef1',
  softBlack: '#1e232e',
  darkNavy: '#2b3244',
  brightNavy: '#586688',
  brightBlue: '#7a90a9',
  blue: '#4ba7ff',
  blue_hover: '#439cf2',
  lightBlue: '#8dc4e6',
  grapeFruit: '#F48B78',
  grapeFruit_hover: '#e87662',
  whiteGray: '#f0f0f0',
  lightGray: '#eeeeee',
  brightGray: '#eeeeee',
  darkGray: '#a5a5a5',
  charcoal: '#595B60',
  softGray: '#cfcfcf',
  blueGreen: '#99ffdb',
  lime: '#edffaa7a',
  wine: '#cb4d79',
  red: 'rgb(255 85 85)',
  green: '#A5DD91',
  whiteGreen: '#E6F6E1',
  softRed: 'rgb(255 231 227)',
  softGreen: 'rgb(227 255 222)',
};

const fontsize = {
  header_main: '28px',
  header_pri: '16px',
  header_semi: '14px',
  table_header: '12px',
  table_body: '11px',
  small: '8px',
  12: '12px',
  13: '13px',
  14: '14px',
  15: '15px',
  16: '16px',
  17: '17px',
  18: '18px',
  19: '19px',
  20: '20px',
  21: '21px',
  22: '22px',
  23: '23px',
  24: '24px',
  25: '25px',
  26: '26px',
  27: '27px',
  28: '28px',
  29: '29px',
  30: '30px',
  31: '31px',
  32: '32px',
  33: '33px',
  34: '34px',
  35: '35px',
  36: '36px',
};

export const theme: DefaultTheme = {
  colors,
  fontsize,
};

export default theme;

export type ThemeColorTypes = typeof colors;

export type ThemeFontsize = typeof fontsize;
