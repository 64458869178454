import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DELIVERY_ORDER, DELIVERY_TPYE } from '../../../@const/sidebarMenu';
import DateDropdown from '../components/DateDropdown';
import { useParams } from 'react-router-dom';
import useFetch from '../../../api/useFetch';
import {
  DeliveryScheduleGetApi,
  DeliveryScheduleResponseType,
} from '../../../api/apiConfig';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/modules';
import {
  setDeliveryData,
  setDeliveryType,
  setEndDate,
  setIsRefresh,
  setOrder,
  setStartDate,
} from '../../../store/modules/delivery';

// 오늘 날짜를 반환하는 함수
const getToday = () => {
  const today = new Date();
  return {
    year: today.getFullYear(),
    month: today.getMonth() + 1, // JavaScript의 월은 0부터 시작하므로 +1
    day: today.getDate(),
  };
};

const DeliverySideBar = () => {
  const dispatch = useDispatch();
  const { deliveryType, order, isRefresh } = useSelector(
    (state: RootState) => state.delivery
  );
  // 오늘 날짜를 기본값으로 설정
  const [startDate, setSDate] = useState(getToday());
  const [endDate, setEDate] = useState(getToday());

  const handleType = (selected: string) => {
    if (deliveryType === selected) {
      dispatch(setDeliveryType('전체'));
    } else {
      dispatch(setDeliveryType(selected));
    }
  };

  const handleOrder = (selected: string) => {
    if (order === selected) {
      dispatch(setOrder('가까운 날짜순'));
    } else {
      dispatch(setOrder(selected));
    }
  };

  // 출고 일정 조회 API
  const { fetch: DeliveryScheduleGet } = useFetch({
    fetchFunction: DeliveryScheduleGetApi,
    onSuccess: (data: DeliveryScheduleResponseType[]) => {
      console.log('DeliveryScheduleGetApi :  성공');
      dispatch(setDeliveryData(data));
    },
  });

  // 날짜를 YYYYMMDD 형식으로 변환하는 함수
  const formatDateToString = (date: {
    year: number;
    month: number;
    day: number;
  }): string => {
    const { year, month, day } = date;
    const formattedMonth = month.toString().padStart(2, '0'); // 월을 2자리로
    const formattedDay = day.toString().padStart(2, '0'); // 일을 2자리로
    return `${year}${formattedMonth}${formattedDay}`;
  };
  useEffect(() => {
    if (startDate && endDate) {
      // 날짜 변환
      const formattedStartDate = formatDateToString(startDate);
      const formattedEndDate = formatDateToString(endDate);

      // 요청 전송
      DeliveryScheduleGet({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (isRefresh) {
      dispatch(setIsRefresh(false));
      if (startDate && endDate) {
        // 날짜 변환
        const formattedStartDate = formatDateToString(startDate);
        const formattedEndDate = formatDateToString(endDate);

        // 요청 전송
        DeliveryScheduleGet({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });
      }
    }
  }, [isRefresh]);

  return (
    <Container>
      {/* 배송방법  */}
      <Section>
        <Title>배송방법</Title>
        <TagDiv>
          {DELIVERY_TPYE.map((item, index) => (
            <Tag
              key={index}
              $isSelected={deliveryType === item}
              onClick={() => handleType(item)}
            >
              {item}
            </Tag>
          ))}
        </TagDiv>
      </Section>
      {/* 납기일 검색  */}
      <Section>
        <Title>납기일 검색</Title>
        <DateDropdown
          date={startDate}
          onChangeDate={(date) => {
            dispatch(setStartDate(date));
            setSDate(date);
          }}
        />
        <Title>부터</Title>
        <DateDropdown
          date={endDate}
          onChangeDate={(date) => {
            dispatch(setEndDate(date));
            setEDate(date);
          }}
        />
        <Title>까지</Title>
      </Section>
      {/* 정렬  */}
      <Section>
        <Title>정렬</Title>
        <OrderTagDiv>
          {DELIVERY_ORDER.map((item, index) => (
            <Tag
              key={index}
              $isSelected={order === item}
              onClick={() => handleOrder(item)}
            >
              {item}
            </Tag>
          ))}
        </OrderTagDiv>
      </Section>
    </Container>
  );
};

export default DeliverySideBar;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 30px;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  color: #fff;
  font-size: calc(0.7rem + 1vw);
  font-weight: 600;
`;

const TagDiv = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const OrderTagDiv = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-direction: column;
`;

const Tag = styled.div<{ $isSelected: boolean }>`
  display: inline-flex;
  width: fit-content;
  color: #fff;
  font-size: calc(0.2rem + 1vw);
  font-weight: 700;
  border-radius: 10px;
  border: ${({ $isSelected }) => ($isSelected ? '#557fae' : '#fff')} 1px solid;
  padding: 5px 7px;
  background-color: ${({ $isSelected }) =>
    $isSelected ? '#557fae' : 'transparent'};
`;
