import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/modules';
import { useEffect, useState } from 'react';
import { setTabletNavSidebarWidth } from '../../../store/modules/tabletNavSidebar';
import styled from 'styled-components';
import useFetch from '../../../api/useFetch';
import { workbayListGetApi, WorkbayType } from '../../../api/apiConfig';
import {
  clearSelectedWorkbayTablet,
  setSelectedProdType,
  setSelectedWorkbayTablet,
} from '../../../store/modules/tabletOption';

type ProdInfoListType = {
  name: string;
  prodType: number;
};

const prodInfoList: ProdInfoListType[] = [
  {
    name: '전체',
    prodType: -1,
  },
  {
    name: '제품',
    prodType: 1,
  },
  {
    name: '원재료',
    prodType: 0,
  },
];

export const TabletBomSideBar = () => {
  const tabletSidebarWidth = useSelector(
    (state: RootState) => state.tabletNavSidebar.width
  );

  const selectedProdTypeTablet = useSelector(
    (state: RootState) => state.tabletOption.selectedProdType
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // 컴포넌트 마운트 시
    dispatch(setTabletNavSidebarWidth(300));

    // 언마운트
    return () => {};
  }, []);

  return (
    <Container $width={tabletSidebarWidth ?? 0}>
      {prodInfoList.map((prodInfo) => (
        <ListItem
          key={prodInfo.prodType}
          className='sidebar-listitem-text'
          $selected={selectedProdTypeTablet === prodInfo.prodType}
          onClick={() => {
            selectedProdTypeTablet !== prodInfo.prodType &&
              dispatch(setSelectedProdType(prodInfo.prodType));
          }}
        >
          {prodInfo.name}
        </ListItem>
      ))}
    </Container>
  );
};

const Container = styled.div<{ $width: number }>`
  width: ${({ $width }) => `${$width}px`};
`;

const ListItem = styled.text<{ $selected: boolean }>`
  display: flex;
  width: 100%;
  background-color: ${({ $selected, theme }) =>
    $selected ? '#5a5a5a' : 'transparent'};
  color: white;
  height: 45px;
  align-items: center;
  justify-content: center;
`;
