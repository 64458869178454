import { DeliveryScheduleResponseType } from '../../api/apiConfig';

// delivery.ts
const SET_DELIVERY_TYPE = 'delivery/SET_DELIVERY_TYPE' as const;
const SET_START_DATE = 'delivery/SET_START_DATE' as const;
const SET_END_DATE = 'delivery/SET_END_DATE' as const;
const SET_ORDER = 'delivery/SET_ORDER' as const;
const SET_DELIVERY_DATA = 'delivery/SET_DELIVERY_DATA' as const;
const SET_IS_LOADING = 'delivery/SET_IS_LOADING' as const;
const SET_IS_REFRESH = 'delivery/SET_IS_REFRESH' as const;

// 타입 정의
export interface DeliveryState {
  deliveryType: string;
  startDate: { year: number; month: number; day: number };
  endDate: { year: number; month: number; day: number };
  order: string;
  deliveryData: DeliveryScheduleResponseType[]; // API 응답 데이터
  isLoading: boolean;
  isRefresh: boolean;
}

// 액션 타입 정의
export type DeliveryAction =
  | { type: typeof SET_DELIVERY_TYPE; payload: string }
  | {
      type: typeof SET_START_DATE;
      payload: { year: number; month: number; day: number };
    }
  | {
      type: typeof SET_END_DATE;
      payload: { year: number; month: number; day: number };
    }
  | {
      type: typeof SET_IS_LOADING;
      payload: boolean;
    }
  | { type: typeof SET_ORDER; payload: string }
  | { type: typeof SET_IS_REFRESH; payload: boolean }
  | { type: typeof SET_DELIVERY_DATA; payload: DeliveryScheduleResponseType[] };

// 초기 상태
const initialState: DeliveryState = {
  deliveryType: '전체',
  startDate: { year: 2023, month: 12, day: 23 },
  endDate: { year: 2023, month: 12, day: 31 },
  order: '가까운 날짜순',
  deliveryData: [],
  isLoading: false,
  isRefresh: false,
};

// 액션 생성 함수
export const setDeliveryType = (payload: string) => ({
  type: SET_DELIVERY_TYPE,
  payload,
});

export const setStartDate = (payload: {
  year: number;
  month: number;
  day: number;
}) => ({
  type: SET_START_DATE,
  payload,
});

export const setEndDate = (payload: {
  year: number;
  month: number;
  day: number;
}) => ({
  type: SET_END_DATE,
  payload,
});

export const setOrder = (payload: string) => ({
  type: SET_ORDER,
  payload,
});

export const setDeliveryData = (payload: DeliveryScheduleResponseType[]) => ({
  type: SET_DELIVERY_DATA,
  payload,
});

export const setIsDeliveryLoding = (payload: boolean) => ({
  type: SET_IS_LOADING,
  payload,
});
export const setIsRefresh = (payload: boolean) => ({
  type: SET_IS_REFRESH,
  payload,
});

// 리듀서
const deliveryReducer = (
  state: DeliveryState = initialState,
  action: DeliveryAction
): DeliveryState => {
  switch (action.type) {
    case SET_DELIVERY_TYPE:
      return { ...state, deliveryType: action.payload };
    case SET_START_DATE:
      return { ...state, startDate: action.payload };
    case SET_END_DATE:
      return { ...state, endDate: action.payload };
    case SET_ORDER:
      return { ...state, order: action.payload };
    case SET_DELIVERY_DATA:
      return { ...state, deliveryData: action.payload };
    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_IS_REFRESH:
      return { ...state, isRefresh: action.payload };
    default:
      return state;
  }
};

export default deliveryReducer;
