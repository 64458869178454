import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import { TabletSidebarWrapper } from '../sidebar/TabletSidebarWrapper';
import { TabletHeader } from '../components/TabletHeader';
export const TabletPageLayout = ({
  bodyChildren,
  sidebarChildren,
}: {
  bodyChildren: JSX.Element;
  sidebarChildren: JSX.Element;
}) => {
  return (
    <Container>
      <BodyContainer>
        <TabletHeader />
        {bodyChildren}
      </BodyContainer>
      <TabletSidebarWrapper>{sidebarChildren}</TabletSidebarWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  * {
    box-sizing: border-box;
  }
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;
