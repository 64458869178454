import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import useFetch from '../../../api/useFetch';
import {
  LoadingLocationGetApi,
  LoadingLocationListResponseType,
  LoadingLocationPostApi,
  UpdateLoadingLocationPutApi,
} from '../../../api/apiConfig';
import { IoMdSearch } from 'react-icons/io';

const OutPlaceModal = ({
  id,
  handleClose,
}: {
  id: number;
  handleClose: () => void;
}) => {
  const [locationList, setLocationList] = useState<
    LoadingLocationListResponseType[]
  >([]);
  const [selectedLocation, setSelectedLocation] =
    useState<LoadingLocationListResponseType | null>(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [addText, setAddText] = useState('');
  const overlayRef = useRef<HTMLDivElement>(null);

  // 상차 장소 리스트 조회 API
  const { fetch: LoadingLocationListGet } = useFetch({
    fetchFunction: LoadingLocationGetApi,
    onSuccess: (data: LoadingLocationListResponseType[]) => {
      setLocationList(data);
    },
  });
  // 상차 장소 등록 API
  const { fetch: LoadingLocationPost } = useFetch({
    fetchFunction: LoadingLocationPostApi,
    onSuccess: (data: any) => {
      console.log('LoadingLocationPostApi : 성공', data);
      setAddText('');
      LoadingLocationListGet();
    },
  });
  // 상차 장소 업데이트 API
  const { fetch: LoadingLocationPut } = useFetch({
    fetchFunction: UpdateLoadingLocationPutApi,
    onSuccess: (data: any) => {
      console.log('LoadingLocationPut : 성공', data);
      handleClose();
    },
  });

  useEffect(() => {
    LoadingLocationListGet();
  }, []);

  useEffect(() => {
    // 외부 클릭 시 오버레이 닫기
    const handleClickOutside = (event: MouseEvent) => {
      if (
        overlayRef.current &&
        !overlayRef.current.contains(event.target as Node)
      ) {
        setShowOverlay(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  const handleAddInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddText(e.target.value);
  };

  const handleItemClick = (item: LoadingLocationListResponseType) => {
    setSelectedLocation(item);
    setSearchText(item.name); // 예를 들어 name 필드가 있다고 가정
    setShowOverlay(false);
  };

  const handleHeaderClick = () => {
    setShowOverlay(true);
  };

  // 검색어에 따라 필터링된 리스트
  const filteredList = locationList.filter((location) =>
    location.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Container>
      <Header>
        <Title>상차 장소 지정</Title>
        <SearchContainer onClick={handleHeaderClick}>
          <SearchInput
            value={searchText}
            onChange={handleInputChange}
            placeholder="상차장소 검색"
            onFocus={() => setShowOverlay(true)}
          />
          <SearchIcon>
            <IoMdSearch size={20} />
          </SearchIcon>
          {showOverlay && (
            <Overlay ref={overlayRef}>
              {filteredList.length > 0 ? (
                filteredList.map((location) => (
                  <ListItem
                    key={location.id}
                    onClick={() => handleItemClick(location)}
                  >
                    {location.name}
                  </ListItem>
                ))
              ) : (
                <NoResults>검색 결과가 없습니다.</NoResults>
              )}
            </Overlay>
          )}
        </SearchContainer>
        <Favorite>
          {selectedLocation && `선택된 장소: ${selectedLocation.name}`}
        </Favorite>
      </Header>

      <Content>
        <AddContent>
          <AddContentText>상차 장소 추가 :</AddContentText>
          <AddContentInput value={addText} onChange={handleAddInputChange} />
          <Button
            onClick={() => {
              if (addText) {
                // 추가하기 버튼 클릭 시 로직
                LoadingLocationPost({
                  name: addText,
                });
              }
            }}
          >
            추가하기
          </Button>
        </AddContent>
        <SubmitButton
          onClick={() => {
            if (selectedLocation) {
              // 추가하기 버튼 클릭 시 로직
              LoadingLocationPut({
                ordersItemId: id,
                loadingLocationId: selectedLocation.id,
              });
            }
          }}
        >
          저장
        </SubmitButton>
      </Content>
    </Container>
  );
};

export default OutPlaceModal;

const Container = styled.div`
  padding: 0 10px 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: space-between;
`;
const Title = styled.div`
  color: #333;
  font-size: calc(0.5rem + 1vw);
  font-weight: 600;
  margin-bottom: 10px;
`;

const Header = styled.div``;

const SearchContainer = styled.div`
  border: 1px solid ${({ theme }) => `${theme.colors.charcoal}`};

  border-radius: 7px;
  overflow: visible;
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;
  cursor: text;
`;

const SearchInput = styled.input`
  border: 0;
  flex: 1;
  display: flex;
  width: 100%;
  padding: 10px;
  color: #333;
  font-size: calc(0.2rem + 1vw);
  font-weight: 600;
  outline: none;
`;

const SearchIcon = styled.div`
  display: flex;
  align-items: center;
`;

const Overlay = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => `${theme.colors.charcoal}`};
  background: white;
  z-index: 10;
`;

const ListItem = styled.div`
  padding: 10px;
  padding-left: 20px;
  color: #333;
  font-size: calc(0.2rem + 1vw);
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => `${theme.colors.lightGray}`};
  }
`;

const NoResults = styled.div`
  padding: 10px;
  color: #999;
`;

const Content = styled.div`
  margin-top: 20px;
`;

const Favorite = styled.div`
  margin-top: 10px;
  color: #717171;
  font-size: calc(0.2rem + 1vw);
  font-weight: 600;
`;

const AddContent = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`;

const AddContentText = styled.div`
  margin-right: 10px;
  color: #717171;
  font-size: calc(0.1rem + 1vw);
  font-weight: 600;
`;

const AddContentInput = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid ${({ theme }) => `${theme.colors.charcoal}`};
  border-radius: 4px;
  margin-right: 10px;

  color: #717171;
  font-size: calc(0.1rem + 1vw);
  font-weight: 600;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: ${({ theme }) => `${theme.colors.blue}`};
  color: white;
  border: none;
  font-size: calc(0.1rem + 1vw);
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
`;

const SubmitButton = styled.button`
  padding: 8px;
  width: 100%;
  font-size: calc(0.1rem + 1vw);
  font-weight: 500;
  margin-top: 20px;
  background-color: ${({ theme }) => `${theme.colors.blue}`};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;
