import styled, { css } from 'styled-components';

export const TableOptionButton = styled.div`
  position: absolute;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; */
  height: 20px;
  top: 0;
  /* font-size: ${({ theme }) => theme.fontsize.table_body}; */
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;
`;

export const HeaderCellWrapper = styled.div<{
  isFirstItem?: boolean;
  isLastItem?: boolean;
}>`
  display: flex;
  height: 100%;
  width: 100%;
  min-width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`;

export const HeaderCellResizer = styled.div`
  width: 5px;
  border-radius: 5px;
  min-height: 26px;
  max-height: 26px;
  background-color: ${({ theme }) => theme.colors.softGray};
  cursor: col-resize;
`;

export const HeaderCell = styled.div<{ overflowType: 'wrap' | 'block' }>`
  height: auto;
  width: 100%;
  min-width: 0;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.charcoal};
  font-size: ${({ theme }) => theme.fontsize.table_header};
  font-weight: 900;
  /* align-items: center;
  justify-content: center;
  display: flex; */
  padding: 5px 0px;

  /* 줄넘김 및 오버플로우 처리 */
  ${({ overflowType }) =>
    overflowType === 'wrap'
      ? `
      word-wrap: break-word; /* 단어 단위로 줄 넘김 */
      word-break: break-word; /* 긴 단어가 있을 때 줄 넘김 */
      white-space: normal; /* 텍스트 줄넘김 허용 */
    `
      : `
      overflow: hidden; /* 넘치는 부분 숨김 */
      text-overflow: ellipsis; /* 가려지는 부분 ... 처리 */
      white-space: nowrap; /* 줄 넘김 금지 */
    `}
`;

export const BodyCell = styled(HeaderCell)<{
  width: number;
  isFirstItem?: boolean;
  isLastItem?: boolean;
}>`
  font-size: ${({ theme }) => theme.fontsize[16]};
  width: ${({ width }) => `${width}px`};
  color: ${({ theme }) => theme.colors.charcoal};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-left-width: ${({ isFirstItem }) => (isFirstItem ? 0 : undefined)};
  border-right-width: ${({ isLastItem }) => (isLastItem ? 0 : undefined)};
  font-weight: 500;
`;

export const Table = styled.table<{ width: number }>`
  width: ${({ width }) => `${width}px`};
  border-collapse: collapse;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  th,
  td {
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
  }
`;
export const THead = styled.thead``;
export const TR = styled.tr``;
export const TH = styled.th`
  min-height: 100%;
`;

export const TBody = styled.tbody``;
export const TD = styled.td<{
  width: number;
  isFirstItem?: boolean;
  isLastItem?: boolean;
  overflowType: 'wrap' | 'hide';
}>`
  font-size: ${({ theme }) => theme.fontsize[16]};
  width: ${({ width }) => `${width}px`};
  min-width: 0;
  color: ${({ theme }) => theme.colors.charcoal};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-left-width: ${({ isFirstItem }) => (isFirstItem ? 0 : undefined)};
  border-right-width: ${({ isLastItem }) => (isLastItem ? 0 : undefined)};
  font-weight: 500;
  align-items: center;
  justify-content: center;
  padding: 5px 0px;

  /* 줄넘김 및 오버플로우 처리 */
  ${({ overflowType }) =>
    overflowType === 'wrap'
      ? `
      word-wrap: break-word; /* 단어 단위로 줄 넘김 */
      word-break: break-word; /* 긴 단어가 있을 때 줄 넘김 */
      white-space: normal; /* 텍스트 줄넘김 허용 */
    `
      : `
      overflow: hidden; /* 넘치는 부분 숨김 */
      text-overflow: ellipsis; /* 가려지는 부분 ... 처리 */
      white-space: nowrap; /* 줄 넘김 금지 */
    `}
`;

//헤더 글자길이가 칸을 넘어갈 시   줄넘김v   숨김v
//바디 글자길이가 칸을 넘어갈 시   줄넘김v   숨김v
//바디 셀 가로정렬         좌측  중앙  우측
//바디 셀 좌우여백값       up/down버튼

export const TableOptionContainer = styled.div<{ show: boolean }>`
  width: 800px;
  overflow: hidden;
  height: ${({ show }) => (show ? '140px' : '0px')};
  margin-bottom: ${({ show }) => (show ? '20px' : '0px')};
  transition: height 0.5s ease, margin-bottom 0.5s ease;
`;

export const TableOptionContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 140px;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  div {
    font-size: ${({ theme }) => theme.fontsize.header_semi};
    color: ${({ theme }) => theme.colors.charcoal};
    font-weight: 600;
  }
`;

export const TableConfigRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 13px;
  font-weight: 500;
  align-items: center;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

//신규 테스트

export const TableContainer = styled.div`
  position: relative;
  padding-top: 20px;
  flex: 1;
`;

export const TableDiv = styled.div<{
  headOverflowType: 'wrap' | 'hide';
  bodyOverflowType: 'wrap' | 'hide';
  bodyAlignHoriType: 'left' | 'center' | 'right';
  cellHoriPaddValue: number;
  bodyVertPaddValue: number;
}>`
  background-color: ${({ theme }) => theme.colors.white};

  .th,
  .td {
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
  }

  .th {
    padding: 4px 0px;
    color: ${({ theme }) => theme.colors.charcoal};
    font-size: ${({ theme }) => theme.fontsize.table_header};
    font-weight: 900;
  }

  .td {
    padding: 2px 0px;
    color: ${({ theme }) => theme.colors.charcoal};
    font-size: ${({ theme }) => theme.fontsize[16]};
    font-weight: 500;
  }

  .td {
    ${({ bodyAlignHoriType }) =>
      bodyAlignHoriType === 'center'
        ? `
        justify-content: center;
      `
        : bodyAlignHoriType === 'right'
        ? `
        justify-content: right;
      `
        : `
        justify-content: left;
      `}
  }

  .th {
    padding: ${({ cellHoriPaddValue }) => `2px ${cellHoriPaddValue}px`};
  }

  .td {
    padding: ${({ cellHoriPaddValue, bodyVertPaddValue }) =>
      `${bodyVertPaddValue}px ${cellHoriPaddValue}px`};
  }

  /* headOverflowType에 따른 .th 스타일링 */
  .th {
    ${({ headOverflowType }) =>
      headOverflowType === 'wrap'
        ? `
        word-wrap: break-word; /* 단어 단위로 줄 넘김 */
        word-break: break-word; /* 긴 단어가 있을 때 줄 넘김 */
        white-space: normal; /* 텍스트 줄넘김 허용 */
      `
        : `
        overflow: hidden; /* 넘치는 부분 숨김 */
        text-overflow: ellipsis; /* 가려지는 부분 ... 처리 */
        white-space: nowrap; /* 줄 넘김 금지 */
      `}
  }

  .th .resizer {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  .th:hover {
    background-color: ${({ theme }) => theme.colors.lightGray};
    border-color: ${({ theme }) => theme.colors.whiteGray};
  }

  .th:hover .resizer {
    opacity: 1;
  }

  /* bodyOverflowType에 따른 .td 스타일링 */
  .td {
    ${({ bodyOverflowType }) =>
      bodyOverflowType === 'wrap'
        ? `
        word-wrap: break-word; /* 단어 단위로 줄 넘김 */
        word-break: break-word; /* 긴 단어가 있을 때 줄 넘김 */
        white-space: normal; /* 텍스트 줄넘김 허용 */
      `
        : `
        overflow: hidden; /* 넘치는 부분 숨김 */
        text-overflow: ellipsis; /* 가려지는 부분 ... 처리 */
        white-space: nowrap; /* 줄 넘김 금지 */
      `}
  }
`;

export const ResizeHandler = styled.div`
  width: 3px;
  min-height: 80%;
  position: absolute;
  right: 0px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.brightNavy};
  cursor: col-resize;
`;

export const ThDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
`;

export const TdDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  .hover-item {
    &:hover {
      background-color: ${({ theme }) => theme.colors.white_odd_hover};
    }
  }
  .odd {
    background-color: ${({ theme }) => theme.colors.white_odd};
  }
`;
export const ThCell = styled.div`
  display: flex;
  min-height: 100%;

  position: relative;
  align-items: center;
  justify-content: center;
`;

export const TdCell = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  /* justify-content: center; */
`;

export const NoBodyCell = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 3px;
  align-items: center;
`;

export const NoText = styled.div`
  font-size: ${({ theme }) => theme.fontsize.header_pri};
  font-weight: 500;
`;

export const PaginationButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

export const PaginationButtonBox = styled.div``;

export const PaginationButton = styled.button<{
  size: number;
  disabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.charcoal};
  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
    border-radius: ${size / 8}px;
  `}
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')}
`;
