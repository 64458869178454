import { createGlobalStyle } from 'styled-components';

//props에서 theme.ts로 바로 접근가능(ThemeProvider 내에 위치해있기 때문에)
// color: ${(props) =>
//   props.theme.colors.text};
const GlobalStyles = createGlobalStyle`
    body {
    /* font-family: Paperlogy; */
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    
    background-color: ${(props) =>
      props.theme.colors.white}; /* 테마에서 배경색 사용 */
    margin: 0;
    padding: 0;
    transition: background-color 0.3s ease;

    //스크롤바 스타일 
   *::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 6px;
    background-color: #ededed;
  }
  *::-webkit-scrollbar-thumb {
      background: #4b4b4b;
      border-radius: 6px;
    }

    .box-shadow {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }

    /* 기본 화살표 제거 */
input[type="number"] {
  -webkit-appearance: none;  /* Safari 및 Chrome에서 화살표 버튼 제거 */
  -moz-appearance: textfield; /* Firefox에서 화살표 버튼 제거 */
  appearance: none;           /* 모든 브라우저에서 기본 스타일 제거 */
}

/* Firefox에서 input의 기본 스타일을 추가로 제거하기 */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

  }

  * {
  letter-spacing: 0.02em;
  /* line-height: 1.3em; */

  text-rendering: optimizeLegibility; /* 가독성을 최적화 */
  -webkit-font-smoothing: antialiased; /* 글자 경계 부드럽게 */
  -moz-osx-font-smoothing: grayscale; /* Firefox에서 부드럽게 */
  }

  /* 커스텀 클래스 정의 */
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .flex-align-center {
    display: flex;
    align-items: center;
  }

  .text-large {
    font-size: 1.5em;
  }

  .text-small {
    font-size: 0.875em;
  }

  .box-shadow {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
`;

export default GlobalStyles;
