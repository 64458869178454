import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/modules';
import { useEffect, useState } from 'react';
import { setTabletNavSidebarWidth } from '../../../store/modules/tabletNavSidebar';
import styled from 'styled-components';
import useFetch from '../../../api/useFetch';
import { workbayListGetApi, WorkbayType } from '../../../api/apiConfig';
import {
  clearSelectedWorkbayTablet,
  setSelectedWorkbayTablet,
} from '../../../store/modules/tabletOption';

export const TabletWorkbaySidebar = () => {
  const [workbayList, setWorkbayList] = useState<WorkbayType[]>([]);
  const { fetch: fetchWorkbayListGetApi } = useFetch({
    fetchFunction: workbayListGetApi,
    onSuccess: (data: WorkbayType[]) => setWorkbayList(data),
  });

  const tabletSidebarWidth = useSelector(
    (state: RootState) => state.tabletNavSidebar.width
  );

  const selectedWorkbayTablet = useSelector(
    (state: RootState) => state.tabletOption.selectedWorkbay
  );

  const dispatch = useDispatch();

  useEffect(() => {
    fetchWorkbayListGetApi();
    // 컴포넌트 마운트 시
    dispatch(setTabletNavSidebarWidth(300));

    // 언마운트
    return () => {};
  }, []);

  // `workbayList` 업데이트 시 `selectedWorkbayTablet`과 동기화
  useEffect(() => {
    if (
      workbayList.length > 0 &&
      selectedWorkbayTablet &&
      !workbayList.some((workbay) => workbay.id === selectedWorkbayTablet.id)
    ) {
      // 현재 선택된 작업장이 리스트에 없을 경우 초기화
      dispatch(clearSelectedWorkbayTablet());
    }
  }, [workbayList, selectedWorkbayTablet, dispatch]);
  return (
    <Container $width={tabletSidebarWidth ?? 0}>
      {workbayList.length > 0 &&
        workbayList
          // 기본작업장(엠마우스산업이라 명명된) 제외
          .filter((workbay) => workbay.workbayCode !== '100')
          .map((workbay) => (
            <ListItem
              key={workbay.id}
              className="sidebar-listitem-text"
              $selected={
                selectedWorkbayTablet
                  ? selectedWorkbayTablet.id === workbay.id
                  : false
              }
              onClick={() => {
                selectedWorkbayTablet?.id === workbay.id
                  ? dispatch(clearSelectedWorkbayTablet())
                  : dispatch(setSelectedWorkbayTablet(workbay));
              }}
            >
              {workbay.workbayName}
            </ListItem>
          ))}
    </Container>
  );
};

const Container = styled.div<{ $width: number }>`
  width: ${({ $width }) => `${$width}px`};
`;

const ListItem = styled.text<{ $selected: boolean }>`
  display: flex;
  width: 100%;
  background-color: ${({ $selected, theme }) =>
    $selected ? '#5a5a5a' : 'transparent'};
  color: white;
  height: 45px;
  align-items: center;
  justify-content: center;
`;
