import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import useFetch from '../../../api/useFetch';
import {
  bomListGetApi,
  itemListGetApi,
  ProductItem,
  productItemListGetApi,
  StockHistoryResponseType,
  unsyncedStockHistoryPostApi,
  UnsyncedStockHistoryResponseType,
  workbayListGetApi,
  WorkbayType,
} from '../../../api/apiConfig';
import { IoSearch } from 'react-icons/io5';
import { RootState } from '../../../store/modules';
import { useSelector } from 'react-redux';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { da } from '@faker-js/faker/.';

export const ProductionBom = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const selectedProdType = useSelector(
    (state: RootState) => state.tabletOption.selectedProdType
  );

  const [itemList, setItemList] = useState<ProductItem[]>([]);

  const [workbayList, setWorkbayList] = useState<WorkbayType[]>([]);

  const [selectedProdItem, setSelectedProdItem] = useState<ProductItem | null>(
    null
  );

  const [selectedWorkbayItem, setSelectedWorkbayItem] =
    useState<WorkbayType | null>(null);

  const [filteredItemList, setFilteredItemList] = useState<ProductItem[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const { fetch: fetchItemListGetApi } = useFetch({
    fetchFunction: itemListGetApi,
    onSuccess: (data: ProductItem[]) => setItemList(data),
  });

  const { fetch: fetchWorkbayListGetApi } = useFetch({
    fetchFunction: workbayListGetApi,
    onSuccess: (data: WorkbayType[]) => setWorkbayList(data),
  });

  const { fetch: fetchUnsyncedStockHistoryPostApi } = useFetch({
    fetchFunction: unsyncedStockHistoryPostApi,
    onSuccess: (data: UnsyncedStockHistoryResponseType) => {
      navigateToProgressPage(data.workbayId, data.itemId);
    },
  });

  useEffect(() => {
    fetchItemListGetApi();
    fetchWorkbayListGetApi();
  }, []);

  useEffect(() => {
    if (itemList === null) return;
    filterItemList();
  }, [itemList, selectedProdType]);

  function filterItemList() {
    const prodTypeFiltered = applyProdType();
    if (searchTerm.trim() !== '') {
      setFilteredItemList(
        prodTypeFiltered.filter((item) => item.PROD_DES.includes(searchTerm))
      );
    } else if (searchTerm.trim() === '') {
      setFilteredItemList(prodTypeFiltered);
    }
  }

  function applyProdType() {
    let filteredList: ProductItem[] = [];
    if (selectedProdType === -1) {
      filteredList = itemList;
    } else {
      filteredList = itemList.filter(
        (item) => Number(item.PROD_TYPE) === selectedProdType
      );
    }

    return filteredList;
  }

  function post() {
    if (selectedProdItem === null || selectedWorkbayItem === null) return;

    fetchUnsyncedStockHistoryPostApi({
      workbayId: selectedWorkbayItem.id,
      itemId: selectedProdItem.id,
    });
  }

  function navigateToProgressPage(workbayId: number, itemId: number) {
    navigate(`/production-bom/${workbayId}/${itemId}`);
  }

  return (
    <Container>
      <HalfContainer>
        <Content>
          <h2 style={{ color: theme.colors.charcoal }} className='title'>
            - 품목을 선택해주세요
          </h2>
          <SearchBar>
            <div className='label-icon'>
              {searchTerm.trim() === '' ? (
                <IoSearch color={theme.colors.charcoal} size={28} />
              ) : (
                <IoIosCloseCircleOutline
                  color={theme.colors.charcoal}
                  size={34}
                  strokeWidth={5}
                  onClick={() => {
                    setSearchTerm('');
                    setFilteredItemList(applyProdType());
                  }}
                />
              )}
            </div>
            <SearchInput
              className='input-section'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  filterItemList();
                }
              }}
            />
            <h4 className='button-section' onClick={() => filterItemList()}>
              검색하기
            </h4>
          </SearchBar>
          <ListContainer>
            {filteredItemList.length > 0 ? (
              filteredItemList.map((item, index) => {
                return (
                  <>
                    <ListItem
                      $selected={selectedProdItem === item}
                      onClick={() => setSelectedProdItem(item)}
                    >
                      {item.PROD_DES}
                    </ListItem>
                    {index + 1 < filteredItemList.length && <DivLine />}
                  </>
                );
              })
            ) : (
              <h3 style={{ color: theme.colors.charcoal }}>
                품목정보가 없습니다
              </h3>
            )}
          </ListContainer>
        </Content>
        <Content>
          <h2 style={{ color: theme.colors.charcoal }} className='title'>
            - 작업장을 선택해주세요
          </h2>
          <SearchBar style={{ border: 'none' }} />
          <ListContainer>
            {workbayList.length > 0 ? (
              workbayList
                .filter((workbay) => workbay.workbayCode !== '100')
                .map((workbay, index) => {
                  return (
                    <>
                      <ListItem
                        $selected={selectedWorkbayItem === workbay}
                        onClick={() => setSelectedWorkbayItem(workbay)}
                      >
                        {workbay.workbayName}
                      </ListItem>
                      {index + 1 < workbayList.length && <DivLine />}
                    </>
                  );
                })
            ) : (
              <h3 style={{ color: theme.colors.charcoal }}>
                작업장 정보가 없습니다
              </h3>
            )}
          </ListContainer>
        </Content>
      </HalfContainer>
      <CountingButton
        $clickable={selectedProdItem !== null && selectedWorkbayItem !== null}
        onClick={() => post()}
      >
        선택완료
      </CountingButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Content = styled.div`
  flex-basis: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 20px;
`;

const HalfContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const SearchBar = styled.div`
  width: 100%;
  border: 3px solid ${({ theme }) => theme.colors.charcoal};
  height: 52px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .label-icon {
    flex: 0 0 12%;
    text-align: center;
    padding-top: 3px;
  }
  .input-section {
    flex: 1 0 50%;
    height: 80%;
  }
  .button-section {
    flex: 0 0 22%;
    text-align: center;
    align-content: center;
    height: 100%;
    margin: 0;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`;

const SearchInput = styled.input`
  border: none;
  padding: 0px 10px;
  font-size: 19px;
`;

const ListContainer = styled.div`
  height: 503px;
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
`;

const ListItem = styled.h3<{ $selected: boolean }>`
  color: ${({ theme }) => theme.colors.charcoal};
  align-self: center;
  width: 100%;
  margin: 0px;
  padding: 8px 15px;
  background-color: ${({ theme, $selected }) =>
    $selected ? theme.colors.whiteGray : 'transparent'};
`;

const DivLine = styled.div`
  width: 98%;
  align-self: center;
  min-height: 1px;
  background-color: ${({ theme }) => theme.colors.charcoal};
`;

const CountingButton = styled.div<{ $clickable: boolean }>`
  background-color: ${({ theme, $clickable }) =>
    $clickable ? theme.colors.blue : '#8ECCF9'};
  color: ${({ theme }) => theme.colors.white};
  height: 60px;
  width: 95%;
  align-self: center;
  margin-bottom: 15px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 28px;
  font-weight: 500;
`;
