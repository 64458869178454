import React from 'react';
import styled from 'styled-components';

type DateType = {
  year: number;
  month: number;
  day: number;
};

interface Props {
  date: DateType;
  onChangeDate: (updated: DateType) => void;
}

const DateDropdown: React.FC<Props> = ({ date, onChangeDate }) => {
  // 현재 연도 구하기
  const currentYear = new Date().getFullYear();

  const handleChangeYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChangeDate({ ...date, year: Number(e.target.value) });
  };

  const handleChangeMonth = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChangeDate({ ...date, month: Number(e.target.value) });
  };

  const handleChangeDay = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChangeDate({ ...date, day: Number(e.target.value) });
  };

  // 예: 현재 연도부터 10년 전까지
  const years = Array.from({ length: 11 }, (_, i) => currentYear - i);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const days = Array.from({ length: 31 }, (_, i) => i + 1);

  return (
    <Container>
      <Select value={date.year} onChange={handleChangeYear}>
        {years.map((y) => (
          <Option key={y} value={y}>
            {y}
          </Option>
        ))}
      </Select>
      <Separator>/</Separator>
      <Select value={date.month} onChange={handleChangeMonth}>
        {months.map((m) => (
          <Option key={m} value={m}>
            {m}
          </Option>
        ))}
      </Select>
      <Separator>/</Separator>
      <Select value={date.day} onChange={handleChangeDay}>
        {days.map((d) => (
          <Option key={d} value={d}>
            {d}
          </Option>
        ))}
      </Select>
    </Container>
  );
};

export default DateDropdown;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 8px 0;
`;

const Select = styled.select`
  flex: 1;
  padding: 4px;
  background-color: #fff;
  border-radius: 4px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  justify-content: center;
  text-align: center;
  color: #000;
  font-size: calc(0.2rem + 1vw);
  font-weight: 600;
`;
const Option = styled.option`
  text-align: center;
  color: #000;
  font-size: calc(0.2rem + 1vw);
  font-weight: 600;
`;

const Separator = styled.div`
  color: #fff;
  font-size: calc(0.5rem + 1vw);
  font-weight: 600;
`;
