import styled, { css } from 'styled-components';
import { CustomCircularProgressbar } from './CustomCircularProgressbar';
import { CustomProgressBar } from './CustomProgressBar';
import { progressMapper } from '../../@const/productionProgress';
export type ProductionCardProps = {
  current: number;
  goal: number;
  workbayName: string;
  itemName: string;
  itemSize: string;
  progress: string;
  hover?: boolean;
  onClick: () => void;
};
export const ProductionCircularCard = ({
  current,
  goal,
  workbayName,
  itemName,
  itemSize,
  progress,
  hover = false,
  onClick,
}: ProductionCardProps) => {
  const active = progress === 'P';
  return (
    <Container
      hover={hover}
      active={active}
      onClick={() => active && onClick()}
    >
      <Header>
        <div className='medium-text'>{itemName}</div>
        <div className='small-text'>[{itemSize}]</div>
      </Header>
      <ProgressHolder>
        <CustomCircularProgressbar
          current={current}
          goal={goal}
          style={{ width: '120px' }}
        />
        {/* <CustomProgressBar
          current={current}
          goal={goal}
          style={{ width: '180px' }}
        /> */}
      </ProgressHolder>
      <CountSection>
        <CountRow>
          <div className='medium-text'>목표수량:</div>
          <div className='large-text'>{goal}</div>
          <div className='medium-text'>개</div>
        </CountRow>
        <CountRow>
          <div className='medium-text'>달성수량:</div>

          <div className='large-text'>{current}</div>
          <div className='medium-text'>개</div>
        </CountRow>
      </CountSection>
      <div className='medium-text' style={{ alignSelf: 'center' }}>
        {progressMapper(progress)}
      </div>
    </Container>
  );
};

const Container = styled.div<{ hover: boolean; active: boolean }>`
  width: 220px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 8px;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.brightGray};
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.colors.charcoal};
  .small-text {
    font-size: ${({ theme }) => theme.fontsize[13]};
    font-weight: 500;
  }
  .medium-text {
    font-size: ${({ theme }) => theme.fontsize[16]};
    font-weight: 900;
    color: ${({ theme }) => theme.colors.black};
  }
  .large-text {
    font-size: ${({ theme }) => theme.fontsize[18]};
    font-weight: 900;
  }
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.lightGray};
        cursor: pointer;
      }
    `}
`;

const Header = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.charcoal};
  /* overflow-y: scroll; */
`;

const ProgressHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
`;

const CountSection = styled(Header)`
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px 14px;
  width: 100%;
  border: none;
  height: 74px;
`;

const CountRow = styled.div`
  align-self: center;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8%;
  * {
    word-break: keep-all;
  }
`;
