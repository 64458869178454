import { useEffect, useState } from 'react';
import {
  ParcelOriginType,
  ParcelUploadModal,
} from '../../../component/common/modal/ModalComponents/ParcelUploadModal';
import styled from 'styled-components';
import { useHeaderButtons } from '../../../component/header/HeaderButtonProvider';
import SquareButton from '../../../component/button/SquareButton';
import useFetch from '../../../api/useFetch';
import {
  parcelItemListAllGetApi,
  parcelItemListGetApi,
  ParcelItemResponseType,
  parcelTraderListAllGetApi,
  parcelTraderListGetApi,
  ParcelTraderResponseType,
} from '../../../api/apiConfig';
import { createExcelFileWithColumnLetters } from '../../../function/createExcel';
import { FiTrash2 } from 'react-icons/fi';
import { Dropdown } from 'react-bootstrap';
import {
  fetchPostCodeFromKakaoAPI,
  openAddressSearch,
} from '../../../function/addressApi';

export type ParcelConvertedType = {
  orderId: string;
  parcels: ParcelOriginType[];
};

export const ParcelPage = () => {
  const { setButtons } = useHeaderButtons();

  //드롭다운용 숫자배열
  const options = Array.from({ length: 99 }, (_, index) => index + 1);

  const [addressManualIndex, setAddressManualIndex] = useState<number[]>([]);

  const [parcelItems, setParcelItems] = useState<ParcelItemResponseType[]>([]);
  const [parcelTraders, setParcelTraders] = useState<
    ParcelTraderResponseType[]
  >([]);

  const { fetch: fetchParcelItemListAllGetApi } = useFetch({
    fetchFunction: parcelItemListAllGetApi,
    onSuccess: (data: ParcelItemResponseType[]) => setParcelItems(data),
  });
  const { fetch: fetchParcelTraderListAllGetApi } = useFetch({
    fetchFunction: parcelTraderListAllGetApi,
    onSuccess: (data: ParcelTraderResponseType[]) => setParcelTraders(data),
  });

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [originDatas, setOriginDatas] = useState<ParcelOriginType[]>([]);
  const [convertedDatas, setConvertedDatas] = useState<ParcelOriginType[]>([]);

  // 데이터 변환
  function convertParcelData(parcels: ParcelOriginType[]) {
    const converted = parcels.map((parcel) => {
      const parcelItem =
        parcelItems.find((parcelItem) => parcelItem.prodCd === parcel.prodCd) ||
        null;
      const parcelTrader = parcelTraders.find(
        (parcelTrader) => parcelTrader.custCd === parcel.custCd || null
      );
      return {
        ...parcel,
        custName: parcelTrader?.label || parcel.custName,
        prodDes: parcelItem?.label || parcel.prodDes,
        sizeDes: parcelItem?.sizeLabel || parcel.sizeDes,
      };
    });
    return converted;
  }

  // 그룹화 로직
  //   const groupParcelsByOrderId = (parcels: ParcelOriginType[]) => {
  //     const groupedMap = parcels.reduce((acc, parcel) => {
  //       const { orderId } = parcel;

  //       // 기존 그룹이 있으면 추가, 없으면 새로 생성
  //       if (!acc[orderId]) {
  //         acc[orderId] = [];
  //       }
  //       acc[orderId].push(parcel);

  //       return acc;
  //     }, {} as Record<string, ParcelOriginType[]>);

  //     // Record<string, ParcelOriginType[]>를 ParcelConvertedType 형식으로 변환
  //     const groupedArray: ParcelConvertedType[] = Object.entries(groupedMap).map(
  //       ([orderId, parcels]) => ({
  //         orderId,
  //         parcels,
  //       })
  //     );

  //     setConvertedDatas(groupedArray); // 상태 업데이트
  //   };

  const reOrderSerNo = (parcels: ParcelOriginType[]) => {
    let orderIdCounter = 1; // 새로운 orderId는 1부터 시작
    const updatedParcels: ParcelOriginType[] = [];

    parcels.forEach((parcel) => {
      const { prodCd, qty } = parcel;

      // parcelItem 찾아오기
      const parcelItem = parcelItems.find((item) => item.prodCd === prodCd);

      if (!parcelItem) {
        // parcelItem이 없으면 값 그대로 추가, orderId를 순차적으로 부여
        updatedParcels.push({
          ...parcel,
          orderId: `${orderIdCounter++}`, // 새로운 orderId 부여
        });
        return;
      }

      const { packageMaxQty } = parcelItem;

      // packageMaxQty가 null이 아니고 qty가 packageMaxQty보다 큰 경우
      if (packageMaxQty !== null && qty > packageMaxQty) {
        const maxQty = packageMaxQty;
        const numOfParcels = Math.ceil(qty / maxQty); // parcel의 qty를 maxQty로 나누어서 필요한 parcel 수 계산

        // 분할된 parcel들 추가
        for (let i = 0; i < numOfParcels; i++) {
          const newQty =
            i === numOfParcels - 1 ? qty - maxQty * (numOfParcels - 1) : maxQty;
          updatedParcels.push({
            ...parcel,
            orderId: `${orderIdCounter++}`, // 새로운 orderId 부여
            qty: newQty,
          });
        }
      } else {
        // packageMaxQty가 null이거나 qty가 packageMaxQty보다 작으면 기존 값 그대로 추가
        updatedParcels.push({
          ...parcel,
          orderId: `${orderIdCounter++}`, // 새로운 orderId 부여
        });
      }
    });

    setConvertedDatas(updatedParcels); // 상태 업데이트
    // return updatedParcels;
  };

  useEffect(() => {
    if (originDatas.length < 1) return;
    reOrderSerNo(convertParcelData(originDatas));
  }, [originDatas]);

  function excelDownloadHandler() {
    // 열 알파벳과 데이터 키 매핑
    const columns = [
      {
        header: '배송번호',
        key: 'orderId',
        columnLetter: 'A',
      },
      {
        header: '배송지주소',
        key: 'address',
        columnLetter: 'B',
      },
      {
        header: '배송지주소 상세',
        key: 'addressDetail',
        columnLetter: 'C',
      },
      {
        header: '받는분',
        key: 'custName',
        columnLetter: 'D',
      },
      {
        header: '담당자',
        key: 'empName',
        columnLetter: 'E',
      },
      {
        header: '우편번호',
        key: 'postCd',
        columnLetter: 'F',
      },
      {
        header: '제품명',
        key: 'prodDes',
        columnLetter: 'G',
      },
      {
        header: '규격명',
        key: 'sizeDes',
        columnLetter: 'H',
      },
      {
        header: '수량',
        key: 'qty',
        columnLetter: 'I',
      },
      {
        header: '수령인 정보',
        key: 'receiverInfo',
        columnLetter: 'J',
      },
      {
        header: '수령인 연락처',
        key: 'receiverTelNo',
        columnLetter: 'K',
      },
    ];
    createExcelFileWithColumnLetters(
      convertedDatas,
      columns,
      'excel-convert-test'
    );
  }

  useEffect(() => {
    setButtons(
      <>
        <SquareButton
          text='엑셀 업로드'
          colorType='ACTIVE'
          onClick={() => setOpenModal(!openModal)}
        />
        <SquareButton
          text='엑셀 다운로드'
          colorType={convertedDatas.length > 0 ? 'ACTIVE' : 'DISACTIVE'}
          onClick={() => convertedDatas.length > 0 && excelDownloadHandler()}
        />
        <SquareButton
          text='우편번호 자동검색'
          colorType={'ACTIVE'}
          onClick={() => postCdSearchAllHandler()}
        />
      </>
    );
    // 페이지가 바뀌면 버튼 초기화
    return () => setButtons(null);
  }, [setButtons, openModal, convertedDatas]);

  useEffect(() => {
    fetchParcelItemListAllGetApi();
    fetchParcelTraderListAllGetApi();
  }, []);

  const handleOrderIdChange = (newOrderId: string, index: number) => {
    setConvertedDatas((prev) =>
      prev.map((item, itemIndex) =>
        itemIndex === index ? { ...item, orderId: newOrderId } : item
      )
    );
  };

  function postCdSearchAllHandler() {
    convertedDatas.forEach((data, targetIndex) => {
      const address = convertedDatas[targetIndex].address || null;
      if (address === null) return;
      fetchPostCodeFromKakaoAPI([address])
        .then((resultDatas) => {
          resultDatas.forEach((result) =>
            setConvertedDatas((prev) =>
              prev.map((item, prevIndex) => {
                if (prevIndex === targetIndex) {
                  setAddressManualIndex((prev) =>
                    prev.filter((index) => index !== targetIndex)
                  );
                  return {
                    ...item,
                    address: result.address,
                    postCd: result.zoneCode,
                  };
                } else {
                  return item;
                }
              })
            )
          );
        })

        .catch((error) => {
          setAddressManualIndex((prev) =>
            prev.some((data) => data === targetIndex)
              ? prev
              : [...prev, targetIndex]
          );
        });
    });
  }

  function postCdSearchHandler(targetIndex: number) {
    const address = convertedDatas[targetIndex].address || null;
    openAddressSearch((address, postCode) => {
      setConvertedDatas((prev) =>
        prev.map((item, index) =>
          index === targetIndex
            ? { ...item, address: address, postCd: postCode }
            : item
        )
      );
      setAddressManualIndex((prev) =>
        prev.filter((item) => item !== targetIndex)
      );
    });
  }

  return (
    <Container>
      {openModal && (
        <ParcelUploadModal
          onClose={() => setOpenModal(false)}
          onSelect={(data: ParcelOriginType[]) => setOriginDatas(data)}
        />
      )}
      {convertedDatas.length > 0 && (
        <table>
          <StyledThead>
            <td></td>
            <td>*번호</td>
            <td>거래처</td>
            <td>*받는분</td>
            <td>*받는분 연락처</td>
            <td>품목명</td>
            <td>규격명</td>
            <td>수량</td>
            <td>*주소</td>
            <td>주소상세</td>
            <td>*우편번호</td>
          </StyledThead>
          <tbody>
            {convertedDatas.map((data, index) => (
              <tr key={index}>
                <td>
                  <FiTrash2
                    color={'red'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setConvertedDatas((prev) =>
                        prev.filter((_, prevIndex) => prevIndex !== index)
                      );
                      setAddressManualIndex((prev) =>
                        prev.filter((_, prevIndex) => prevIndex !== index)
                      );
                    }}
                  />
                </td>
                <td>
                  <select
                    value={data.orderId || undefined}
                    onChange={(e) => handleOrderIdChange(e.target.value, index)}
                  >
                    {options.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    value={data.custName || undefined}
                    onChange={(e) =>
                      setConvertedDatas((prev) =>
                        prev.map((item, itemIndex) =>
                          itemIndex === index
                            ? { ...item, custName: e.target.value }
                            : item
                        )
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    value={data.receiverInfo || undefined}
                    onChange={(e) =>
                      setConvertedDatas((prev) =>
                        prev.map((item, itemIndex) =>
                          itemIndex === index
                            ? { ...item, receiverInfo: e.target.value }
                            : item
                        )
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    value={data.receiverTelNo || undefined}
                    onChange={(e) =>
                      setConvertedDatas((prev) =>
                        prev.map((item, itemIndex) =>
                          itemIndex === index
                            ? { ...item, receiverTelNo: e.target.value }
                            : item
                        )
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    value={data.prodDes || undefined}
                    onChange={(e) =>
                      setConvertedDatas((prev) =>
                        prev.map((item, itemIndex) =>
                          itemIndex === index
                            ? { ...item, prodDes: e.target.value }
                            : item
                        )
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    value={data.sizeDes || undefined}
                    onChange={(e) =>
                      setConvertedDatas((prev) =>
                        prev.map((item, itemIndex) =>
                          itemIndex === index
                            ? { ...item, sizeDes: e.target.value }
                            : item
                        )
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    value={data.qty || undefined}
                    onChange={(e) =>
                      setConvertedDatas((prev) =>
                        prev.map((item, itemIndex) =>
                          itemIndex === index
                            ? { ...item, qty: Number(e.target.value) }
                            : item
                        )
                      )
                    }
                  />
                </td>
                <td>
                  <CustomInput
                    value={data.address || undefined}
                    onChange={(e) =>
                      setConvertedDatas((prev) =>
                        prev.map((item, itemIndex) =>
                          itemIndex === index
                            ? { ...item, address: e.target.value }
                            : item
                        )
                      )
                    }
                    error={addressManualIndex.includes(index)}
                  />
                </td>
                <td>
                  <input
                    value={data.addressDetail || undefined}
                    onChange={(e) =>
                      setConvertedDatas((prev) =>
                        prev.map((item, itemIndex) =>
                          itemIndex === index
                            ? { ...item, addressDetail: e.target.value }
                            : item
                        )
                      )
                    }
                  />
                </td>
                <td>
                  {data.postCd.trim() !== '' &&
                  data.postCd !== undefined &&
                  data.postCd !== null ? (
                    <input value={data.postCd || undefined} readOnly />
                  ) : (
                    <div
                      style={{ cursor: 'pointer', minWidth: '75px' }}
                      onClick={() => postCdSearchHandler(index)}
                    >
                      검색하기
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Container>
  );
};

const Container = styled.div``;

const CustomInput = styled.input<{ error: boolean }>`
  border-color: ${({ error, theme }) =>
    error ? theme.colors.red : theme.colors.black};

  border-width: ${({ error }) => (error ? '2px' : '2px')};
`;

const StyledThead = styled.thead`
  td {
    text-align: center;
  }
`;
