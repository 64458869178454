import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import TraderListTable from '../../component/table/TableComponents/TraderListTable';
import styled from 'styled-components';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../component/header/Header';
import { HeaderButtonProvider } from '../../component/header/HeaderButtonProvider';
import OrderListTable from '../../component/table/OrderListTable';
import OrderListPage from '../../pages/OrderListPage';
import Table from '../../component/table/Table';

import axios from 'axios';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { Children, useEffect, useRef } from 'react';
import { RootState } from '../../store/modules';
import { useSelector } from 'react-redux';
import { getAccessTokenAsync } from '../../service/tokenService';
import DashBoardPage from '../../pages/DashBoardPage';
import { DeliveryPage } from './pages/DeliveryPage';
import { OrderUpdatePage } from './pages/OrderUpdatePage';
import { ProductionAdd } from './pages/ProductionAdd';
import { ProductionOverview } from './pages/ProductionOverview';
import { useDispatch } from 'react-redux';
import {
  increaseProductionCount,
  setProductionData,
} from '../../store/modules/socketData';
import { ProductionProgress } from './pages/ProductionProgress';
import { WebsocketManager } from '../../hook/WebsocketManager';
import { Stock } from './pages/Stock';
import NaverMap from '../../component/map/NaverMap';
import { NaverMapWrapper } from '../../component/map/NaverMapWrapper';
import { ProductionProgressWorker } from './pages/ProductionProgressWorker';
import { ProductionInbound } from './pages/ProductionInbound';
import { BomPage } from './pages/BomPage';
import { ParcelItemPage } from './pages/ParcelItemPage';
import { ParcelTraderPage } from './pages/ParcelTraderPage';
import { ParcelPage } from './pages/ParcelPage';
import { Attendance } from './pages/Attendance';
import { OrderAddressVerifyTable } from '../../component/table/TableComponents/OrderAddressVerifyTable';
import { EmmShopOrderTable } from '../../component/table/TableComponents/EmmShopOrderTable';
import { OrderAddressModifyTable } from '../../component/table/TableComponents/OrderAddressModifyTable';
import toast, { ToastOptions } from 'react-hot-toast';
export const ManagerRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/login') {
      navigate('/orders/update');
    }
  }, [location]);
  const WithDefaultLayout = ({ children }: { children: React.ReactNode }) => (
    <HeaderButtonProvider>
      <Sidebar />
      <BodyContainer>
        <Header />
        <Content>{children}</Content>
      </BodyContainer>
    </HeaderButtonProvider>
  );

  return (
    <WholeContainer>
      <WebsocketManager />

      <Routes>
        <Route
          path='dash-board'
          element={
            <WithDefaultLayout>
              <DashBoardPage />
            </WithDefaultLayout>
          }
        />

        <Route
          path='trader/update'
          element={
            <WithDefaultLayout>
              <TraderListTable />
            </WithDefaultLayout>
          }
        />
        <Route
          path='production-add'
          element={
            <WithDefaultLayout>
              <ProductionAdd />
            </WithDefaultLayout>
          }
        />
        <Route
          path='production-overview'
          element={
            <WithDefaultLayout>
              <ProductionOverview />
            </WithDefaultLayout>
          }
        />
        <Route
          path='production-progress'
          element={
            <WithDefaultLayout>
              <ProductionProgress />
            </WithDefaultLayout>
          }
        />
        <Route
          path='production-inbound'
          element={
            <WithDefaultLayout>
              <ProductionInbound />
            </WithDefaultLayout>
          }
        />
        <Route
          path='production-progress/:productionId/:itemId'
          element={
            // <WithDefaultLayout>
            <ProductionProgressWorker />
            // </WithDefaultLayout>
          }
        />
        <Route
          path='stock'
          element={
            <WithDefaultLayout>
              <Stock />
            </WithDefaultLayout>
          }
        />
        {/* <Route path='/' element={<OrderListPage />} /> */}
        {/* <Route path='/' element={<Table />} /> */}
        {/* <Route path='/' element={<OrderlistPage />} /> */}
        <Route
          path='orders/update'
          element={
            <WithDefaultLayout>
              <OrderUpdatePage />
            </WithDefaultLayout>
          }
        />
        {/* 현재 미사용 */}
        <Route
          path='orders/address-verify'
          element={
            <WithDefaultLayout>
              <OrderAddressVerifyTable />
            </WithDefaultLayout>
          }
        />
        <Route
          path='orders/address-modify'
          element={
            <WithDefaultLayout>
              <OrderAddressModifyTable />
            </WithDefaultLayout>
          }
        />
        <Route
          path='orders/emm-shop-changed'
          element={
            <WithDefaultLayout>
              <EmmShopOrderTable />
            </WithDefaultLayout>
          }
        />
        <Route
          path='order-list'
          element={
            <WithDefaultLayout>
              <OrderListPage />
            </WithDefaultLayout>
          }
        />
        <Route
          path='dash-board'
          element={
            <WithDefaultLayout>
              <DashBoardPage />
            </WithDefaultLayout>
          }
        />
        <Route
          path='delivery/set'
          element={
            <WithDefaultLayout>
              <DeliveryPage />
            </WithDefaultLayout>
          }
        />
        <Route
          path='bom'
          element={
            <WithDefaultLayout>
              <BomPage />
            </WithDefaultLayout>
          }
        />
        <Route
          path='parcel-item'
          element={
            <WithDefaultLayout>
              <ParcelItemPage />
            </WithDefaultLayout>
          }
        />
        <Route
          path='parcel-trader'
          element={
            <WithDefaultLayout>
              <ParcelTraderPage />
            </WithDefaultLayout>
          }
        />
        <Route
          path='parcel'
          element={
            <WithDefaultLayout>
              <ParcelPage />
            </WithDefaultLayout>
          }
        />
        <Route
          path='attendance'
          element={
            <WithDefaultLayout>
              <Attendance />
            </WithDefaultLayout>
          }
        />
        <Route path='naver-map' element={<NaverMapWrapper />} />
      </Routes>
    </WholeContainer>
  );
};

const WholeContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const BodyContainer = styled.div`
  flex-basis: 100%;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.lightGray};
`;
