import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { RootState } from '../../../store/modules';
import { useEffect, useLayoutEffect, useState } from 'react';
import {
  StockInfoRecordType,
  StockInfoType,
} from '../../../router/user/pages/ProductionProgressWorker';
import {
  itemBomListGetApi,
  ItemBomResponseType,
  ProductionResponseType,
  StockHistoryResponseType,
} from '../../../api/apiConfig';
import useFetch from '../../../api/useFetch';
import { ProductionSquareCard } from '../../common/card/ProductionSquareCard';
import { ProductionCircularCardFix } from '../../common/card/ProductionCircularCardFix';
export const ProductionProgressTabletWorker = () => {
  const param = useParams();

  const theme = useTheme();

  const navigate = useNavigate();

  const [cardHeight, setCardHeight] = useState<number>(0);

  const socketData = useSelector((state: RootState) => state.socketData);

  const [increaseCount, setIncreaseCount] = useState<number>(1);

  const [stockInfo, setStockInfo] = useState<StockInfoRecordType>();

  const [productionData, setProductionData] =
    useState<ProductionResponseType | null>(null);

  const [bom, setBom] = useState<ItemBomResponseType | null>(null);

  const [filteredStockHistory, setFilteredStockHistory] = useState<
    StockHistoryResponseType[]
  >([]);

  const productionDatas = useSelector(
    (state: RootState) => state.socketData.production
  );

  const { fetch: fetchItemBomListGet } = useFetch({
    fetchFunction: itemBomListGetApi,
    onSuccess: (data: ItemBomResponseType) => setBom(data),
  });

  useEffect(() => {
    if (!bom) return;

    const stockInfo: StockInfoRecordType = bom.consumedItems.reduce(
      (acc, bomItem) => {
        const matchingStock = socketData.stock.find(
          (stock) => stock.itemId === bomItem.itemId
        );

        const targetStockHistory = socketData.stockHistory.find(
          (stockHistory) =>
            stockHistory.itemId === bomItem.itemId &&
            stockHistory.productionId === productionData?.id
              ? stockHistory
              : null
        );

        const targetStockHistoryEtc = socketData.stockHistory
          .map((stockHistory) =>
            stockHistory.itemId === bomItem.itemId ? stockHistory : null
          )
          .filter((data) => data !== null);

        if (!matchingStock) return acc; // 매칭되는 stock이 없으면 건너뜀

        acc[bomItem.itemId] = {
          itemDes: matchingStock.prodDes,
          sizeDes: matchingStock.sizeDes,
          stockQty: matchingStock.qty,
          stockHistoryQty: targetStockHistory?.changedQuantity || 0,
          stockHistoryQtyEtc: targetStockHistoryEtc?.reduce(
            (acc, data) => acc + data.changedQuantity,
            0
          ),
          consumedQty: bomItem.consumedQty, // bom의 consumedQty 가져오기
        };

        return acc;
      },
      {} as Record<number, StockInfoType>
    ); // 초기값은 빈 객체

    setStockInfo(stockInfo);

    const productionTarget =
      productionDatas.find(
        (production) => Number(production.id) === Number(param.productionId)
      ) || null;

    if (
      productionTarget?.progress === 'C' ||
      productionTarget?.progress === 'E'
    ) {
      window.alert('생산일정이 마감되었습니다. 이전페이지로 돌아갑니다.');
      navigate('/production-progress');
    }

    setProductionData(productionTarget);
  }, [socketData, bom]);

  useEffect(() => {
    if (!param.productionId) return;
    if (!param.itemId) return;
    fetchItemBomListGet(Number(param.itemId));
  }, [param]);

  useLayoutEffect(() => {
    const container = document.querySelector('#container');
    if (container) {
      const computedStyle = window.getComputedStyle(container);
      const height = parseFloat(computedStyle.height); // 패딩 제외한 높이
      setCardHeight(height * 0.17);
    }
  }, []);

  return (
    <Container id='container'>
      <ProductionSquareCard
        height={cardHeight}
        current={productionData ? productionData.productionCnt : 0}
        goal={productionData ? productionData.productionGoal : 0}
        prodDes={productionData ? productionData.item.PROD_DES : ''}
        sizeDes={productionData ? productionData.item.SIZE_DES : ''}
      />
      <BomSection $minHeight={cardHeight + 40}>
        <BomRow style={{ padding: '7px 7px' }}>
          <Label className='desc-cell'>소모품 정보</Label>
          <Label className='my-bom-cell'>나의 소모량</Label>
          <Label className='other-bom-cell'>기타 소모량</Label>
          <Label className='stock-cell'>현재 재고</Label>
        </BomRow>
        <BomBody $maxHeight={cardHeight} className='even-shadow'>
          {bom?.consumedItems.map((item) => (
            <BomRow>
              {stockInfo && stockInfo[item.itemId] && (
                <>
                  <Label className='desc-cell'>
                    {stockInfo[item.itemId].itemDes} /{' '}
                    {stockInfo[item.itemId].sizeDes}
                  </Label>
                  <Label className='my-bom-cell'>
                    {stockInfo[item.itemId].stockQty}
                  </Label>
                  <Label className='other-bom-cell'>
                    {stockInfo[item.itemId].stockHistoryQtyEtc}
                  </Label>
                  <Label className='stock-cell'>
                    {stockInfo[item.itemId].stockQty +
                      stockInfo[item.itemId].stockHistoryQtyEtc}
                  </Label>
                </>
              )}
            </BomRow>
          ))}

          {/* {bom?.consumedItems.map((item) => (
            <BomRow>
              {stockInfo && stockInfo[item.itemId] && (
                <>
                  <Label className='desc-cell'>
                    {stockInfo[item.itemId].itemDes} /{' '}
                    {stockInfo[item.itemId].sizeDes}
                  </Label>
                  <Label className='other-bom-cell'>
                    {stockInfo[item.itemId].stockHistoryQty}
                  </Label>
                  <Label className='my-bom-cell'>
                    {stockInfo[item.itemId].stockHistoryQtyEtc}
                  </Label>
                  <Label className='stock-cell'>소모품 정보</Label>
                </>
              )}
            </BomRow>
          ))} */}
        </BomBody>
      </BomSection>
      <AllProductionSection>
        <h3 style={{ color: theme.colors.charcoal }}>
          - 진행중 및 마감된 생산항목
        </h3>
        <CardSection>
          {productionDatas.length > 0 ? (
            productionDatas
              .filter(
                (production) =>
                  production.workbayId === productionData?.workbayId
              )
              .map((production) => (
                <ProductionCircularCardFix
                  width={170}
                  height={270}
                  hover={false}
                  onClick={() => undefined}
                  itemName={production.item.PROD_DES}
                  itemSize={production.item.SIZE_DES}
                  workbayName={production.workbayName}
                  current={production.productionCnt}
                  goal={production.productionGoal}
                  progress={production.progress}
                />
              ))
          ) : (
            <h4>-생산항목이 없습니다</h4>
          )}
        </CardSection>
      </AllProductionSection>
    </Container>
  );
};

const Label = styled.h4`
  color: ${({ theme }) => theme.colors.charcoal};
  font-weight: 700;
  letter-spacing: 0;
  margin: 0;
`;

const Container = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px 20px;
`;

const BomSection = styled.div<{ $minHeight: number }>`
  min-height: ${({ $minHeight }) => `${$minHeight}px`};
  box-sizing: content-box;
  .desc-cell {
    flex: 0 0 43%;
  }
  .my-bom-cell {
    flex: 0 0 18%;
    text-align: center;
  }
  .other-bom-cell {
    flex: 0 0 18%;
    text-align: center;
  }
  .stock-cell {
    flex: 0 0 18%;
    text-align: center;
  }
`;

const BomBody = styled.div<{ $maxHeight: number }>`
  min-height: ${({ $maxHeight }) => `${$maxHeight / 2}px`};
  max-height: ${({ $maxHeight }) => `${$maxHeight}px`};
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

const BomRow = styled.div`
  display: flex;
  flex: 0 0 30px;
  flex-direction: row;
  align-items: center;
  padding: 0px 7px;
`;

const CardSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 28px;
  overflow-x: scroll;
  padding: 8px 8px 0px 8px;
`;

const AllProductionSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 50%;
`;
