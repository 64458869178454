import {
  HTMLProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { orderData, OrderHeaderType } from '../TableTestdata';
import {
  ColumnDef,
  Row,
  RowData,
  TableMeta,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import * as Styled from '../TableStyled';
import TableConfig from '../TableConfig';
import { useHeaderButtons } from '../../header/HeaderButtonProvider';
import SquareButton from '../../button/SquareButton';
import { AiFillCaretRight } from 'react-icons/ai';
import { AiFillCaretDown } from 'react-icons/ai';
import ListTooltip from '../../common/ListTooltip';
import { ValueWithKey } from '../TableTestdata';
import { traderData } from '../TableTestdata';
import TraderUploadModal from '../../common/modal/ModalComponents/TraderUploadModal';
import axios from 'axios';
import { useTheme } from 'styled-components';
import { toast } from 'react-hot-toast';
import {
  emmShopChangedOrderConfirmApi,
  EmmShopOrder,
  emmShopOrderListGetApi,
  keyMapping,
  orderAddressDataPutApi,
  OrderAddressUpdateRequestType,
  orderListGetApi,
  orderListPostApi,
  orderToVerifyAddressListGetApi,
  OrderUploadResponseType,
  traderListGetApi,
  traderListPostApi,
  TraderType,
} from '../../../api/apiConfig';
import useFetch from '../../../api/useFetch';
import { OrderUploadModal } from '../../common/modal/ModalComponents/OrderUploadModal';
//주문번호 예시 20241104-1

import { BsChevronDoubleLeft } from 'react-icons/bs';
import { BsChevronDoubleRight } from 'react-icons/bs';
import { BsChevronLeft } from 'react-icons/bs';
import { BsChevronRight } from 'react-icons/bs';

import { IoMdSearch } from 'react-icons/io';

import {
  OrderListType,
  OrderListUpdateRequestDto,
} from '../../../api/apiConfig';
import { PaginationState } from '../Pagination';
import {
  formatCurrency,
  formatEightDigitStringToSlashForm,
  getYearMonthDateFromDate,
} from '../../../function/replaceString';

import AddressSearchModal from '../../common/modal/ModalComponents/AddressSearchModal';

export const EmmShopOrderTable = () => {
  const theme = useTheme();
  const { setButtons } = useHeaderButtons();

  const { fetch: fetchEmmShopOrderListGetApi } = useFetch({
    fetchFunction: emmShopOrderListGetApi,
    onSuccess: (data: EmmShopOrder[]) => {
      setData(data);
    },
  });

  const { fetch: fetchEmmShopChangedOrderConfirm } = useFetch({
    fetchFunction: emmShopChangedOrderConfirmApi,
    onSuccess: (id: number) => {
      setData((prev) => prev.filter((data) => data.id !== id));
    },
  });

  //   const { fetch: apiPostOrderList } = useFetch({
  //     fetchFunction: orderListPostApi,
  //     onSuccess: (data: OrderUploadResponseType) => {
  //       setUpdatedData(data);
  //       // toast('업데이트에 성공하였습니다.');
  //       // setData(data);
  //     },
  //   });

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  //모든 테이블값
  const [data, setData] = useState<EmmShopOrder[]>([]);

  //테이블 옵션값 ~~~
  const [headerOverflowType, setHeaderOverflowType] = useState<'wrap' | 'hide'>(
    'hide'
  );
  const [bodyOverflowType, setBodyOverflowType] = useState<'wrap' | 'hide'>(
    'hide'
  );
  const [bodyAlignHoriType, setBodyAlignHoriType] = useState<
    'left' | 'center' | 'right'
  >('left');

  const [cellHoriPaddValue, setCellHoriPaddValue] = useState<number>(5);
  const [bodyVertPaddValue, setBodyVertPaddValue] = useState<number>(5);

  const [showConfig, setShowConfig] = useState<boolean>(false);

  useEffect(() => {
    fetchEmmShopOrderListGetApi();
  }, []);

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        //TH Cell
        header: ({ table }) => <>확인처리</>,
        cell: ({ row, getValue }) => {
          if (row.depth === 0) {
            const id = row.original.id || null;
            return <div>확인</div>;
          } else {
            return null;
          }
        },
        id: 'confirm-button',
        size: 79,
      },
      //   {
      //     header: '주문번호',
      //     accessorKey: 'ORDER_ID',
      //     size: 84,
      //   },
      {
        header: '주문자명',
        size: 180,
        cell: ({ row }) => {
          const ordererName = row.original.ordererName || null;
          const recipientName = row.original.recipientName || null;
          return ordererName ?? recipientName ?? '';
        },
      },
      //   {
      //     header: '담당자명',
      //     accessorKey: 'EMP_NAME',
      //     size: 65,
      //   },
      {
        header: '품목명',
        size: 140,
        cell: ({ row }) => {
          const productName = row.original.productName || null;
          return productName ?? '';
        },
      },
      {
        header: '단가',
        size: 53,
        cell: ({ row }) => {
          const price = row.original.price || null;
          return price ? formatCurrency(Number(price)) : '';
        },
      },
      {
        header: '수량',
        size: 48,
        cell: ({ row }) => {
          const quantity = row.original.quantity || null;
          return quantity ? quantity : '';
        },
      },
      //   {
      //     header: '공급가액',
      //     accessorKey: 'SUPPLY_AMT',
      //     size: 73,
      //     cell: (supplyAmt) => formatCurrency(supplyAmt.getValue() as number),
      //   },

      {
        header: '주문특이사항',
        size: 420,
        cell: ({ row }) => {
          const memo = row.original.memo || null;
          return memo ? memo : '';
        },
      },
      {
        header: '주소',
        accessorKey: 'receiverAddress',
        size: 420,
        cell: ({ row }) => {
          const recipientAddressBase =
            row.original.recipientAddressBase || null;
          const recipientAddressDetail =
            row.original.recipientAddressDetail || null;
          return recipientAddressBase
            ? recipientAddressDetail
              ? recipientAddressBase + ' ' + recipientAddressDetail
              : recipientAddressBase
            : '';
        },
      },
      {
        header: '주소(참조)',
        accessorKey: 'receiverAddressDetail',
        size: 420,
        cell: ({ row }) => {
          const recipientAddressOption =
            row.original.recipientAddressOption || null;
          return recipientAddressOption ? recipientAddressOption : '';
        },
      },

      //   {
      //     header: '적요',
      //     accessorKey: 'REMARKS',
      //     size: 420,
      //   },
    ],
    []
  );

  //아마 페이지네이션
  function useSkipper() {
    const shouldSkipRef = useRef(true);
    const shouldSkip = shouldSkipRef.current;

    // Wrap a function with this to skip a pagination reset temporarily
    const skip = useCallback(() => {
      shouldSkipRef.current = false;
    }, []);

    useEffect(() => {
      shouldSkipRef.current = true;
    });

    return [shouldSkip, skip] as const;
  }

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  //~~페이지네이션

  useEffect(() => {
    //헤더에 버튼 추가
    setButtons(
      <>
        <SquareButton
          text='주소 검색'
          colorType='ACTIVE'
          onClick={() => {
            // setModalType('add');
            // setShowModal(true);
          }}
        />
        {/* <SquareButton
          text='전체 저장'
          colorType={
            addressDatas.current.some((addressData) => isAllFilled(addressData))
              ? 'ACTIVE'
              : 'DISACTIVE'
          }
          onClick={() => {
            addressDatas.current.some((addressData) =>
              isAllFilled(addressData)
            ) && updateOrders();
          }}
        /> */}
      </>
    );
    // 페이지가 바뀌면 버튼 초기화
    return () => setButtons(null);
  }, [setButtons]);

  const table = useReactTable({
    data,
    columns,
    columnResizeMode: 'onChange',
    state: {
      expanded: true,
      pagination,
    },
    getSubRows: (row) => row.products,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    autoResetPageIndex,
    getPaginationRowModel: getPaginationRowModel(),
    paginateExpandedRows: false,
    onPaginationChange: setPagination,
  });

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]!;
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }
    return colSizes;
  }, [table.getState().columnSizingInfo, table.getState().columnSizing]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Styled.PaginationButtonContainer>
          <Styled.PaginationButton
            size={28}
            onClick={() => table.firstPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <BsChevronDoubleLeft />
          </Styled.PaginationButton>
          <Styled.PaginationButton
            size={28}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <BsChevronLeft />
          </Styled.PaginationButton>
          {/* 중간인풋 */}
          <Styled.PaginationButton
            size={28}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <BsChevronRight />
          </Styled.PaginationButton>
          <Styled.PaginationButton
            size={28}
            onClick={() => table.lastPage()}
            disabled={!table.getCanNextPage()}
          >
            <BsChevronDoubleRight />
          </Styled.PaginationButton>
        </Styled.PaginationButtonContainer>
        <div
          style={{
            alignContent: 'center',
            marginLeft: '20px',
            marginRight: '40px',
          }}
        >
          {table.getPageCount()}페이지 중{' '}
          {table.getState().pagination.pageIndex + 1}페이지
        </div>
        <div className='flex-center'>페이지당 표시 데이터 수</div>
        <select
          id={'row-count'}
          value={pagination.pageSize}
          onChange={(e) => {
            setPagination((prev) => ({
              pageIndex: prev.pageIndex,
              pageSize: Number(e.target.value),
            }));
            table.firstPage();
          }}
          style={{ marginLeft: '14px' }}
        >
          <option key={'10'} value={10}>
            10개
          </option>
          <option key={'20'} value={20}>
            20개
          </option>
          <option key={'30'} value={30}>
            30개
          </option>
          <option key={'40'} value={40}>
            40개
          </option>
          <option key={'50'} value={50}>
            50개
          </option>
        </select>
      </div>

      <Styled.TableContainer>
        <Styled.TableOptionButton onClick={() => setShowConfig(!showConfig)}>
          설정
        </Styled.TableOptionButton>
        <TableConfig
          show={showConfig}
          Header_Overflow_Type={headerOverflowType}
          setHeaderOverflowType={(value: 'hide' | 'wrap') =>
            setHeaderOverflowType(value)
          }
          Body_Overflow_Type={bodyOverflowType}
          setBodyOverflowType={(value: 'hide' | 'wrap') =>
            setBodyOverflowType(value)
          }
          Body_Align_Hori_Type={bodyAlignHoriType}
          setBodyAlignHoriType={(value: 'left' | 'center' | 'right') =>
            setBodyAlignHoriType(value)
          }
          Cell_Hori_Padd_Value={cellHoriPaddValue}
          setCellHoriPaddValue={(value: number) => setCellHoriPaddValue(value)}
          Body_Vert_Padd_Value={bodyVertPaddValue}
          setBodyVertPaddValue={(value: number) => setBodyVertPaddValue(value)}
        />
        <Styled.TableDiv
          {...{
            style: {
              ...columnSizeVars,
              width: table.getTotalSize(),
            },
          }}
          headOverflowType={headerOverflowType}
          bodyOverflowType={bodyOverflowType}
          bodyAlignHoriType={bodyAlignHoriType}
          cellHoriPaddValue={cellHoriPaddValue}
          bodyVertPaddValue={bodyVertPaddValue}
        >
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <Styled.ThDiv {...{ key: headerGroup.id }}>
                {headerGroup.headers.map((headerCell) =>
                  headerCell.isPlaceholder ? null : (
                    <Styled.ThCell
                      {...{
                        key: headerCell.id,
                        style: {
                          width: `calc(var(--header-${headerCell?.id}-size) * 1px)`,
                        },
                        className: 'th',
                      }}
                    >
                      {headerCell.isPlaceholder
                        ? null
                        : flexRender(
                            headerCell.column.columnDef.header,
                            headerCell.getContext()
                          )}
                      <Styled.ResizeHandler
                        {...{
                          onMouseDown: headerCell.getResizeHandler(),
                          className: `resizer ${
                            headerCell.column.getIsResizing()
                              ? 'isResizing'
                              : ''
                          }`,
                        }}
                      />
                    </Styled.ThCell>
                  )
                )}
              </Styled.ThDiv>
            );
          })}
          {table.getRowModel().rows.map((row) => (
            <>
              <Styled.TdDiv
                {...{
                  key: row.id,
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  const isMainRow = row.depth === 0;
                  const isButtonCell = cell.column.id === 'confirm-button';
                  const id = row.original.id || null;
                  console.log(id);
                  let className = 'td';
                  if (isMainRow) {
                    className += ' odd';
                  }
                  if (isMainRow && isButtonCell) {
                    className += ' hover-item';
                  }

                  // 커스텀 스타일 (변경된 셀에 하이라이트 적용)
                  return (
                    <Styled.TdCell
                      {...{
                        key: cell.id,
                        className: className,
                        style: {
                          width: `calc(var(--header-${cell.column.id}-size) * 1px)`, // 셀 크기
                        },
                        onClick: () => {
                          isMainRow &&
                            isButtonCell &&
                            id &&
                            fetchEmmShopChangedOrderConfirm(id);
                        },
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Styled.TdCell>
                  );
                })}
              </Styled.TdDiv>
            </>
          ))}
        </Styled.TableDiv>
      </Styled.TableContainer>
    </>
  );
};
