import { WorkbayType } from '../../api/apiConfig';

const SET_SELECTED_WORKBAY_TABLET =
  'tabletOption/SET_SELECTED_WORKBAY_TABLET' as const;
const CLEAR_SELECTED_WORKBAY_TABLET =
  'tabletOption/CLEAR_SELECTED_WORKBAY_TABLET' as const;
const SET_SELECTED_PROD_TYPE = 'tabletOption/SET_SELECTED_PROD_TYPE' as const;

export interface TabletOptionType {
  selectedWorkbay: WorkbayType | null;
  selectedProdType: number;
}

export type TabletOptionAction =
  | {
      type: typeof SET_SELECTED_WORKBAY_TABLET;
      payload: WorkbayType;
    }
  | {
      type: typeof CLEAR_SELECTED_WORKBAY_TABLET;
    }
  | {
      type: typeof SET_SELECTED_PROD_TYPE;
      payload: number;
    };

export const setSelectedWorkbayTablet = (payload: WorkbayType) => ({
  type: SET_SELECTED_WORKBAY_TABLET,
  payload: payload,
});

export const clearSelectedWorkbayTablet = () => ({
  type: CLEAR_SELECTED_WORKBAY_TABLET,
});

export const setSelectedProdType = (payload: number) => ({
  type: SET_SELECTED_PROD_TYPE,
  payload: payload,
});

const initialState: TabletOptionType = {
  selectedWorkbay: null,
  selectedProdType: -1,
};

const tabletOptionReducer = (
  state: TabletOptionType = initialState,
  action: TabletOptionAction
) => {
  switch (action.type) {
    case SET_SELECTED_WORKBAY_TABLET:
      return {
        ...state,
        selectedWorkbay: action.payload,
      };

    case CLEAR_SELECTED_WORKBAY_TABLET:
      return {
        ...state,
        selectedWorkbay: null,
      };

    case SET_SELECTED_PROD_TYPE:
      return {
        ...state,
        selectedProdType: action.payload,
      };

    default:
      return state;
  }
};

export default tabletOptionReducer;
