export const Note_SVG = ({
  style,
  fill,
}: {
  style?: React.CSSProperties;
  fill?: string;
}) => {
  return (
    <svg fill={fill || '#000000'} viewBox='0 0 48 48' style={{ ...style }}>
      <path d='M0 0h48v48H0z' fill='none' />
      <g id='Shopicon'>
        <polygon points='40,38 8,38 8,10 24,10 24,6 4,6 4,42 44,42 44,22 40,22  ' />
        <path d='M20,19.172V28h8.828l16-16L36,3.172L20,19.172z M27.172,24H24v-3.172l12-12L39.172,12L27.172,24z' />
      </g>
    </svg>
  );
};
//
