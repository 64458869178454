import { StyleHTMLAttributes } from 'react';

export const Factory_SVG = ({
  style,
  fill,
}: {
  style?: React.CSSProperties;
  fill?: string;
}) => {
  return (
    <svg fill={fill || '#000000'} viewBox='0 0 24 24' style={{ ...style }}>
      <path d='M22,1H18a1,1,0,0,0-1,1V9H15V7a1,1,0,0,0-1.447-.895L9,8.382V7a1,1,0,0,0-1.447-.895l-6,3A1,1,0,0,0,1,10V22a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V2A1,1,0,0,0,22,1ZM21,3V5H19V3ZM3,21V10.618l4-2V10a1,1,0,0,0,1.447.895L13,8.618V10a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V7h2V21Zm2-8H8v2H5Zm5,0h4v2H10Zm6,0h3v2H16ZM5,17H8v2H5Zm5,0h4v2H10Zm6,0h3v2H16Z' />
    </svg>
  );
};
