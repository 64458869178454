import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { RootState } from '../../../store/modules';
import { useEffect, useLayoutEffect, useState } from 'react';
import {
  StockInfoRecordType,
  StockInfoType,
} from '../../../router/user/pages/ProductionProgressWorker';
import {
  itemBomListGetApi,
  ItemBomResponseType,
  ProductionResponseType,
  StockHistoryResponseType,
  StockResponseType,
  UnsyncedStockHistoryResponseType,
} from '../../../api/apiConfig';
import useFetch from '../../../api/useFetch';
import { ProductionSquareCard } from '../../common/card/ProductionSquareCard';
import { ProductionCircularCardFix } from '../../common/card/ProductionCircularCardFix';
import { ProductionSquareCardReverse } from '../../common/card/ProductionSquareCardReverse';
export const ProductionBomProgress = () => {
  const param = useParams();

  const theme = useTheme();

  const navigate = useNavigate();

  const [cardHeight, setCardHeight] = useState<number>(0);

  const productionDatas = useSelector(
    (state: RootState) => state.socketData.production
  );

  const unsyncedStockHistoryList = useSelector(
    (state: RootState) => state.socketData.unsyncedStockHistory
  );

  const stockDatas = useSelector((state: RootState) => state.socketData.stock);

  const [unsyncedStockHistory, setUnsyncedStockHistory] =
    useState<UnsyncedStockHistoryResponseType | null>(null);

  const [targetStock, setTargetStock] = useState<StockResponseType | null>(
    null
  );

  const [otherStockHistoriesTotalQty, setOtherStockHistoriesTotalQty] =
    useState<number>(0);

  const [orderedProductionDatas, setOrderedProductionDatas] = useState<
    ProductionResponseType[]
  >([]);

  useEffect(() => {
    if (!param.workbayId) return;
    if (!param.itemId) return;
    const targetWorkbayId = Number(param.workbayId);
    const targetItemId = Number(param.itemId);

    setUnsyncedStockHistory(
      unsyncedStockHistoryList.find(
        (ush) =>
          ush.workbayId === targetWorkbayId && ush.itemId === targetItemId
      ) || null
    );
    setTargetStock(
      stockDatas.find((stock) => stock.itemId === targetItemId) || null
    );
    setOtherStockHistoriesTotalQty(
      unsyncedStockHistoryList
        .filter(
          (ush) =>
            ush.itemId === targetItemId && ush.workbayId !== targetWorkbayId
        )
        .reduce((acc, value) => acc + value.changedQuantity, 0)
    );
    const sortOrder = { P: 1, W: 2, C: 3, E: 4 };

    const sortedDatas = [...productionDatas].sort(
      (a, b) =>
        sortOrder[a.progress as keyof typeof sortOrder] -
        sortOrder[b.progress as keyof typeof sortOrder]
    );

    setOrderedProductionDatas(sortedDatas);
  }, [param, unsyncedStockHistoryList, stockDatas, productionDatas]);

  useLayoutEffect(() => {
    const container = document.querySelector('#container');
    if (container) {
      const computedStyle = window.getComputedStyle(container);
      const height = parseFloat(computedStyle.height); // 패딩 제외한 높이
      setCardHeight(height * 0.17);
    }
  }, []);

  return (
    <Container id='container'>
      <div style={{ height: '35px' }} />
      {targetStock !== null && unsyncedStockHistory !== null && (
        <ProductionSquareCardReverse
          height={cardHeight}
          currentWorkbayConsumeQty={unsyncedStockHistory.changedQuantity}
          otherWorkbaysConsumeQty={otherStockHistoriesTotalQty}
          goal={targetStock.qty}
          prodDes={targetStock.prodDes}
          sizeDes={targetStock.sizeDes}
        />
      )}
      <div style={{ height: '23px' }} />

      <AllProductionSection>
        <h3 style={{ color: theme.colors.charcoal }}>연관된 작업목록</h3>
        <CardSection>
          {orderedProductionDatas.length > 0 ? (
            orderedProductionDatas.map((production) => (
              <ProductionCircularCardFix
                width={170}
                height={270}
                hover={false}
                onClick={() => undefined}
                itemName={production.item.PROD_DES}
                itemSize={production.item.SIZE_DES}
                workbayName={production.workbayName}
                current={production.productionCnt}
                goal={production.productionGoal}
                progress={production.progress}
              />
            ))
          ) : (
            <h4>-연관된 작업목록이 없습니다</h4>
          )}
        </CardSection>
      </AllProductionSection>
    </Container>
  );
};

const Label = styled.h4`
  color: ${({ theme }) => theme.colors.charcoal};
  font-weight: 700;
  letter-spacing: 0;
  margin: 0;
`;

const Container = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 20px;
`;

const BomSection = styled.div<{ $minHeight: number }>`
  min-height: ${({ $minHeight }) => `${$minHeight}px`};
  box-sizing: content-box;
  .desc-cell {
    flex: 0 0 43%;
  }
  .my-bom-cell {
    flex: 0 0 18%;
    text-align: center;
  }
  .other-bom-cell {
    flex: 0 0 18%;
    text-align: center;
  }
  .stock-cell {
    flex: 0 0 18%;
    text-align: center;
  }
`;

const BomBody = styled.div<{ $maxHeight: number }>`
  min-height: ${({ $maxHeight }) => `${$maxHeight / 2}px`};
  max-height: ${({ $maxHeight }) => `${$maxHeight}px`};
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

const BomRow = styled.div`
  display: flex;
  flex: 0 0 30px;
  flex-direction: row;
  align-items: center;
  padding: 0px 7px;
`;

const CardSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 28px;
  overflow-x: scroll;
  padding: 8px 8px 0px 8px;
`;

const AllProductionSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 50%;
`;
