import styled, { css } from 'styled-components';
import { CustomCircularProgressbar } from '../CustomCircularProgressbar';
import { progressMapper } from '../../../@const/productionProgress';

export type ProductionCardProps = {
  current: number;
  goal: number;
  workbayName: string;
  itemName: string;
  itemSize: string;
  progress: string;
  hover?: boolean;
  width: number;
  height: number;
  onClick: () => void;
};
export const ProductionCircularCardFix = ({
  current,
  goal,
  workbayName,
  itemName,
  itemSize,
  progress,
  hover = false,
  width,
  height,
  onClick,
}: ProductionCardProps) => {
  const active = progress === 'P';
  return (
    <Container
      className='even-shadow'
      $hover={hover}
      $active={active}
      $width={width}
      $height={height}
      onClick={() => active && onClick()}
    >
      <Header>
        <div className='medium-text'>{itemName}</div>
        <div className='small-text'>[{itemSize}]</div>
      </Header>
      <ProgressHolder>
        <CustomCircularProgressbar
          current={current}
          goal={goal}
          // style={{ width: '120px' }}
        />
        {/* <CustomProgressBar
          current={current}
          goal={goal}
          style={{ width: '180px' }}
        /> */}
      </ProgressHolder>
      <CountSection>
        <CountRow>
          <div className='medium-text'>목표수량:</div>
          <CountCell className='large-text'>{goal}</CountCell>
          <div className='medium-text'>개</div>
        </CountRow>
        <CountRow>
          <div className='medium-text'>달성수량:</div>

          <CountCell className='large-text'>{current}</CountCell>
          <div className='medium-text'>개</div>
        </CountRow>
      </CountSection>
      <ProgressSection className='medium-text' style={{ alignSelf: 'center' }}>
        {progressMapper(progress)}
      </ProgressSection>
    </Container>
  );
};

const Container = styled.div<{
  $hover: boolean;
  $active: boolean;
  $width: number;
  $height: number;
}>`
  min-width: ${({ $width }) => `${$width}px`};
  min-height: ${({ $height }) => `${$height}px`};
  max-width: ${({ $width }) => `${$width}px`};
  max-height: ${({ $height }) => `${$height}px`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  background-color: ${({ theme, $active }) =>
    $active ? theme.colors.white : theme.colors.brightGray};
  border-radius: 10px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  * {
    overflow: hidden; /* 넘치는 부분 숨김 */
    text-overflow: ellipsis; /* 가려지는 부분 ... 처리 */
    white-space: nowrap; /* 줄 넘김 금지 */
  }
  color: ${({ theme }) => theme.colors.charcoal};
  .small-text {
    font-size: ${({ $width, $height }) => `${$width * 0.055}px`};
    font-weight: 500;
  }
  .medium-text {
    font-size: ${({ $width, $height }) => `${$height * 0.045}px`};
    font-weight: 900;
    color: ${({ theme }) => theme.colors.black};
  }
  .large-text {
    font-size: ${({ $width, $height }) => `${$height * 0.065}px`};
    font-weight: 900;
  }
  .less-spacing {
    letter-spacing: 0;
  }
  ${({ $hover }) =>
    $hover &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.lightGray};
        cursor: pointer;
      }
    `}
`;

const Header = styled.div`
  width: 100%;
  /* height: 50px; */
  flex: 0 0 18%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.charcoal};
`;

const ProgressHolder = styled.div`
  display: flex;
  flex: 0 0 45%;
  aspect-ratio: 1/1;
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

const CountSection = styled(Header)`
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  border: none;
  flex: 0 0 22%;
  justify-content: space-between;
`;

const CountRow = styled.div`
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  padding: 0px 10px;
  font-weight: 700;
  * {
    word-break: keep-all;
  }
`;

const CountCell = styled.div`
  flex-basis: 37%;
  padding-left: 5px;
  text-align: end;
  line-height: 115%;
`;

const ProgressSection = styled.div`
  flex: 0 0 8%;
`;
