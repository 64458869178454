import styled from 'styled-components';
import { CustomProgressBar } from '../CustomProgressBar';

export const ProductionSquareCard = ({
  current,
  goal,
  prodDes,
  sizeDes,
  height,
}: {
  current: number;
  goal: number;
  prodDes: string;
  sizeDes: string;
  height: number;
}) => {
  return (
    <ProgressCard className='container even-shadow' $height={height}>
      <LeftSide>
        <ItemInfoSection className='medium-text'>{`${prodDes} / ${sizeDes}`}</ItemInfoSection>
        <ProdInfoSection>
          <ProdInfoSideItem>
            <div className='small-text less-spacing'>달성수량</div>
            <div className='small-text less-spacing'>{current}</div>
          </ProdInfoSideItem>
          <ProdInfoSideItem>
            <div className='small-text less-spacing'>목표수량</div>
            <div className='small-text less-spacing'>{goal}</div>
          </ProdInfoSideItem>
        </ProdInfoSection>
        <ProgressSection>
          <CustomProgressBar
            current={current}
            goal={goal}
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '8px',
            }}
          />
        </ProgressSection>
      </LeftSide>
      <RightSide className='large-text'>{`${(
        (current / goal) *
        100
      ).toFixed()}%`}</RightSide>
    </ProgressCard>
  );
};

const ProgressCard = styled.div<{ $height: number }>`
  width: 100%;
  padding: 10px;
  display: flex;
  min-height: ${({ $height }) => `${$height}px`};
  max-height: ${({ $height }) => `${$height}px`};
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;

  color: ${({ theme }) => theme.colors.charcoal};

  .small-text {
    font-size: ${({ $height }) => `${$height * 0.12}px`};
    font-weight: 600;
  }
  .medium-text {
    font-size: ${({ $height }) => `${$height * 0.155}px`};
    font-weight: 900;
  }
  .large-text {
    font-size: ${({ $height }) => `${$height * 0.35}px`};
    font-weight: 600;
  }
  .less-spacing {
    letter-spacing: 0;
  }
`;

const LeftSide = styled.div`
  flex: 0 0 77%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ItemInfoSection = styled.div`
  margin: 0;
  flex: 0 0 30%;
  align-self: center;
`;

const ProdInfoSection = styled.div`
  flex: 0 0 35%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ProdInfoSideItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const RightSide = styled.div`
  flex: 0 0 23%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressSection = styled.div`
  flex: 0 0 20%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ProgressPercent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontsize[36]};
`;
