import React, { useEffect, useRef } from 'react';
import ModalBase from '../ModalBase';
import { AddressSearchResultType } from '../../../table/TableComponents/OrderAddressVerifyTable';
import theme from '../../../../styles/theme';

/**
 * address
 * buildingName
 * zonecode
 *
 */

type AddressLocalResponseType = {
  address_name: string;
  x: number;
  y: number;
};

interface AddressSearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (data: AddressSearchResultType) => void;
}

const AddressSearchModal: React.FC<AddressSearchModalProps> = ({
  isOpen,
  onClose,
  onSelect,
}) => {
  const postcodeContainerRef = useRef<HTMLDivElement>(null);
  const postcodeInstanceRef = useRef<any>(null); // Postcode 인스턴스를 저장할 레퍼런스

  useEffect(() => {
    const scriptId = 'daum-postcode-script';
    const existingScript = document.getElementById(scriptId);

    if (!existingScript) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src =
        '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
      script.async = true;
      script.onload = () => {
        // 스크립트 로드 후 Postcode 객체 생성
        postcodeInstanceRef.current = new (window as any).daum.Postcode({
          oncomplete: async (data: any) => {
            console.log(data);
            // 카카오 로컬 API 호출 (우편번호 사용)
            const postNo = data.zonecode;
            const response = await fetch(
              `https://dapi.kakao.com/v2/local/search/address.json?query=${encodeURIComponent(
                data.address
              )}`,
              {
                headers: {
                  Authorization: `KakaoAK aa7308ac63661cbe121b293a6f5dd9aa`,
                },
              }
            );

            const result = await response.json();
            if (result.documents && result.documents.length === 1) {
              const data = result.documents[0] as AddressLocalResponseType;
              onSelect({
                receiverAddress: data.address_name,
                zoneNo: postNo,
                longitude: data.x,
                latitude: data.y,
              });
            } else {
              console.error('No coordinates found for the selected postcode.');
              //   onSelect({ address: data.address, zonecode: data.zonecode });
            }
          },
        });
      };
      document.body.appendChild(script);
    } else if (!postcodeInstanceRef.current) {
      // 스크립트가 이미 로드된 경우 Postcode 객체 생성
      //   postcodeInstanceRef.current = new (window as any).daum.Postcode({
      //     oncomplete: (data: any) => {
      //       console.log('Address selected:', data);
      //       //   onClose(); // 모달 닫기
      //     },
      //   });
      return;
    }
  }, [onClose]);

  function closeAfterUnmount() {
    const scriptId = 'daum-postcode-script';
    const existingScript = document.getElementById(scriptId);

    if (existingScript) {
      document.body.removeChild(existingScript); // 스크립트 제거
    }
    onClose();
  }

  useEffect(() => {
    if (isOpen && postcodeInstanceRef.current && postcodeContainerRef.current) {
      // 모달이 열릴 때만 embed 호출
      postcodeInstanceRef.current.embed(postcodeContainerRef.current, {
        width: '100%',
        height: '100%',
      });
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <ModalBase>
      <div
        style={{
          backgroundColor: '#fff',
          borderRadius: '8px',
          padding: '16px',
        }}
        className='box-shadow'
      >
        <button
          onClick={() => closeAfterUnmount()}
          style={{
            top: '8px',
            right: '8px',
            backgroundColor: 'transparent',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
            width: '100%',
            textAlign: 'end',
          }}
        >
          ✖
        </button>
        <div
          ref={postcodeContainerRef}
          style={{ width: '100%', height: '100%' }}
        ></div>
      </div>
    </ModalBase>
  );
};

export default AddressSearchModal;
