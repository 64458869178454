import { useEffect, useState } from 'react';
import NaverMap from './NaverMap';

export interface AddressItem {
  address: string;
  clientName: string;
}

export interface NaverMapProps {
  addresses: AddressItem[];
  managerAddresses: AddressItem[];
}
export const NaverMapWrapper = () => {
  const [addressData, setAddressData] = useState<AddressItem[]>([]);
  useEffect(() => {
    // 메시지를 수신할 리스너를 추가
    const handleMessage = (event: MessageEvent) => {
      // 출처를 확인 (보안을 위해 origin을 확인)
      console.log(event.origin);
      // if (event.origin !== 'http://localhost:3000') {/
      if (event.origin !== 'https://emmausi.duckdns.org') {
        console.error('알 수 없는 출처의 메시지입니다.');
        return;
      }

      const messageData = event.data;
      if (messageData && messageData.message) {
        console.log('부모 창에서 받은 메시지:', messageData.message);
        // 메시지에 대한 추가 처리
        const parsed: AddressItem[] = JSON.parse(messageData.message);
        setAddressData(parsed);
      }
    };

    window.addEventListener('message', handleMessage);

    // 클린업: 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <NaverMap addresses={addressData} />
    </div>
  );
};
