const SET_TABLET_NAV_SIDEBAR_OPENSTATE =
  'tabletNavSidebar/SET_TABLET_NAV_SIDEBAR_OPENSTATE' as const;

const SET_TABLET_NAV_SIDEBAR_WIDTH =
  'tabletNavSidebar/SET_TABLET_NAV_SIDEBAR_WIDTH' as const;

export interface TabletNavSidebarStatusType {
  openState: boolean;
  width: number | null;
}

export type TabletNavSidebarAction =
  | {
      type: typeof SET_TABLET_NAV_SIDEBAR_OPENSTATE;
      payload: boolean;
    }
  | {
      type: typeof SET_TABLET_NAV_SIDEBAR_WIDTH;
      payload: number;
    };

export const setTabletNavSidebarOpenState = (payload: boolean) => ({
  type: SET_TABLET_NAV_SIDEBAR_OPENSTATE,
  payload: payload,
});

export const setTabletNavSidebarWidth = (payload: number) => ({
  type: SET_TABLET_NAV_SIDEBAR_WIDTH,
  payload: payload,
});

const initialState: TabletNavSidebarStatusType = {
  openState: true,
  width: null,
};

const tabletNavSidebarReducer = (
  state: TabletNavSidebarStatusType = initialState,
  action: TabletNavSidebarAction
) => {
  switch (action.type) {
    case SET_TABLET_NAV_SIDEBAR_OPENSTATE:
      return {
        ...state,
        openState: action.payload,
      };

    case SET_TABLET_NAV_SIDEBAR_WIDTH:
      return {
        ...state,
        width: action.payload,
      };

    default:
      return state;
  }
};

export default tabletNavSidebarReducer;
