import styled from 'styled-components';
import { IoIosArrowBack } from 'react-icons/io';
import { RootState } from '../../../store/modules';
import { useDispatch, useSelector } from 'react-redux';
import { BsLayoutSidebarReverse } from 'react-icons/bs';
import { setTabletNavSidebarOpenState } from '../../../store/modules/tabletNavSidebar';
import { useNavigate } from 'react-router-dom';

export const TabletHeader = () => {
  const tableHeaderTextItems = useSelector(
    (state: RootState) => state.tabletNavHeader
  );

  const tabletSidebarOpenState = useSelector(
    (state: RootState) => state.tabletNavSidebar.openState
  );

  const navigate = useNavigate();

  const dispatch = useDispatch();

  return (
    <Container className='tablet-header'>
      <Section className='row-justify-start flex-gap-0dot5rem'>
        <div
          style={{ display: 'flex', gap: '7px', alignItems: 'center' }}
          onClick={() => navigate(-1)}
        >
          <IoIosArrowBack />

          <text>뒤로가기</text>
        </div>
      </Section>
      <Section className='row-justify-center'>
        <text>{tableHeaderTextItems.workbayName}</text>
      </Section>
      <Section
        className='row-justify-end flex-gap-0dot5rem'
        onClick={() =>
          dispatch(setTabletNavSidebarOpenState(!tabletSidebarOpenState))
        }
      >
        <text>{tableHeaderTextItems.timeDate}</text>
        <BsLayoutSidebarReverse size={20} />
      </Section>
    </Container>
  );
};

const Container = styled.div`
  margin: 0px 2rem;
  padding: 1rem 0px;
  display: flex;
  flex: 0 0 54px;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => `${theme.colors.charcoal}`};
`;

const Section = styled.div`
  display: flex;
  flex-basis: 33%;
  align-items: center;
`;
