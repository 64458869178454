import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FaArrowLeftLong } from 'react-icons/fa6';
import useFetch from '../../../api/useFetch';
import {
  productionCntIncreaseApi,
  ProductionResponseType,
} from '../../../api/apiConfig';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/modules';

export const TabletKeypadSidebar = () => {
  const parms = useParams();
  const [count, setCount] = useState<string>('0');

  const [productionId, setProductionId] = useState('');
  const productionDatas = useSelector(
    (state: RootState) => state.socketData.production
  );
  const [productionData, setProductionData] =
    useState<ProductionResponseType | null>(null);

  useEffect(() => {
    if (parms?.productionId) {
      setProductionId(parms.productionId);

      const productionTarget =
        productionDatas.find(
          (production) => Number(production.id) === Number(parms?.productionId)
        ) || null;

      if (productionTarget) {
        setProductionData(productionTarget);
      }
    }
  }, [parms, productionDatas]);

  const { fetch } = useFetch({
    fetchFunction: productionCntIncreaseApi,
    onSuccess: (data) => {
      console.log('productionCntIncreaseApi data : ', data);
    },
  });

  const handleKeypadClick = (value: string) => {
    setCount((prev) => {
      if (value === '-') {
        // 백스페이스 처리
        return prev.length > 1 ? prev.slice(0, -1) : '0';
      }
      if (value === 'C') {
        return '0';
      }

      if (prev === '0' && value !== '00') {
        // 초기값이 0일 때 새로 입력된 값으로 덮어쓰기
        return value;
      }

      if (prev.length >= 45) {
        // 최대 입력 길이 제한 (3줄 = 30자)
        return prev;
      }

      return prev + value;
    });
  };

  const handleAddSubtract = (amount: number) => {
    let sendCount = Number(count);

    // 수량 더하기/빼기 계산
    sendCount = amount === 1 ? sendCount : -sendCount;

    if (
      productionData?.productionCnt !== undefined &&
      productionData?.productionGoal !== undefined
    ) {
      const currentCount = productionData.productionCnt;
      const goalCount = productionData.productionGoal;

      console.log('currentCount : ', currentCount, 'goalCount : ', goalCount);
      // 수량 더하기 제한 (목표 수량보다 높아질 경우)
      // if (amount === 1 && currentCount + sendCount > goalCount) {
      //   console.log('수량이 목표치를 초과할 수 없습니다.');
      //   alert('수량이 목표치를 초과할 수 없습니다.');
      //   return;
      // }

      // 수량 빼기 제한 (현재 수량이 음수로 내려가지 않도록)
      if (amount !== 1 && currentCount + sendCount < 0) {
        console.log('현재 수량이 0보다 작을 수 없습니다.');
        alert('현재 수량이 0보다 작을 수 없습니다.');
        return;
      }

      // 생산 테이블 수량 증가/감소 POST
      if (productionId && sendCount) {
        fetch({ productionId: productionId, increaseCount: sendCount });
      }
    } else {
      console.error('생산 데이터가 없습니다.');
    }
  };

  const quickValues = ['1', '3', '5', '10', '15', '30', '50'];
  const keypadValues = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'C',
    '0',
    '-',
  ];

  const Keypad = ({ value }: { value: string }) => {
    const [isClicked, setIsClicked] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const handleClick = () => {
      handleKeypadClick(value);

      // 클릭 애니메이션
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setIsClicked(true);
      timeoutRef.current = setTimeout(() => {
        setIsClicked(false);
      }, 100);
    };

    return (
      <KeypadItem isClicked={isClicked} onClick={handleClick}>
        {value !== '-' ? value : <FaArrowLeftLong size={30} />}
      </KeypadItem>
    );
  };

  const lineCount = count.length <= 9 ? 1 : count.length <= 24 ? 2 : 3;
  return (
    <Container>
      <BookmarkContainer>
        {/* {quickValues.map((value) => (
          <BookmarkItem onClick={() => undefined}>+{value}</BookmarkItem>
        ))} */}
      </BookmarkContainer>
      <CountValue lineCount={lineCount}>{count}</CountValue>
      <KeypadContainer className='keypad'>
        {keypadValues.map((value) => (
          <Keypad value={value} />
        ))}
      </KeypadContainer>
      <ButtonContainer>
        <Button
          buttonColor='#FF5252'
          buttonClickColor='#FF5252'
          isClicked={false}
          onClick={() => handleAddSubtract(-1)}
        >
          {'수량\n빼기'}
        </Button>
        <Button
          buttonColor='#2CA7FF'
          buttonClickColor='#2CA7FF'
          isClicked={false}
          onClick={() => handleAddSubtract(1)}
        >
          {'수량\n더하기'}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

// 스타일
const Container = styled.div`
  box-sizing: border-box !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 11px;
`;

const BookmarkContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`;

const BookmarkItem = styled.text`
  padding: 2px 10px;
  border-radius: 18px;
  font-weight: 700;
  font-size: 20px;
  border: 2px solid white;
  color: white;

  &:hover {
    cursor: pointer;
  }
`;

const CountValue = styled.div<{ lineCount: number }>`
  width: 100%;
  text-align: end;
  padding-right: calc(2rem);
  color: white;
  font-size: ${({ lineCount }) =>
    lineCount === 1
      ? 'calc(3rem + 1vw)'
      : lineCount === 2
      ? 'calc(2rem + 1vw)'
      : 'calc(1.5rem + 1vw)'};
  font-weight: 700;
  word-wrap: break-word;
  line-height: 1.2; /* 줄 간격 조정 */
`;

const KeypadContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const KeypadItem = styled.text<{ isClicked: boolean }>`
  width: 100%;
  aspect-ratio: 1/0.7;
  align-content: center;
  justify-content: center;
  text-align: center;
  background-color: ${({ isClicked }) =>
    isClicked ? '#6464645e' : 'transparent'};
  transition: ${({ isClicked }) =>
    isClicked ? 'none' : 'background-color 0.3s ease-out'};
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;
`;

const Button = styled.div<{
  buttonColor: string;
  buttonClickColor: string;
  isClicked: boolean;
}>`
  flex: 1;
  font-size: calc(1.3rem + 1vw);
  color: white;
  background-color: ${({ buttonColor }) => buttonColor};
  aspect-ratio: 1;
  border-radius: 10px;
  align-content: center;
  font-weight: 700;
  text-align: center;
  white-space: pre-wrap;
  transition: ${({ isClicked }) =>
    isClicked ? 'none' : 'background-color 0.3s ease-out'};
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    cursor: pointer;
  }
`;
