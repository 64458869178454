import { useEffect, useState } from 'react';
import {
  productionProgressUpdatePostApi,
  ProductionProgressUpdateReqType,
  ProductionResponseType,
  workbayListGetApi,
  WorkbayType,
} from '../../../api/apiConfig';
import useFetch from '../../../api/useFetch';
import styled, { css, useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/modules';
import { CustomCircularProgressbar } from '../../../component/common/CustomCircularProgressbar';
import { ProductionSquareCard } from '../../../component/common/ProductionSquareCard';
import { ProductionCircularCard } from '../../../component/common/ProductionCircularCard';
import { useHeaderButtons } from '../../../component/header/HeaderButtonProvider';
import SquareButton from '../../../component/button/SquareButton';
import { useDispatch } from 'react-redux';
import { toggleSidebar } from '../../../store/modules/sidebar';
import { setHeaderOpenstate } from '../../../store/modules/header';
import { useNavigate } from 'react-router-dom';

export const ProductionProgress = () => {
  const theme = useTheme();
  const role =
    useSelector((state: RootState) => state.loginUserData?.role) || null;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { fetch: fetchProductionProgressUpdate } = useFetch({
    fetchFunction: productionProgressUpdatePostApi,
    onSuccess: () => setStartable(false),
  });

  const sidebarOpenStatus = useSelector(
    (state: RootState) => state.sidebar.uiStatus.open
  );

  const productionStoreData = useSelector(
    (state: RootState) => state.socketData.production
  );

  const [productionData, setProductionData] = useState<
    ProductionResponseType[]
  >([]);
  const [afterProductionData, setAfterProductionData] = useState<
    ProductionResponseType[]
  >([]);

  const headerOpenStatus = useSelector((state: RootState) => state.header.open);
  const { setButtons } = useHeaderButtons();
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const [workbayList, setWorkbayList] = useState<WorkbayType[]>([]);
  const [selectedWorkbayId, setSelectedWorkbayId] = useState<number>(-1);
  const { fetch: fetchWorkbayListGet } = useFetch({
    fetchFunction: workbayListGetApi,
    onSuccess: (data: WorkbayType[]) => setWorkbayList(data),
  });

  //생산시작버튼 활성화 트리거
  const [startable, setStartable] = useState<boolean>(false);

  //생산마감 활성화 트리거
  const [completable, setCompletable] = useState<boolean>(false);

  useEffect(() => {
    fetchWorkbayListGet();
  }, []);

  function progressUpdate() {
    const targetItem = productionData.find(
      (production) => Number(production.workbayId) === Number(selectedWorkbayId)
    );

    console.log('productiondata', productionData);

    if (!targetItem) return;
    const dto: ProductionProgressUpdateReqType = {
      progress: 'P',
      targetDate: targetItem.productionDt,
      workbayId: selectedWorkbayId,
    };
    fetchProductionProgressUpdate(dto);
  }

  function progressComplete() {
    const targetItem = productionData.find(
      (production) => Number(production.workbayId) === Number(selectedWorkbayId)
    );

    if (!targetItem) return;
    const dto: ProductionProgressUpdateReqType = {
      progress: 'C',
      targetDate: targetItem.productionDt,
      workbayId: selectedWorkbayId,
    };
    fetchProductionProgressUpdate(dto);
  }

  useEffect(() => {
    //헤더에 버튼 추가
    setButtons(
      <>
        {role === 'MANAGER' && (
          <SquareButton
            text='전체화면'
            colorType='ACTIVE'
            onClick={() => {
              sidebarOpenStatus && dispatch(toggleSidebar());
              dispatch(setHeaderOpenstate(false));
              document.documentElement.requestFullscreen();
              setFullScreen(true);
            }}
          />
        )}
        {role === 'MANAGER' && (
          <SquareButton
            text='생산시작'
            colorType={startable ? 'ACTIVE' : 'DISACTIVE'}
            onClick={() => {
              startable && progressUpdate();
            }}
          />
        )}
        {role === 'MANAGER' && (
          <SquareButton
            text='생산마감'
            colorType={completable ? 'ACTIVE' : 'DISACTIVE'}
            onClick={() => {
              completable && progressComplete();
            }}
          />
        )}
      </>
    );
    // 페이지가 바뀌면 버튼 초기화
    return () => setButtons(null);
  }, [
    setButtons,
    fullScreen,
    sidebarOpenStatus,
    startable,
    completable,
    role,
    selectedWorkbayId,
  ]);

  useEffect(() => {
    if (selectedWorkbayId === -1) return;
    setProductionData(
      productionStoreData
        .map((storeData: ProductionResponseType) => {
          if (storeData === null || storeData === undefined) return null;
          if (
            (storeData.progress === 'W' || storeData.progress === 'P') &&
            Number(storeData.workbayId) === Number(selectedWorkbayId)
          ) {
            return storeData;
          } else {
            return null;
          }
        })
        .filter((data) => data !== null)
    );

    setAfterProductionData(
      productionStoreData
        .map((storeData: ProductionResponseType) => {
          if (storeData === null || storeData === undefined) return null;
          if (
            storeData.progress === 'E' ||
            (storeData.progress === 'C' &&
              Number(storeData.workbayId) === Number(selectedWorkbayId))
          ) {
            return storeData;
          } else {
            return null;
          }
        })
        .filter((data) => data !== null)
    );
  }, [selectedWorkbayId, productionStoreData]);

  useEffect(() => {
    setStartable(
      productionData.find(
        (production) => Number(production.workbayId) === selectedWorkbayId
      )?.progress === 'W'
    );

    setCompletable(
      productionData.find(
        (production) => Number(production.workbayId) === selectedWorkbayId
      )?.progress === 'P'
    );
  }, [productionData, selectedWorkbayId]);

  return (
    <Container>
      {!headerOpenStatus && (
        <>
          <SquareButton
            text='전체화면해제'
            colorType='ACTIVE'
            onClick={() => {
              !sidebarOpenStatus && dispatch(toggleSidebar());
              dispatch(setHeaderOpenstate(true));
              document.exitFullscreen();
              setFullScreen(false);
            }}
          />
          <hr />
        </>
      )}
      {selectedWorkbayId === -1 && (
        <h3 style={{ marginTop: 0, color: theme.colors.red }}>
          작업장을 선택해주세요
        </h3>
      )}
      {!fullScreen && (
        <WorkbaySection>
          {workbayList.length > 0 &&
            workbayList
              //100은 엠마우스 본사코드로 사용안함
              .filter((workbay) => workbay.workbayCode !== '100')
              .map((workbay) => (
                <WorkbayItem
                  selected={selectedWorkbayId === workbay.id}
                  onClick={() =>
                    setSelectedWorkbayId((prev) =>
                      prev === workbay.id ? prev : workbay.id
                    )
                  }
                >
                  {workbay.workbayName}
                </WorkbayItem>
              ))}
        </WorkbaySection>
      )}
      {!fullScreen && (
        <h3 style={{ color: theme.colors.charcoal }}>
          - 대기중 및 진행중인 생산항목
        </h3>
      )}
      <CardSection fullScreen={fullScreen}>
        {productionData.length > 0 &&
          productionData
            .filter((production) =>
              selectedWorkbayId === -1
                ? true
                : Number(selectedWorkbayId) === Number(production.workbayId)
            )
            .map((production) => (
              <ProductionCircularCard
                hover={true}
                onClick={() =>
                  navigate(
                    `/production-progress/${production.id}/${production.item.id}`
                  )
                }
                itemName={production.item.PROD_DES}
                itemSize={production.item.SIZE_DES}
                workbayName={production.workbayName}
                current={production.productionCnt}
                goal={production.productionGoal}
                progress={production.progress}
              />
            ))}
      </CardSection>
      {!fullScreen && afterProductionData && afterProductionData.length > 0 && (
        <>
          <div style={{ height: '20px' }} />

          <h3 style={{ color: theme.colors.charcoal }}>- 마감된 생산항목</h3>
          <CardSection fullScreen={false}>
            {afterProductionData.length > 0 &&
              afterProductionData
                .filter((production) =>
                  selectedWorkbayId === -1
                    ? true
                    : Number(selectedWorkbayId) === Number(production.workbayId)
                )
                .map((production) => (
                  <ProductionCircularCard
                    hover={false}
                    onClick={() => undefined}
                    itemName={production.item.PROD_DES}
                    itemSize={production.item.SIZE_DES}
                    workbayName={production.workbayName}
                    current={production.productionCnt}
                    goal={production.productionGoal}
                    progress={production.progress}
                  />
                ))}
          </CardSection>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const WorkbaySection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  width: auto;
  margin-bottom: 15px;
  height: 33px;
`;

const WorkbayItem = styled.div<{ selected: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 3px 7px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 3px solid;
  border-color: ${({ theme, selected }) =>
    selected ? theme.colors.grapeFruit : 'transparent'};
  color: ${({ theme }) => theme.colors.charcoal};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontsize.header_semi};
  cursor: pointer;
`;

const CardSection = styled.div<{ fullScreen?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 28px;
  ${({ fullScreen }) =>
    fullScreen &&
    css`
      flex: 1;
      align-items: center;
      justify-content: space-evenly;
    `}
`;
